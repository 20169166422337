import { useLocalization } from '@fluent/react';
import { Button, CardContent, Grid, Tooltip, Typography } from '@material-ui/core';
import React, { FC } from 'react';

import { Ivim } from '_store/ivim/ivimsTypes';
import styles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { blue, red } from '@material-ui/core/colors';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';

export interface CardDetailsProps {
  ivim: Ivim;
  onUpdate?: () => void;
  onDelete?: () => void;
  showButtons?: boolean;
  isActive?: boolean;
  onDisable?: () => void;
  onEnable?: () => void;
  isDisabled?: boolean;
  onClone?: () => void;
}

const CardDetails: FC<CardDetailsProps> = ({
  ivim,
  onUpdate,
  onDelete,
  showButtons,
  isActive = true,
  onDisable,
  onEnable,
  isDisabled = false,
  onClone,
}) => {
  const classes = styles();
  const { l10n } = useLocalization();

  return (
    <CardContent className={classes.detailStyle}>
      <Grid container spacing={1} style={{ marginTop: 10 }}>
        <Grid item xs={12}>
          <Typography component={'span'} variant={'body1'} paragraph>
            {l10n.getString('forms-ivim-detail-header')}:
          </Typography>
        </Grid>
        <Grid container spacing={1} style={{ marginTop: 10 }}>
          <Grid item xs={12} className={classes.gridVertical}>
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              {l10n.getString('forms-ivim-id')} {`\n`}
              <strong>{ivim.id}</strong>
            </Typography>
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              {l10n.getString('forms-ivim-init-date')} {`\n`}{' '}
              <strong>{new Date(ivim.starts).toLocaleString()}</strong>
            </Typography>

            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              {l10n.getString('forms-ivim-final-date')} {`\n`}
              <strong>{new Date(ivim.ends).toLocaleString()}</strong>
            </Typography>
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              {l10n.getString('forms-ivim-reference-point')} {`\n`}
              <strong>
                ({ivim.referencePoint.coordinates[1]}, {ivim.referencePoint.coordinates[0]})
              </strong>
            </Typography>
          </Grid>
        </Grid>

        {showButtons && (
          <Grid item xs={12} style={{ paddingLeft: 0, marginTop: 10 }}>
            {(isActive || isDisabled) && (
              <>
                {!isDisabled && (
                  <Tooltip title={l10n.getString('forms-event-update-btn')}>
                    <FontAwesomeIcon
                      icon={faPen}
                      style={{
                        backgroundColor: blue[400],
                      }}
                      className={classes.iconButton}
                      size="xs"
                      onClick={onUpdate}
                    />
                  </Tooltip>
                )}
                <Tooltip
                  title={l10n.getString(
                    isDisabled ? 'forms-event-enable-btn' : 'forms-event-disable-btn',
                  )}
                >
                  <FontAwesomeIcon
                    icon={isDisabled ? faEye : faEyeSlash}
                    style={{
                      backgroundColor: blue[400],
                    }}
                    className={classes.iconButton}
                    size="xs"
                    onClick={isDisabled ? onEnable : onDisable}
                  />
                </Tooltip>
              </>
            )}
            {!isActive && !isDisabled && (
              <Tooltip title={l10n.getString('forms-event-clone-btn')}>
                <FontAwesomeIcon
                  icon={faClone}
                  style={{
                    backgroundColor: blue[400],
                  }}
                  className={classes.iconButton}
                  size="xs"
                  onClick={onClone}
                />
              </Tooltip>
            )}
            {(isActive || isDisabled) && (
              <Tooltip title={l10n.getString('forms-event-delete-btn')}>
                <FontAwesomeIcon
                  icon={faTrash}
                  style={{
                    backgroundColor: red[400],
                  }}
                  className={classes.iconButton}
                  size="xs"
                  onClick={onDelete}
                />
              </Tooltip>
            )}
          </Grid>
        )}
      </Grid>
    </CardContent>
  );
};

export default React.memo(CardDetails);
