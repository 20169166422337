import L, { Map } from 'leaflet';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import MarkerClusterGroup from 'react-leaflet-markercluster';

import { Ivim } from '../../../../../store/ivim/ivimsTypes';
import IvimMarker from './markers/IvimMarker';
import { iconSizePx } from '../utils';
import EmptyTriangle from '../../../icons/ivim/BlueExclamation.png';

const Ivims: FC<{ ivims: Ivim[]; mapRef: Map }> = ({ ivims, mapRef }): ReactElement => {
  // workaround to force re-render of markers https://github.com/yuzhva/react-leaflet-markercluster/issues/149
  const [renderKey, setRenderKey] = useState(0);
  useEffect(() => {
    setRenderKey((k) => k + 1);
  }, [ivims]);

  const iconSize = new L.Point(iconSizePx, iconSizePx);
  const iconAnchor = new L.Point(iconSize.x / 2, iconSize.y);

  return (
    <MarkerClusterGroup
      maxClusterRadius={160}
      disableClusteringAtZoom={13}
      iconCreateFunction={(cluster) => {
        const markers = cluster.getAllChildMarkers();
        const count = markers.length;
        const digits = count.toString().length;

        const html = `
      <div class="cluster-icon-container-full">
        <img src="${EmptyTriangle}" class="cluster-icon" />
        <div class="cluster-count" data-digits="${digits}">${count}</div>
      </div>
    `;

        return L.divIcon({
          html,
          className: 'marker-event-unselected',
          iconSize: iconSize,
          iconAnchor: iconAnchor,
        });
      }}
      key={renderKey}
    >
      {ivims.map((ivim, key) => (
        <IvimMarker key={key} ivim={ivim} mapRef={mapRef} />
      ))}
    </MarkerClusterGroup>
  );
};

export default React.memo(Ivims);
