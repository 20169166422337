/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import ivimsReducer from '_store/ivim/ivimsReducers';
import { ivimsSagas } from '_store/ivim/sagas';
import { eventChannel } from 'redux-saga';
import { put } from 'redux-saga/effects';
import { call, cancelled, take } from 'typed-redux-saga';

import applicationReducer from '../../application/reducer';
import denmsReducer from '../../denm/denmsReducers';
import { denmsSagas } from '../../denm/sagas';
import devicesReducer from '../../devices/devicesReducers';
import camsReducer from '../../vehicles/vehiclesReducers';
import apiReducer from '../reducer';
import store from '_store';

// const roadsUrl = `${process.env.WEB_SOCKET_ENDPOINT}/api/roads/v1alpha1/segments/events`;
// const roadsSegments = ({ emitter, socketRoads, token }) => {
//   socketRoads.onopen = () => {
//     console.log('opening...', token);
//     socketRoads.send(
//       JSON.stringify({
//         event: 'subscribe',
//         data: {
//           topics: ['*.*'],
//           token: token,
//           headers: {
//             traceparent: "00-31d203e42079286316be3c0e7b129e9b-495eb5969729d819-01",
//             tracestate: "sentry=2131343241",
//           }
//         },
//       }),
//     );
//   }
//   socketRoads.onerror = (error) => {
//     console.log('WebSocket error ', error)
//   }
//   socketRoads.onmessage = (e) => {
//     console.log('msg.channel >>>>>>>>>>>>', JSON.parse(e.data));
//   }
// }

const denmsUrl = `${process.env.WEB_SOCKET_ENDPOINT}/api/etsi/v1alpha1/denms/events`;
const ivimsUrl = `${process.env.WEB_SOCKET_ENDPOINT}/api/etsi/v1alpha1/ivims/events`;
const vehiclesUrl = `${process.env.WEB_SOCKET_ENDPOINT}/api/etsi/v1alpha1/cams/events`;
const showErrors = Boolean(process.env.SHOW_WEBSOCKET_ERRORS === 'true');

const denms = ({ emitter, socketDenms, token, createWs }) => {
  // eslint-disable-next-line no-param-reassign
  socketDenms.onopen = () => {
    socketDenms.send(
      JSON.stringify({
        event: 'subscribe',
        data: {
          topics: ['*.*'],
          token: token,
        },
      }),
    );
  };
  // eslint-disable-next-line no-param-reassign
  socketDenms.onerror = (error) => {
    // eslint-disable-next-line no-console
    if (showErrors) {
      console.log('WebSocket error >>>>>>>>>>> ', error);
    }
    emitter(new Error(error));
  };
  // eslint-disable-next-line no-param-reassign
  socketDenms.onmessage = (e) => {
    const data = JSON.parse(e.data);
    // console.log('on denms message >>>>>>>>>>>>', data);
    emitter(data);
  };
  // eslint-disable-next-line no-param-reassign
  socketDenms.onclose = (e) => {
    if (e.code === 1005) {
      // eslint-disable-next-line no-console
      console.log('WebSocket: closed');
      // you probably want to end the channel in this case
      // emitter(END);
    } else {
      // eslint-disable-next-line no-console
      console.log(
        'Socket is closed Unexpectedly. Reconnect will be attempted in 1 second.',
        e.reason,
      );
      setTimeout(() => createWs(), 1000);
    }
  };
};

const ivims = ({ emitter, socketIvims, token, createWs }) => {
  // eslint-disable-next-line no-param-reassign
  socketIvims.onopen = () => {
    socketIvims.send(
      JSON.stringify({
        event: 'subscribe',
        data: {
          topics: ['*.*'],
          token: token,
        },
      }),
    );
  };
  // eslint-disable-next-line no-param-reassign
  socketIvims.onerror = (error) => {
    // eslint-disable-next-line no-console
    if (showErrors) {
      console.log('WebSocket error >>>>>>>>>>> ', error);
    }
    emitter(new Error(error));
  };
  // eslint-disable-next-line no-param-reassign
  socketIvims.onmessage = (e) => {
    const data = JSON.parse(e.data);
    // console.log('on Ivims message >>>>>>>>>>>>', data);
    emitter(data);
  };
  // eslint-disable-next-line no-param-reassign
  socketIvims.onclose = (e) => {
    if (e.code === 1005) {
      // eslint-disable-next-line no-console
      if (showErrors) {
        console.log('WebSocket: closed');
      }
      // you probably want to end the channel in this case
      // emitter(END);
    } else {
      // eslint-disable-next-line no-console
      console.log(
        'Socket is closed Unexpectedly. Reconnect will be attempted in 1 second.',
        e.reason,
      );
      setTimeout(() => createWs(), 1000);
    }
  };
};

const camTimeouts = new Map();

const vehicles = ({ emitter, socketVehicles, token, createWs }) => {
  socketVehicles.onopen = () => {
    socketVehicles.send(
      JSON.stringify({
        event: 'subscribe',
        data: {
          topics: ['*.*'],
          token: token,
        },
      }),
    );
  };
  socketVehicles.onerror = (error) => {
    if (showErrors) {
      console.log('WebSocket error >>>>>>>>>>> ', error);
    }
    emitter(new Error(error));
  };
  socketVehicles.onmessage = (e) => {
    const data = JSON.parse(e.data);
    emitter(data);
  };
  socketVehicles.onclose = (e) => {
    if (e.code === 1005) {
      console.log('WebSocket: closed');
      // you probably want to end the channel in this case
      // emitter(END);
    } else {
      console.log(
        'Socket is closed Unexpectedly. Reconnect will be attempted in 1 second.',
        e.reason,
      );
      setTimeout(() => createWs(), 1000);
    }
    camTimeouts.forEach(clearTimeout);
  };
  return () => {
    socketVehicles.close();
  };
};

function* handleCamUpdated(cam) {
  const { stationId } = cam;

  if (camTimeouts.has(stationId)) {
    clearTimeout(camTimeouts.get(stationId));
  }

  const timeout = setTimeout(() => {
    store.dispatch(camsReducer.actions.camRemove(stationId));
    camTimeouts.delete(stationId);
  }, 30000);

  camTimeouts.set(stationId, timeout);
}

const devicesUrl = `${process.env.WEB_SOCKET_ENDPOINT}/api/devices/v1alpha1/rsus/events`;

const devices = ({ emitter, socketDevices, token, createWs }) => {
  // eslint-disable-next-line no-param-reassign
  socketDevices.onopen = () => {
    socketDevices.send(
      JSON.stringify({
        event: 'subscribe',
        data: {
          topics: ['**'],
          token: token,
        },
      }),
    );
  };
  // eslint-disable-next-line no-param-reassign
  socketDevices.onerror = (error) => {
    // eslint-disable-next-line no-console
    if (showErrors) {
      console.log('WebSocket devices error >>>>>>>>>>> ', error);
    }
    emitter(new Error(error));
  };
  // eslint-disable-next-line no-param-reassign
  socketDevices.onmessage = (e) => {
    const data = JSON.parse(e.data);
    // console.log('on devices message >>>>>>>>>>>>', data);
    emitter(data);
  };
  // eslint-disable-next-line no-param-reassign
  socketDevices.onclose = (e) => {
    if (e.code === 1005) {
      // eslint-disable-next-line no-console
      console.log('WebSocket: closed');
      // you probably want to end the channel in this case
      // emitter(END);
    } else {
      // eslint-disable-next-line no-console
      console.log(
        'Socket is closed Unexpectedly. Reconnect will be attempted in 1 second.',
        e.reason,
      );
      setTimeout(() => createWs(), 1000);
    }
  };
};

const { sessionStorage } = window;
let socketDenms;
let socketIvims;
let socketDevices;
let socketVehicles;

function initDenmsWebsocket(token: string) {
  return eventChannel((emitter) => {
    function createWs() {
      socketDenms = new WebSocket(denmsUrl);
      denms({ emitter, socketDenms, token, createWs });
    }
    createWs();

    return () => {
      // eslint-disable-next-line no-console
      console.log('Socket off');
      socketDenms.send(
        JSON.stringify({
          event: 'unsubscribe',
          data: {
            id: sessionStorage.getItem('denmSubscriptionID'),
          },
        }),
      );
      sessionStorage.setItem('denmSubscriptionID', '');
    };
  });
}

function initIvimsWebsocket(token: string) {
  return eventChannel((emitter) => {
    function createWs() {
      socketIvims = new WebSocket(ivimsUrl);
      ivims({ emitter, socketIvims, token, createWs });
    }
    createWs();

    return () => {
      // eslint-disable-next-line no-console
      console.log('Socket off');
      socketIvims.send(
        JSON.stringify({
          event: 'unsubscribe',
          data: {
            id: sessionStorage.getItem('ivimSubscriptionID'),
          },
        }),
      );
      sessionStorage.setItem('ivimSubscriptionID', '');
    };
  });
}

function initDevicesWebsocket(token: string) {
  return eventChannel((emitter) => {
    function createWs() {
      socketDevices = new WebSocket(devicesUrl);
      devices({ emitter, socketDevices, token, createWs });
    }
    createWs();

    return () => {
      // eslint-disable-next-line no-console
      console.log('Socket off');
      socketDevices.send(
        JSON.stringify({
          event: 'unsubscribe',
          data: {
            id: sessionStorage.getItem('devicesSubscriptionID'),
          },
        }),
      );
      sessionStorage.setItem('devicesSubscriptionID', '');
    };
  });
}

function initVehiclesWebsocket(token: string) {
  return eventChannel((emitter) => {
    function createWs() {
      socketVehicles = new WebSocket(vehiclesUrl);
      vehicles({ emitter, socketVehicles, token, createWs });
    }
    createWs();

    return () => {
      // eslint-disable-next-line no-console
      console.log('Socket off');
      socketVehicles.send(
        JSON.stringify({
          event: 'unsubscribe',
          data: {
            id: sessionStorage.getItem('vehiclesSubscriptionID'),
          },
        }),
      );
      sessionStorage.setItem('vehiclesSubscriptionID', '');
    };
  });
}

export function* pollTaskWatcherDENM(token: string) {
  // eslint-disable-next-line no-console
  console.log('pollTaskWatcher DENM START >>>>>>>>>>>>');
  const denmChannel = yield call(initDenmsWebsocket, token);
  while (true) {
    const denmAction = yield take(denmChannel);
    console.log('denmevets', denmAction);
    switch (denmAction.event) {
      case 'subscription.created':
        // eslint-disable-next-line no-console
        console.log('denm subscription.created >>>>>>> ');
        sessionStorage.setItem('denmSubscriptionID', denmAction.data.id);
        break;
      case 'subscription.error':
        // eslint-disable-next-line no-console
        if (showErrors) {
          console.log('Websocket DENM fails >>>>>>> ');
          yield put(applicationReducer.actions.toastAdded({ id: 'Websocket DENM fails' }));
        }
        break;
      case 'denm.created':
        // eslint-disable-next-line no-console
        console.log('denm.created >>>>>>> ', denmAction);
        yield put(denmsReducer.actions.denmsAddOne(denmAction.data.denm));
        yield put(denmsSagas.actions.statusTimer(denmAction.data.denm));
        break;
      case 'denm.updated':
        // eslint-disable-next-line no-console
        console.log('denm.updated >>>>>>> ', denmAction);
        yield put(denmsReducer.actions.denmUpsertOne(denmAction.data.denm));
        break;
      case 'denm.deleted':
        // eslint-disable-next-line no-console
        console.log('denm.deleted >>>>>>> ', denmAction);
        yield put(denmsReducer.actions.denmRemove(denmAction.data.denm.id));
        break;
      default:
        if (showErrors) {
          // eslint-disable-next-line no-console
          console.log('Websocket DENM fails >>>>>>> ', denmAction.event, denmAction);
          yield put(applicationReducer.actions.toastAdded({ id: 'Websocket DENM fails' }));
        }
        break;
    }
  }
}

export function* pollTaskWatcherIVIM(token: string) {
  // eslint-disable-next-line no-console
  console.log('pollTaskWatcher IVIM START >>>>>>>>>>>>');
  const ivimChannel = yield call(initIvimsWebsocket, token);
  while (true) {
    const ivimAction = yield take(ivimChannel);
    console.log('ivim events', ivimAction, ivimAction.event);
    switch (ivimAction.event) {
      case 'subscription.created':
        // eslint-disable-next-line no-console
        console.log('ivim subscription.created >>>>>>> ');
        sessionStorage.setItem('ivimSubscriptionID', ivimAction.data.id);
        break;
      case 'subscription.error':
        // eslint-disable-next-line no-console
        if (showErrors) {
          console.log('Websocket IVIM fails >>>>>>> ');
          yield put(applicationReducer.actions.toastAdded({ id: 'Websocket IVIM fails' }));
        }
        break;
      case 'ivim.created':
        // eslint-disable-next-line no-console
        console.log('ivim.created >>>>>>> ', ivimAction);
        yield put(ivimsReducer.actions.ivimsAddOne(ivimAction.data.ivim));
        yield put(ivimsSagas.actions.statusTimer(ivimAction.data.ivim));
        break;
      case 'ivim.deleted':
        // eslint-disable-next-line no-console
        console.log('ivim.deleted >>>>>>> ', ivimAction);
        yield put(ivimsReducer.actions.ivimRemove(ivimAction.data.ivim.id));
        break;
      default:
        if (showErrors) {
          // eslint-disable-next-line no-console
          console.log('Websocket IVIM fails >>>>>>> ', ivimAction.event, ivimAction);
          yield put(applicationReducer.actions.toastAdded({ id: 'Websocket IVIM fails' }));
        }
        break;
    }
  }
}

export function* pollTaskWatcherRSU(token: string) {
  // eslint-disable-next-line no-console
  console.log('pollTaskWatcher RSU START >>>>>>>>>>>>');
  const devicesChannel = yield call(initDevicesWebsocket, token);
  while (true) {
    const devicesAction = yield take(devicesChannel);
    switch (devicesAction.event) {
      case 'subscription.created':
        // eslint-disable-next-line no-console
        console.log('rsu subscription.created >>>>>>> ');
        sessionStorage.setItem('devicesSubscriptionID', devicesAction.data.id);
        break;
      case 'subscription.error':
        // eslint-disable-next-line no-console
        if (showErrors) {
          console.log('Websocket RSU fails error >>>>>>> ', devicesAction.event);
          // yield put(applicationReducer.actions.toastAdded({ id: 'Websocket RSU fails' }));
        }
        break;
      case 'rsu.created':
        // eslint-disable-next-line no-console
        console.log('rsu.created >>>>>>> ', devicesAction);
        yield put(devicesReducer.actions.devicesAddOne(devicesAction.data.rsu));
        break;
      case 'rsu.updated':
        // eslint-disable-next-line no-console
        console.log('rsu.updated >>>>>>> ', devicesAction);
        yield put(devicesReducer.actions.devicesUpsertOne(devicesAction.data.rsu));
        break;
      case 'rsu.deleted':
        // eslint-disable-next-line no-console
        console.log('rsu.deleted >>>>>>> ', devicesAction);
        yield put(devicesReducer.actions.devicesRemove(devicesAction.data.rsu.id));
        break;
      case 'rsu.diagnostic.online':
      case 'rsu.diagnostic.degraded':
        // eslint-disable-next-line no-console
        // console.log('rsu.diagnostic >>>>>', devicesAction);
        yield put(apiReducer.actions.upsertDiagnostic(devicesAction));
        break;
      case 'rsu.diagnostic.offline':
        yield put(apiReducer.actions.deleteDiagnostic(devicesAction));
        break;
      default:
        if (showErrors) {
          // eslint-disable-next-line no-console
          console.log('Websocket RSU fails >>>>>>> ', devicesAction.event, devicesAction);
          // yield put(applicationReducer.actions.toastAdded({ id: 'Websocket RSU fails' }));
        }
        break;
    }
  }
}

export function* pollTaskWatcherCAM(token: string) {
  // eslint-disable-next-line no-console
  console.log('pollTaskWatcher CAM START >>>>>>>>>>>>');
  const camChannel = yield call(initVehiclesWebsocket, token);
  try {
    while (true) {
      const camAction = yield take(camChannel);
      // console.log('cam events', camAction, camAction.event);
      switch (camAction.event) {
        case 'subscription.created':
          console.log('cam subscription.created >>>>>>> ');
          sessionStorage.setItem('camSubscriptionID', camAction.data.id);
          break;
        case 'subscription.error':
          if (showErrors) {
            console.log('Websocket CAM fails >>>>>>> ');
            yield put(applicationReducer.actions.toastAdded({ id: 'Websocket CAM fails' }));
          }
          break;
        case 'cam.created':
          //console.log('cam.created >>>>>>> ', camAction);
          yield put(camsReducer.actions.camsAddOne(camAction.data.cam));
          break;
        case 'cam.updated':
          //console.log('cam.updated >>>>>>> ', camAction);
          if (camAction.data.cam.pathHistory?.coordinates.length > 1) {
            yield call(handleCamUpdated, camAction.data.cam);
          }
          yield put(camsReducer.actions.camUpsertOne(camAction.data.cam));
          break;
        case 'cam.deleted':
          //console.log('cam.deleted >>>>>>> ', camAction);
          yield put(camsReducer.actions.camRemove(camAction.data.cam.stationId));
          break;
        default:
          if (showErrors) {
            console.log('Websocket CAM fails >>>>>>> ', camAction.event, camAction);
            yield put(applicationReducer.actions.toastAdded({ id: 'Websocket CAM fails' }));
          }
          break;
      }
    }
  } finally {
    if (yield cancelled()) {
      camTimeouts.forEach(clearTimeout);
      camTimeouts.clear();
    }
  }
}
