import bus from './bus.png';
import busTo from './busTo.png';
import cyclist from './cyclist.png';
import cyclistFrom from './cyclistFrom.png';
import heavyTruck from './heavyTruck.png';
import heavyTruckTo from './heavyTruckTo.png';
import icons8Adr from './icons8-adr.png';
import icons8Car from './icons8-car.png';
import lightTruck from './lightTruck.png';
import lightTruckTo from './lightTruckTo.png';
import moped from './moped.png';
import mopedFrom from './mopedFrom.png';
import motorcycles from './motorcycles.png';
import motorcyclesFrom from './motorcyclesFrom.png';
import passengerCar from './passengerCar.png';
import passengerCarFrom from './passengerCarFrom.png';
import pedestrian from './pedestrian.png';
import pedestrianFrom from './pedestrianFrom.png';
import specialVehicles from './specialVehicles.png';
import specialVehiclesTo from './specialVehiclesTo.png';
import trailer from './trailer.png';
import trailerTo from './trailerTo.png';
import tram from './tram.png';
import tramTo from './tramTo.png';
import unknown from './unknown.png';

export default {
  '-1-bus.png': busTo,
  '1-bus.png': bus,
  'bus.png': bus,

  '1-cyclist.png': cyclistFrom,
  '-1-cyclist.png': cyclist,
  'cyclist.png': cyclist,

  '-1-heavyTruck.png': heavyTruckTo,
  '1-heavyTruck.png': heavyTruck,
  'heavyTruck.png': heavyTruck,

  '-1-lightTruck.png': lightTruckTo,
  '1-lightTruck.png': lightTruck,
  'lightTruck.png': lightTruck,

  '-1-moped.png': moped,
  '1-moped.png': mopedFrom,
  'moped.png': mopedFrom,

  '-1-motorcycles.png': motorcycles,
  '1-motorcycles.png': motorcyclesFrom,
  'motorcycles.png': motorcyclesFrom,

  '-1-passengerCar.png': passengerCar,
  '1-passengerCar.png': passengerCarFrom,
  'passengerCar.png': passengerCarFrom,

  '-1-pedestrian.png': pedestrian,
  '1-pedestrian.png': pedestrianFrom,
  'pedestrian.png': pedestrianFrom,

  '-1-specialVehicles.png': specialVehiclesTo,
  '1-specialVehicles.png': specialVehicles,
  'specialVehicles.png': specialVehicles,

  '-1-trailer.png': trailerTo,
  '1-trailer.png': trailer,
  'trailer.png': trailer,

  '-1-tram.png': tramTo,
  '1-tram.png': tram,
  'tram.png': tram,

  'icons8-car.png': icons8Car,
  'icons8-adr.png': icons8Adr,

  'unknown.png': unknown,
};
