import emerasLogo from './app/assets/images/logo-emeras.png';
import a4MobilityLogo from './app/assets/images/logo-a4bspd.png';
import satapLogo from './app/assets/images/logo-satap.png';
import brebemiLogo from './app/assets/images/logo-brebemi.png';

const localStorageKey = process.env.LOCAL_STORAGE_KEY;

export const updateLocalStorage = (field: string, value: any) => {
  try {
    const storedObject = JSON.parse(localStorage.getItem(localStorageKey) || '{}');
    storedObject[field] = value;
    localStorage.setItem(localStorageKey, JSON.stringify(storedObject));
  } catch (error) {
    console.error(`Error updating localStorage for field ${field}:`, error);
  }
};

export const getFromLocalStorage = (field: string) => {
  try {
    const storedObject = JSON.parse(localStorage.getItem(localStorageKey) || '{}');
    return storedObject[field];
  } catch (error) {
    console.error(`Error reading ${field} from localStorage:`, error);
    return null;
  }
};

export interface Client {
  name: string;
  logoPath: string;
  url?: string;
}

export const mapClient = (client: string): Client => {
  switch (client) {
    case 'a4mobility':
      return {
        name: 'A4 Mobility',
        logoPath: a4MobilityLogo,
      };
    case 'satap':
      return {
        name: 'SATAP',
        logoPath: satapLogo,
      };
    case 'brebemi':
      return {
        name: 'Brebemi',
        logoPath: brebemiLogo,
      };
    default:
      return {
        name: 'Emeras',
        logoPath: emerasLogo,
      };
  }
};
