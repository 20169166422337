export enum DenmTypeEnum {
  NEW_DENM = 'newDenm',
  UPDATE_DENM = 'updateDenm',
  CLONE_DENM = 'cloneDenm',
  DELETE_DENM = 'deleteDenm',
  DISABLE_DENM = 'disableDenm',
}

export type DenmType = {
  type: DenmTypeEnum;
  id: string;
  onClose: () => void;
  onGoingBack: () => void;
  closeDialog: () => void;
};
