import { combineReducers } from '@reduxjs/toolkit';

import denmFormReducer from './manage-denm';
import ivimFormReducer from './manage-ivim';
import pointFormReducer from './create-point';
import rsuFormReducer from './manage-rsu';

const formReducer = combineReducers({
  'manage-denm': denmFormReducer.reducer,
  'create-ivim': ivimFormReducer.reducer,
  'create-point': pointFormReducer.reducer,
  'manage-rsu': rsuFormReducer.reducer,
});

export default formReducer;
