import { useLocalization } from '@fluent/react';
import styles from './styles';

interface EventStatusProps {
  date: Date;
  active: boolean;
  disabled: boolean;
  scheduled?: boolean;
}
export const EventStatus = ({ date, active, disabled, scheduled }: EventStatusProps) => {
  const classes = styles();
  const { l10n } = useLocalization();
  let statusClass: string;
  let statusText: string;

  if (disabled) {
    statusClass = classes.statusTextDisabled;
    statusText = l10n.getString('forms-event-status-event-disabled');
  } else if (scheduled) {
    statusClass = classes.statusTextScheduled;
    statusText = l10n.getString('forms-event-status-event-scheduled');
  } else if (active) {
    statusClass = classes.statusTextProgress;
    statusText = l10n.getString('forms-event-status-event-prog');
  } else {
    const eventTime = new Date(date);
    statusClass = classes.statusTextClosed;
    statusText = l10n.getString('forms-event-status-event-clos');
    const now = new Date();
    if (eventTime > now) {
      statusClass = classes.statusTextWaiting;
      statusText = l10n.getString('forms-event-status-event-waiting');
    }
  }

  return {
    statusClass,
    statusText,
  };
};
