import { Coordinate, RSU as RSUInterface } from '_lib/api';
import { Denm } from '_store/denm/denmsTypes';
import { Ivim } from '_store/ivim/ivimsTypes';
import { Cam } from '_store/vehicles/vehiclesTypes';
import { PointExpression } from 'leaflet';

export const enum HoveringElementType {
  CAM = 'cam',
  DENM = 'denm',
  IVIM = 'ivim',
  RSU = 'rsu',
  DOTT_ADD = 'dott-add',
}

export interface HoveringCAM {
  type: HoveringElementType.CAM;
  cam: Cam;
  offset?: PointExpression;
}

export interface HoveringDENM {
  type: HoveringElementType.DENM;
  denm: Denm;
  offset?: PointExpression;
}

export interface HoveringIVIM {
  type: HoveringElementType.IVIM;
  ivim: Ivim;
  offset?: PointExpression;
}

export interface HoveringRSU {
  type: HoveringElementType.RSU;
  device: RSUInterface;
  offset: PointExpression;
}

export interface HoveringDottPoint {
  type: HoveringElementType.DOTT_ADD;
  number: number;
  coords: Coordinate;
  offset?: PointExpression;
}

export type HoveringElement =
  | HoveringCAM
  | HoveringDENM
  | HoveringIVIM
  | HoveringRSU
  | HoveringDottPoint;

export interface HoverTooltipProps {
  hoveringElement: HoveringElement;
  onClose?: () => void;
}
