import { MapElement, PointSelection } from '_store/application/types';
import React, { FC } from 'react';

import Elements from './Elements';

const SelectionElements: FC<{ selection?: PointSelection }> = ({ selection }) => {
  const elements: MapElement[] = selection.points.map((point) => ({
    ...selection.style,
    loc: point,
  }));
  return <Elements elements={elements} />;
};

export default SelectionElements;
