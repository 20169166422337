import { Method } from '.';

export const getFetchHeaders = ({
  authenticated = true,
  method,
}: {
  authenticated: boolean;
  method: Method;
}): Headers => {
  const token = window.sessionStorage.getItem('token');
  const Authorization = authenticated && token ? `Bearer ${token}` : undefined;
  const headers = new Headers();
  headers.append('authorization', Authorization);
  headers.append('accept', 'application/json');

  if (method === Method.POST || method === Method.PATCH) {
    headers.append('Content-Type', 'application/json');
  }

  return headers;
};

export default { getFetchHeaders };
