import { useLocalization } from '@fluent/react';
import { Button, Divider } from '@material-ui/core';
import { RootState, useAppDispatch } from '_store';
import applicationReducer from '_store/application/reducer';
import { extraSelector } from '_store/application/selectors';
import {
  MapElementType,
  PointCount,
  PointSelectionParams,
  PointSelectionType,
} from '_store/application/types';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { measureDistance, pointStyles } from '../utils';
import styles from './styles';

export const MeasureDistance = ({ onClose }: { onClose: () => void }) => {
  const { l10n } = useLocalization();
  const dispatch = useAppDispatch();
  const classes = styles();

  const pointSelection = useSelector((state: RootState) => state.application.pointSelections);
  const { active: psActive, points } = pointSelection;
  const currentSelection = useSelector((state: RootState) => state.application.currentSelection);
  const selection = useSelector(extraSelector);

  const isActiveSelection = useMemo(
    () => ({
      [PointSelectionType.MEASUREMENT]:
        psActive && currentSelection === PointSelectionType.MEASUREMENT,
    }),
    [psActive, currentSelection],
  );

  const startSelection = useCallback(
    (params: PointSelectionParams) => {
      dispatch(applicationReducer.actions.pointSelectionStarted(params));
    },
    [dispatch],
  );

  const endSelection = useCallback(
    (params: PointSelectionParams) => {
      try {
        const distance = measureDistance(points[0], points[1]);

        const elements = points.map((loc) => ({
          ...pointStyles[params.type],
          loc,
        }));

        if (points.length > 1) {
          elements.push({
            type: MapElementType.POLYLINE,
            loc: points[0],
            points: points.map((p, _i, arr) => [p[0] - arr[0][0], p[1] - arr[0][1]]),
            options: {
              color: pointStyles[params.type].options.color,
            },
            distance: distance,
          });
        }

        const paramsId = params.type;

        dispatch(
          applicationReducer.actions.setMapElement({
            id: paramsId,
            elements,
          }),
        );
      } catch (error) {
        dispatch(
          applicationReducer.actions.toastAdded({
            id: error.message,
          }),
        );
        return;
      }

      // Stop recording coordinates
      dispatch(applicationReducer.actions.pointSelectionAborted());
    },
    [dispatch, points, pointStyles],
  );

  const toggleSelection = useCallback(() => {
    const params: PointSelectionParams = {
      type: PointSelectionType.MEASUREMENT,
      pointCount: PointCount.TWO,
      style: pointStyles[PointSelectionType.MEASUREMENT],
    };

    if (isActiveSelection[PointSelectionType.MEASUREMENT]) {
      endSelection(params);
    } else {
      startSelection(params);
    }
  }, [pointStyles, isActiveSelection, endSelection, startSelection]);

  const removeSelection = useCallback(() => {
    dispatch(applicationReducer.actions.removeMapElements(PointSelectionType.MEASUREMENT));
  }, [dispatch]);

  return (
    <>
      <Button
        variant="text"
        color="primary"
        className={classes.button}
        onClick={(e) => {
          e.stopPropagation();
          toggleSelection();
          if (isActiveSelection[PointSelectionType.MEASUREMENT]) {
            onClose();
          }
        }}
      >
        {isActiveSelection[PointSelectionType.MEASUREMENT]
          ? l10n.getString('forms-settings-points-measurement-stop')
          : l10n.getString('forms-settings-points-measurement')}
      </Button>
      <Divider />
      <Button
        variant="text"
        color="primary"
        className={classes.button}
        onClick={(e) => {
          e.stopPropagation();
          removeSelection();
        }}
        disabled={!selection.measurement}
      >
        {l10n.getString('forms-settings-points-measurement-remove-selection')}
      </Button>
    </>
  );
};
