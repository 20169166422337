import { useLocalization } from '@fluent/react';
import { Box, Button, CardContent } from '@material-ui/core';
import { useAppDispatch } from '_store';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { PaginationComponent } from '_store/components/PaginationComponent';
import usePagination from '_store/components/usePagination';
import ivimsReducer from '_store/ivim/ivimsReducers';
import { Ivim, IvimStatusFilter } from '_store/ivim/ivimsTypes';
import {
  ivimFilterStatusSelector,
  ivimsSelector,
  paginationCurrentPageSelector,
  paginationItemsPerPageSelector,
  totalIvimsSelector,
} from '_store/ivim/selectors';
import {
  mapServiceCategoryCode,
  mapServiceCategorySubCode,
  useIvimsRequest,
} from '_store/ivim/utils';
import { formatDateForCSV } from '_store/utils';
import { CheckboxItem } from '../../../../components/items/CheckboxItem';
import { ExportCSV } from '../../../../components/items/ExportCSV';
import MessageDialog from '../../../items/MessageDialog';
import CardListItem from './Card';
import { IvimListFilters } from './Filters';
import styles from './styles';

export interface IvimListLayoutProps {
  onUpdateIvim?: (idExpanded: string) => void;
  onCloneIvim?: (idExpanded: string) => void;
}

// expanded details inside Ivim list items
const IvimListLayout: FC<IvimListLayoutProps> = (crudProps) => {
  const { l10n } = useLocalization();
  const classes = styles();
  const dispatch = useAppDispatch();

  const ivims = useSelector(ivimsSelector);
  const ivimStatus = useSelector(ivimFilterStatusSelector);
  const totalItems = useSelector(totalIvimsSelector);
  const paginationCurrentPage = useSelector(paginationCurrentPageSelector);
  const paginationItemsPerPage = useSelector(paginationItemsPerPageSelector);

  const [itemsPerPage, setItemsPerPage] = useState(paginationItemsPerPage);

  const { currentPage, totalPages, goToPage } = usePagination(
    totalItems,
    itemsPerPage,
    paginationCurrentPage,
  );
  const dispatchIvimsRequest = useIvimsRequest({ currentPage, itemsPerPage });

  useEffect(() => {
    dispatchIvimsRequest();
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    if (paginationCurrentPage !== currentPage) {
      goToPage(paginationCurrentPage);
    }
  }, [paginationCurrentPage]);

  const [checked, setChecked] = useState<string[]>([]);
  const [searchFilter, setSearchFilter] = useState('');
  const [filteredStatus, setFilteredStatus] = useState(ivimStatus);
  const [showMessage, setShowMessage] = useState({
    active: false,
    typeAction: '',
    idsToDelete: null,
    typeEvent: 'ivim',
    idsToDisable: null,
  });

  const filterIvim = (val: Ivim) => {
    const searchVal = searchFilter.toLowerCase();
    const itemVal = val.id.toString().toLowerCase();
    return itemVal.indexOf(searchVal) > -1;
  };

  const handleCheckedChange = (id: string) => {
    if (checked.includes(id)) {
      setChecked(checked.filter((i) => i !== id));
    } else {
      setChecked([...checked, id]);
    }
  };

  const handleBulkCheckedChange = () => {
    if (checked.length === ivims.length) {
      setChecked([]);
    } else {
      setChecked(ivims.map((i) => i.id));
    }
  };

  const onMessageLeaveConfirmed = (type: string) => {
    if (type === 'confirm' && showMessage.typeAction === 'delete') {
      dispatch(ivimsReducer.actions.deleteIVIMs(showMessage.idsToDelete));
    }

    if (type === 'confirm' && showMessage.typeAction === 'disable') {
      dispatch(ivimsReducer.actions.disableIVIM({ ids: showMessage.idsToDisable, disable: true }));
    }

    if (type === 'confirm' && showMessage.typeAction === 'enable') {
      dispatch(ivimsReducer.actions.disableIVIM({ ids: showMessage.idsToDisable, disable: false }));
    }

    setShowMessage((sm) => ({
      ...sm,
      active: false,
      idsToDelete: null,
      idsToDisable: null,
    }));
  };

  const onDeleteIvim = (ids: string[]) => {
    setShowMessage((sm) => ({
      ...sm,
      typeAction: 'delete',
      active: true,
      idsToDelete: ids,
    }));
  };

  const onDisableIvim = (ids: string[]) => {
    setShowMessage((sm) => ({
      ...sm,
      typeAction: 'disable',
      active: true,
      idsToDisable: ids,
    }));
  };

  const onEnableIvim = (ids: string[]) => {
    setShowMessage((sm) => ({
      ...sm,
      typeAction: 'enable',
      active: true,
      idsToDisable: ids,
    }));
  };

  const transformedIvims = useMemo(() => {
    return ivims.map((ivim) => ({
      ID: ivim.id,
      [l10n.getString('forms-ivim-init-date')]: formatDateForCSV(ivim.starts),
      [l10n.getString('forms-ivim-final-date')]: formatDateForCSV(ivim.ends),
      [l10n.getString(
        'forms-ivim-reference-point',
      )]: `${ivim.referencePoint.coordinates[1]}, ${ivim.referencePoint.coordinates[0]}`,
    }));
  }, [ivims]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    goToPage(value);
  };

  return (
    <CardContent className={classes.cardContent} style={{ overflow: 'hidden' }}>
      {showMessage.active === true ? (
        <MessageDialog statusMessage={showMessage} onCloseDialog={onMessageLeaveConfirmed} />
      ) : null}

      <IvimListFilters
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
        setFilteredStatus={setFilteredStatus}
        setChecked={setChecked}
      />

      <ExportCSV
        showButton={transformedIvims.length > 0}
        data={transformedIvims}
        filename={'ivims'}
      />

      {ivims.length > 0 && (
        <Box marginBottom={2} hidden={checked.length <= 0} display={'flex'} flexDirection={'row'}>
          <CheckboxItem
            checked={checked.length === ivims.length}
            itemId={'all'}
            handleCheckedChange={handleBulkCheckedChange}
            size="small"
            label={
              checked.length === ivims.length
                ? l10n.getString('forms-event-deselect-all')
                : l10n.getString('forms-event-select-all')
            }
          />

          {filteredStatus !== IvimStatusFilter.CLOSED && (
            <>
              <Button
                className={classes.bulkButton}
                onClick={() =>
                  filteredStatus === IvimStatusFilter.DISABLED
                    ? onEnableIvim(checked)
                    : onDisableIvim(checked)
                }
                disabled={checked.length <= 0}
              >
                {filteredStatus === IvimStatusFilter.DISABLED
                  ? l10n.getString('forms-event-bulk-enable')
                  : l10n.getString('forms-event-bulk-disable')}
              </Button>
              <Button
                className={classes.bulkButton}
                onClick={() => onDeleteIvim(checked)}
                disabled={checked.length <= 0}
              >
                {l10n.getString('forms-event-bulk-delete')}
              </Button>
            </>
          )}
        </Box>
      )}

      <div style={{ overflow: 'auto' }}>
        {ivims &&
          ivims
            .filter(filterIvim)
            .map((item) => (
              <CardListItem
                asCard={true}
                key={item.id}
                ivim={item}
                text={`${mapServiceCategoryCode(
                  item.serviceCategoryCode,
                  l10n,
                )} - ${mapServiceCategorySubCode(item.serviceSubCategoryCode, l10n)}`}
                onUpdate={() => crudProps.onUpdateIvim(item.id)}
                onDelete={() => onDeleteIvim([item.id])}
                onDisable={() => onDisableIvim([item.id])}
                onEnable={() => onEnableIvim([item.id])}
                onClone={() => crudProps.onCloneIvim(item.id)}
                onCheckedChange={handleCheckedChange}
                checked={checked.includes(item.id)}
                showCheckbox={true}
              />
            ))}
        {ivims.length === 0 && <>{l10n.getString('forms-ivim-list-empty')}</>}
      </div>
      {ivims.length > 0 && (
        <PaginationComponent
          currentPage={currentPage}
          totalPages={totalPages}
          handleChange={handleChange}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          goToPage={goToPage}
        />
      )}
    </CardContent>
  );
};

export default React.memo(IvimListLayout);
