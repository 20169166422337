import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(2),
  },
  input: {
    fontSize: 13,
  },
  mandatory: {
    color: 'red',
    marginLeft: 5,
  },
}));

export default styles;
