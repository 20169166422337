import { useLocalization } from '@fluent/react';
import { Box, Button, CardContent } from '@material-ui/core';
import { useAppDispatch } from '_store';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { PaginationComponent } from '_store/components/PaginationComponent';
import usePagination from '_store/components/usePagination';
import denmsReducer from '_store/denm/denmsReducers';
import { DenmStatusFilter } from '_store/denm/denmsTypes';
import {
  denmFilterStatusSelector,
  denmsFilteredSelector,
  paginationCurrentPageSelector,
  paginationItemsPerPageSelector,
  totalDenmsSelector,
} from '_store/denm/selectors';
import { useDenmsRequest } from '_store/denm/utils';
import { CheckboxItem } from '../../../../components/items/CheckboxItem';
import MessageDialog from '../../../items/MessageDialog';
import CardListItem from './Card';
import { ExportCSV } from './ExportCSV';
import { DenmListFilters } from './Filters';
import styles from './styles';

export interface DenmListLayoutProps {
  onUpdateDenm?: (idExpanded: string) => void;
  onCloneDenm?: (idExpanded: string) => void;
}

// expanded details inside Denm list items
const DenmListLayout: FC<DenmListLayoutProps> = (crudProps) => {
  const { l10n } = useLocalization();
  const classes = styles();
  const dispatch = useAppDispatch();

  const denms = useSelector(denmsFilteredSelector);
  const denmsStatus = useSelector(denmFilterStatusSelector);
  const totalItems = useSelector(totalDenmsSelector);
  const paginationCurrentPage = useSelector(paginationCurrentPageSelector);
  const paginationItemsPerPage = useSelector(paginationItemsPerPageSelector);

  const [itemsPerPage, setItemsPerPage] = useState(paginationItemsPerPage);

  const { currentPage, totalPages, goToPage } = usePagination(
    totalItems,
    itemsPerPage,
    paginationCurrentPage,
  );
  const dispatchDenmsRequest = useDenmsRequest({
    currentPage,
    itemsPerPage,
  });

  useEffect(() => {
    dispatchDenmsRequest();
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    if (paginationCurrentPage !== currentPage) {
      goToPage(paginationCurrentPage);
    }
  }, [paginationCurrentPage]);

  const [checked, setChecked] = useState<string[]>([]);
  const [filteredStatus, setFilteredStatus] = useState(denmsStatus);
  const [showMessage, setShowMessage] = useState({
    active: false,
    typeAction: '',
    idsToDelete: null,
    typeEvent: 'denm',
    idsToDisable: null,
  });

  useEffect(() => {
    dispatchDenmsRequest();
  }, []);

  const handleCheckedChange = (id: string) => {
    if (checked.includes(id)) {
      setChecked(checked.filter((i) => i !== id));
    } else {
      setChecked([...checked, id]);
    }
  };

  const handleBulkCheckedChange = () => {
    if (checked.length === denms.length) {
      setChecked([]);
    } else {
      setChecked(denms.map((i) => i.id));
    }
  };

  const onMessageLeaveConfirmed = (type: string) => {
    if (type === 'confirm' && showMessage.typeAction === 'delete') {
      dispatch(denmsReducer.actions.deleteDENMs(showMessage.idsToDelete));
    }

    if (type === 'confirm' && showMessage.typeAction === 'disable') {
      dispatch(denmsReducer.actions.disableDENM({ ids: showMessage.idsToDisable, disable: true }));
    }

    if (type === 'confirm' && showMessage.typeAction === 'enable') {
      dispatch(denmsReducer.actions.disableDENM({ ids: showMessage.idsToDisable, disable: false }));
    }

    setShowMessage((sm) => ({
      ...sm,
      active: false,
      idsToDelete: null,
      idsToDisable: null,
    }));
  };

  const onDeleteDenm = (ids: string[]) => {
    setShowMessage((sm) => ({
      ...sm,
      typeAction: 'delete',
      active: true,
      idsToDelete: ids,
    }));
  };

  const onDisableDenm = (ids: string[]) => {
    setShowMessage((sm) => ({
      ...sm,
      typeAction: 'disable',
      active: true,
      idsToDisable: ids,
    }));
  };

  const onEnableDenm = (ids: string[]) => {
    setShowMessage((sm) => ({
      ...sm,
      typeAction: 'enable',
      active: true,
      idsToDisable: ids,
    }));
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    goToPage(value);
  };

  return (
    <CardContent className={classes.cardContent} style={{ overflow: 'hidden' }}>
      {showMessage.active === true ? (
        <MessageDialog statusMessage={showMessage} onCloseDialog={onMessageLeaveConfirmed} />
      ) : null}

      <DenmListFilters
        setFilteredStatus={setFilteredStatus}
        setChecked={setChecked}
        itemsPerPage={itemsPerPage}
      />

      <ExportCSV show={denms.length > 0} filename={'denms'} />

      {denms.length > 0 && (
        <Box marginBottom={2} hidden={checked.length <= 0} display={'flex'} flexDirection={'row'}>
          <CheckboxItem
            checked={checked.length === denms.length}
            itemId={'all'}
            handleCheckedChange={handleBulkCheckedChange}
            size="small"
            label={
              checked.length === denms.length
                ? l10n.getString('forms-event-deselect-all')
                : l10n.getString('forms-event-select-all')
            }
          />

          {filteredStatus !== DenmStatusFilter.CLOSED && (
            <>
              <Button
                className={classes.bulkButton}
                onClick={() =>
                  filteredStatus === DenmStatusFilter.DISABLED
                    ? onEnableDenm(checked)
                    : onDisableDenm(checked)
                }
                disabled={checked.length <= 0}
              >
                {filteredStatus === DenmStatusFilter.DISABLED
                  ? l10n.getString('forms-event-bulk-enable')
                  : l10n.getString('forms-event-bulk-disable')}
              </Button>
              <Button
                className={classes.bulkButton}
                onClick={() => onDeleteDenm(checked)}
                disabled={checked.length <= 0}
              >
                {l10n.getString('forms-event-bulk-delete')}
              </Button>
            </>
          )}
        </Box>
      )}

      <div style={{ overflow: 'auto' }}>
        {denms &&
          denms.map((item) => (
            <CardListItem
              asCard={true}
              key={item.id}
              denm={item}
              onUpdate={() => crudProps.onUpdateDenm(item.id)}
              onDelete={() => onDeleteDenm([item.id])}
              onDisable={() => onDisableDenm([item.id])}
              onEnable={() => onEnableDenm([item.id])}
              onClone={() => crudProps.onCloneDenm(item.id)}
              onCheckedChange={handleCheckedChange}
              checked={checked.includes(item.id)}
              showCheckbox={true}
            />
          ))}
        {denms.length === 0 && <>{l10n.getString('forms-denm-list-empty')}</>}
      </div>
      {denms.length > 0 && (
        <PaginationComponent
          currentPage={currentPage}
          totalPages={totalPages}
          handleChange={handleChange}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          goToPage={goToPage}
        />
      )}
    </CardContent>
  );
};

export default React.memo(DenmListLayout);
