import { useState } from 'react';

const usePagination = (totalItems: number, itemsPerPage: number, currentPageSelected: number) => {
  const [currentPage, setCurrentPage] = useState(currentPageSelected);
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const goToPage = (pageNumber) => {
    setCurrentPage(Math.min(Math.max(pageNumber, 1), totalPages));
  };

  return {
    currentPage,
    totalPages,
    goToNextPage,
    goToPreviousPage,
    goToPage,
  };
};

export default usePagination;
