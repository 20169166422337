import { TextField, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { FC } from 'react';

import styles from './styles';

const CustomAutocomplete = styles(Autocomplete);

export interface FreeformSelectProps {
  label: string;
  presets: string[];
  default?: string;
  value?: string;
  onChange?: (ev: React.ChangeEvent, value: string) => void;
  disabled?: boolean;
  tooltip?: string;
  numeric?: boolean;
  mandatory?: boolean;
}

const FreeformSelect: FC<FreeformSelectProps> = (props: FreeformSelectProps) => {
  // const [anchorEl, setAnchorEl] = useState(null);

  // const showInfoSnipped = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const hideInfoSnipped = (event) => {
  //   setAnchorEl(null);
  // };

  // const open = Boolean(anchorEl);
  // const id = open ? 'simple-popover' : undefined;

  let validation = {};

  if (props.numeric) {
    validation = {
      error: parseFloat(props.value) <= 0,
      helperText: parseFloat(props.value) <= 0 ? 'The value must be higher than 0' : null,
    };
  }

  return (
    <CustomAutocomplete
      freeSolo={true}
      options={props.presets}
      defaultValue={props.default}
      value={props.value}
      onInputChange={props.onChange}
      disabled={props.disabled}
      renderInput={(params) => (
        /* if (props.tooltip) {
          params.InputProps.endAdornment = (
            <>
              {params.InputProps.endAdornment}
              <InputAdornment position="end">
                <IconButton
                  //onClick={hideInfoSnipped}
                  onMouseDown={showInfoSnipped}
                  edge="end"
                >
                  <HelpIcon />
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={hideInfoSnipped}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'left',
                    }}
                  >
                    <Typography style={{ padding: 10 }}>
                      {props.tooltip}
                    </Typography>
                  </Popover>
                </IconButton>
              </InputAdornment>
            </>
          );
        } */
        <Tooltip title={props.tooltip} placement="right">
          <TextField
            {...params}
            variant="standard"
            label={
              <span>
                {props.label}
                {props.mandatory && <span style={{ color: 'red', marginLeft: 5 }}>*</span>}
              </span>
            }
            fullWidth
            disabled={props.disabled}
            InputLabelProps={{
              style: {
                fontSize: 13,
              },
            }}
            // multiline={true}
            margin="dense"
            {...validation}
          />
        </Tooltip>
      )}
    />
  );
};

export default React.memo(FreeformSelect);
