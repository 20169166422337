import { useLocalization } from '@fluent/react';
import React, { FC, useEffect, useRef } from 'react';
import { Tooltip } from 'react-leaflet';

import { defaultOffset } from '.';
import { events } from '../../../icons/utils/cam';
import styles from '../styles';
import { HoveringCAM, HoverTooltipProps } from './types';

const CamInfoTooltip: FC<HoverTooltipProps> = ({ hoveringElement }) => {
  const { l10n } = useLocalization();
  const classes = styles();
  const persistentVehicleRole = useRef<string>('---');

  const { cam, offset } = hoveringElement as HoveringCAM;
  const { stationId, speedValue, stationType } = cam;

  useEffect(() => {
    if (cam?.vehicleRole && persistentVehicleRole.current === '---') {
      persistentVehicleRole.current = cam.vehicleRole;
    }
  }, [cam]);

  const speedValueConverted = Math.round(speedValue * 0.036);
  const stationTypeText = events[stationType]?.text || 'unknown';
  // const ev = camEvents[stationtype];
  // const icon = camIcons[ev?.icon] ?? camIcons['unknown.png'];

  return (
    <Tooltip direction="top" offset={offset || defaultOffset}>
      <p className={classes.tooltipHeader}>
        {/* <img src={icon} style={{ width: 'auto', height: '2rem', marginRight: '0.5rem' }} />
        {l10n.getString(`types-stationTypes-${ev.text ?? 'unknown'}`)} */}
      </p>
      <table>
        <tbody>
          <tr>
            <th className={classes.tooltipTableHeader}>
              {l10n.getString('forms-info-box-station-id')}
            </th>
            <td>{stationId}</td>
          </tr>
          <tr>
            <th className={classes.tooltipTableHeader}>{l10n.getString('forms-info-box-speed')}</th>
            <td>{l10n.getString('forms-info-box-speed-fmt', { speed: speedValueConverted })}</td>
          </tr>
          <tr>
            <th className={classes.tooltipTableHeader}>
              {l10n.getString('forms-info-box-station-type')}
            </th>
            <td> {l10n.getString(`types-stationTypes-${stationTypeText}`)}</td>
          </tr>
          <tr>
            <th className={classes.tooltipTableHeader}>
              {l10n.getString('forms-info-box-vehicle-role')}
            </th>
            <td>{persistentVehicleRole.current}</td>
          </tr>
        </tbody>
      </table>
    </Tooltip>
  );
};

export default CamInfoTooltip;
