import { useLocalization } from '@fluent/react';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';
import { useCSVDownloader } from 'react-papaparse';
import styles from './styles';

interface ExportCSVProps {
  showButton: boolean;
  data: {
    [x: string]: string;
  }[];
  filename: string;
}

export const ExportCSV = ({ showButton = false, data, filename }: ExportCSVProps) => {
  const { CSVDownloader, Type } = useCSVDownloader();
  const classes = styles();
  const { l10n } = useLocalization();

  const downloadLabel = l10n.getString('forms-event-download-csv');

  const filenameWithDate = useMemo(() => {
    const date = new Date();
    const formattedDate = `${date.getFullYear()}${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}_${date
      .getHours()
      .toString()
      .padStart(2, '0')}${date.getMinutes().toString().padStart(2, '0')}${date
      .getSeconds()
      .toString()
      .padStart(2, '0')}`;
    return `${filename}_${formattedDate}`;
  }, [filename]);

  if (!showButton) {
    return null;
  }

  return (
    <CSVDownloader
      type={Type.Button}
      filename={filenameWithDate}
      bom={true}
      config={{
        delimiter: ';',
      }}
      data={data}
      className={classes.downloadButton}
    >
      {downloadLabel}
      <FontAwesomeIcon icon={faFileArrowDown} size="lg" style={{ marginLeft: 10 }} />
    </CSVDownloader>
  );
};
