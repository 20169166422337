import { RootState } from '_store';
import applicationReducer from '_store/application/reducer';
import { selectionSelector } from '_store/application/selectors';
import { PointSelectionType } from '_store/application/types';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useMapEvents } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import ContextMenu from './ContextMenu';

const MapEvents: FC = () => {
  const [contextMenuPosition, setContextMenuPosition] = useState(null);
  const mapRef = useRef(null);
  const contextMenuRef = useRef(null);

  const dispatch = useDispatch();
  const selection = useSelector(selectionSelector);
  const pointSelection = useSelector((state: RootState) => state.application.pointSelections);
  const { active: psActive } = pointSelection;
  const currentSelection = useSelector((state: RootState) => state.application.currentSelection);

  const handleClick = useCallback(
    (clickEvent) => {
      if (
        contextMenuRef.current &&
        contextMenuRef.current.contains(clickEvent.originalEvent.target)
      ) {
        return;
      }

      if (selection.active) {
        dispatch(
          applicationReducer.actions.pointSelected([clickEvent.latlng.lng, clickEvent.latlng.lat]),
        );
      }

      if (!psActive && currentSelection !== PointSelectionType.MEASUREMENT) {
        setContextMenuPosition(null);
      }
    },
    [dispatch, selection, psActive, currentSelection],
  );

  const handleContextMenu = useCallback((e) => {
    setContextMenuPosition(e.latlng);
  }, []);

  const handleClickOutside = useCallback(
    (event) => {
      if (
        contextMenuRef.current &&
        !contextMenuRef.current.contains(event.target) &&
        psActive &&
        currentSelection !== PointSelectionType.MEASUREMENT
      ) {
        setContextMenuPosition(null);
      }
    },
    [psActive, currentSelection],
  );

  const map = useMapEvents({
    click: handleClick,
    contextmenu: handleContextMenu,
  });

  mapRef.current = map;

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  if (!contextMenuPosition) return null;

  const point = mapRef.current.latLngToContainerPoint(contextMenuPosition);

  return (
    <div ref={contextMenuRef}>
      <ContextMenu
        point={point}
        position={contextMenuPosition}
        onClose={() => setContextMenuPosition(null)}
      />
    </div>
  );
};

export default MapEvents;
