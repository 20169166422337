import React, { useState, useEffect, useMemo } from 'react';
import { Box, CardContent, Typography } from '@material-ui/core';
import { useLocalization } from '@fluent/react';
import { useSelector } from 'react-redux';
import { events } from '../../../../components/icons/utils/cam';
import { camsSelector } from '_store/vehicles/selectors';
import { settingsSelector } from '_store/settings/selectors';
import styles from './styles';

const CamListLayout = () => {
  const { l10n } = useLocalization();
  const classes = styles();
  const cams = useSelector(camsSelector);
  const camSettings = useSelector(settingsSelector).storage.cam;
  const obusFilteredEnabled = camSettings.obuFilterEnabled;
  const obus = camSettings.obu;

  const [persistentRoles, setPersistentRoles] = useState(new Map());

  const obuMap = useMemo(() => {
    if (!obusFilteredEnabled) {
      return new Map();
    }
    const map = new Map();
    obus.forEach((obu) => {
      map.set(obu.id, obu.name);
    });
    return map;
  }, [obus, obusFilteredEnabled]);

  useEffect(() => {
    const newRoles = new Map(persistentRoles);
    let hasChanges = false;

    cams.forEach((cam) => {
      const currentRole = newRoles.get(cam.stationId);
      if (!currentRole || (currentRole === null && cam.vehicleRole !== null)) {
        newRoles.set(cam.stationId, cam.vehicleRole ?? '---');
        hasChanges = true;
      }
    });

    if (hasChanges) {
      setPersistentRoles(newRoles);
    }
  }, [cams]);

  const camsFiltered = useMemo(() => {
    return cams.map((cam) => {
      const obuName = obuMap.get(cam.stationId) || 'Unknown';
      const persistentRole = persistentRoles.get(cam.stationId) || '---';
      return { ...cam, obuName, persistentRole };
    });
  }, [cams, obuMap, persistentRoles]);

  return (
    <CardContent className={classes.cardContent} style={{ overflow: 'hidden' }}>
      <div style={{ overflow: 'auto' }}>
        {camsFiltered.map((cam, index) => {
          const stationTypeText = events[cam.stationType]?.text || 'unknown';
          const speedValueConverted = Math.round(cam.speedValue * 0.036);

          return (
            <Box
              display="flex"
              flexDirection="column"
              className={classes.boxContent}
              key={cam.stationId || index}
            >
              <Typography variant="body2">
                <strong>OBU</strong>: {cam.obuName}
              </Typography>
              <Typography variant="body2">
                <strong>{l10n.getString('forms-info-box-station-id')}</strong>: {cam.stationId}
              </Typography>
              <Typography variant="body2">
                <strong>{l10n.getString('forms-info-box-station-type')}</strong>:{' '}
                {l10n.getString(`types-stationTypes-${stationTypeText}`)}
              </Typography>
              <Typography variant="body2">
                <strong>{l10n.getString('forms-info-box-speed')}</strong>:{' '}
                {l10n.getString('forms-info-box-speed-fmt', { speed: speedValueConverted })}
              </Typography>
              <Typography variant="body2">
                <strong>{l10n.getString('forms-info-box-vehicle-role')}</strong>:{' '}
                {cam.persistentRole}
              </Typography>
            </Box>
          );
        })}
        {camsFiltered.length === 0 && l10n.getString('forms-cam-list-empty')}
      </div>
    </CardContent>
  );
};

export default React.memo(CamListLayout);
