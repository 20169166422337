import { devicesSelector } from '_store/devices/selectors';
import manageRsuFormReducer from '_store/forms/manage-rsu';
import { useLocalization } from '@fluent/react';
import { Box, Button, CardContent, CardHeader, IconButton } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RsuManage from './RsuManage';
import styles from './styles';
import { CrudType, CrudTypeEnum } from './types';

const RsuLayout: FC<CrudType> = ({ id, type, onGoingBack, onClose }) => {
  const { l10n } = useLocalization();
  const classes = styles();

  let LayoutType = null;
  let CardHeaderTitle = null;

  const dispatch = useDispatch();
  useEffect(() => {
    if (type === CrudTypeEnum.NEW_RSU) {
      dispatch(manageRsuFormReducer.actions.resetForm());
    }
  }, [type]);

  switch (type) {
    case CrudTypeEnum.NEW_RSU:
      CardHeaderTitle = l10n.getString('forms-rsu-add-new');
      LayoutType = <RsuManage id={id} type={type} />;
      break;
    case CrudTypeEnum.UPDATE_RSU: {
      const devices = useSelector(devicesSelector);
      const { name } = devices.find((d) => d.id === id);

      CardHeaderTitle = `${l10n.getString('forms-rsu-update-rsu')} ${name}`;
      LayoutType = <RsuManage id={id} type={type} />;
      break;
    }
    case CrudTypeEnum.DELETE_RSU:
      LayoutType = null;
      break;

    default:
      CardHeaderTitle = `Add a new RSU`;
      LayoutType = null;
  }

  return (
    <CardContent className={classes.cardContent}>
      <Box className={classes.box}>
        <Button
          variant="text"
          onClick={onGoingBack}
          size="small"
          className={classes.backBnt}
          startIcon={<ArrowBackIosIcon />}
        >
          {l10n.getString('back')}
        </Button>

        <IconButton className={classes.closeBnt} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <CardHeader
        title={CardHeaderTitle}
        className={classes.header}
        titleTypographyProps={{ variant: 'h6' }}
      />

      {LayoutType}
    </CardContent>
  );
};

export default React.memo(RsuLayout);
