import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '..';
import { ivimsAdapter } from './ivimsReducers';

const ivimStateDomain = (state: RootState) => state.ivims;

export const ivimsSelector = createSelector(ivimStateDomain, (ivim) =>
  ivimsAdapter.getSelectors().selectAll(ivim),
);

export const totalIvimsSelector = createSelector(ivimStateDomain, (ivim) => ivim.total);

export const ivimFilterStatusSelector = createSelector(
  ivimStateDomain,
  (ivim) => ivim.statusFilter,
);

export const ivimFilterDateSelector = createSelector(ivimStateDomain, (ivim) => ivim.dateFilter);

export const ivimFilterCreatedFromSelector = createSelector(
  ivimStateDomain,
  (ivim) => ivim.createdFromFilter,
);

export const ivimFilterCreatedToSelector = createSelector(
  ivimStateDomain,
  (ivim) => ivim.createdToFilter,
);

export const ivimsSelectorForSelectedRoadSegment = (roadSegmentIds: string[]) =>
  createSelector(ivimsSelector, (ivim) =>
    ivim.filter((d) => roadSegmentIds.includes(d.roadSegmentId)),
  );

export const paginationCurrentPageSelector = createSelector(
  ivimStateDomain,
  (ivim) => ivim.paginationCurrentPage,
);

export const paginationItemsPerPageSelector = createSelector(
  ivimStateDomain,
  (ivim) => ivim.paginationItemsPerPage,
);
