import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { Cam, CamReducerState } from './vehiclesTypes';

const initialState: CamReducerState = {
  loading: false,
};

export const camAdapter = createEntityAdapter({
  selectId: (el: Cam) => el.stationId,
});

const camsReducer = createSlice({
  name: 'cams',
  initialState: camAdapter.getInitialState(initialState),
  reducers: {
    camRequest: (state) => {
      state.loading = true;
    },
    camResponse: (state, action) => {
      if (state.loading) {
        camAdapter.setAll(state, action.payload);
        state.loading = false;
      }
    },
    camsAddOne: (state, action) => {
      camAdapter.addOne(state, action);
      state.loading = false;
    },
    camsAddMany: camAdapter.addMany,
    camUpdate: camAdapter.updateOne,

    camRemove: camAdapter.removeOne,
    camRemoveMany: camAdapter.removeMany,

    camUpsertOne: (state, action) => {
      camAdapter.upsertOne(state, action);
      state.loading = false;
    },
    camUpsertMany: camAdapter.upsertMany,

    resetLoading: (state) => {
      state.loading = false;
    },
  },
});

export default camsReducer;
