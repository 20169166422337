import applicationReducer from '_store/application/reducer';
import { dialogSelector, selectionSelector } from '_store/application/selectors';
import { DialogType } from '_store/application/types';
import { Denm, DenmSourceFilter } from '_store/denm/denmsTypes';
import { defaultIcon, denmStatuses, useCause } from '_store/denm/utils';
import L, { Map } from 'leaflet';
import React, { FC, ReactElement, useMemo } from 'react';
import { Marker } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';

import { eventIcon } from '../../../../icons';
import denmIcons from '../../../../icons/denm';
import { iconForCause } from '../../../../icons/utils/denm';
import { canInfoViewBeOpened } from '../../../InfoView/utils';
import DenmInfoTooltip from '../../InfoTooltips/DenmInfoTooltip';
import { HoveringElementType } from '../../InfoTooltips/types';
import { toLatLng } from '../../utils';

const iconSize = new L.Point(48, 48);
const iconAnchor = new L.Point(iconSize.x / 2, iconSize.y);
const tooltipOffset = new L.Point(0, -iconSize.y);

const DenmMarker: FC<{
  denm: Denm;
  mapRef: Map;
}> = ({ denm, mapRef }): ReactElement => {
  const dispatch = useDispatch();

  const dialog = useSelector(dialogSelector);
  const selection = useSelector(selectionSelector);

  const markerPosition = useMemo(() => toLatLng(denm?.referencePoint?.coordinates), [denm]);

  const eventHandlers = useMemo(
    () => ({
      click: () => {
        if (canInfoViewBeOpened(dialog, selection)) {
          dispatch(
            applicationReducer.actions.infoSelected({
              type: DialogType.DENM,
              id: denm.id,
            }),
          );
          mapRef.panTo(markerPosition);
        }
      },
    }),
    [mapRef, denm, markerPosition, dialog, selection],
  );

  const isDenmSelected = useMemo(() => {
    return (
      dialog &&
      [DialogType.DENM, DialogType.DENM_PATH].includes(dialog.type) &&
      dialog.id === denm.id
    );
  }, [dialog, denm]);

  const [cause, subcause] = useCause(denm);
  const { isDenmActive, disabled } = denmStatuses(denm);

  return (
    <Marker
      position={markerPosition}
      icon={
        new L.Icon({
          iconSize,
          // iconUrl: denmIcons[cause.iconUploadKey ?? defaultIcon].png,
          iconUrl: `data:image/svg+xml;utf8,${eventIcon(
            (denmIcons[iconForCause(cause, subcause)] ?? denmIcons[defaultIcon]).base64,
            disabled ? 'disabled' : 'progress',
          )}`,
          iconAnchor,
          className: `marker-event-${isDenmSelected ? 'selected' : 'unselected'} marker-event-${
            disabled || isDenmActive ? 'progress' : 'terminated'
          } ${
            denm.source === DenmSourceFilter.CONNEX &&
            `marker-event-pulsing-${disabled || isDenmActive ? 'progress' : 'terminated'}`
          }
          }`,
        })
      }
      eventHandlers={eventHandlers}
    >
      <DenmInfoTooltip
        hoveringElement={{
          type: HoveringElementType.DENM,
          denm,
          offset: tooltipOffset,
        }}
      />
    </Marker>
  );
};

export default React.memo(DenmMarker);
