/* eslint-disable simple-import-sort/imports */
import { Cam } from '_store/vehicles/vehiclesTypes';
import L, { Map } from 'leaflet';
import React, { FC, ReactElement, useMemo } from 'react';
import { Marker, Polyline } from 'react-leaflet';

import applicationReducer from '_store/application/reducer';
import { dialogSelector, selectionSelector } from '_store/application/selectors';
import { DialogType } from '_store/application/types';
import { useDispatch, useSelector } from 'react-redux';
import { events } from '../../../../../components/icons/utils/cam';
import { canInfoViewBeOpened } from '../../../../../components/routes/InfoView/utils';
import camIcons from '../../../../icons/cam';
import CamInfoTooltip from '../../InfoTooltips/CamInfoTooltip';
import { HoveringElementType } from '../../InfoTooltips/types';
import { iconSizePx, toLatLng } from '../../utils';

export interface CamMarkerProps {
  cam: Cam;
  mapRef: Map;
}

const CamMarker: FC<CamMarkerProps> = ({ cam, mapRef }): ReactElement => {
  const dispatch = useDispatch();
  const dialog = useSelector(dialogSelector);
  const selection = useSelector(selectionSelector);

  const markerPosition = useMemo(() => toLatLng(cam?.referencePoint?.coordinates), [cam]);

  const eventHandlers = useMemo(
    () => ({
      click: () => {
        if (canInfoViewBeOpened(dialog, selection)) {
          dispatch(
            applicationReducer.actions.infoSelected({
              type: DialogType.CAM,
              id: cam.id,
            }),
          );
          mapRef.panTo(markerPosition);
        }
      },
    }),
    [mapRef, cam, markerPosition, dialog, selection],
  );

  const icon = useMemo(() => {
    return camIcons[cam.stationType in events ? events[cam.stationType].icon : 'unknown.png'];
  }, [cam.stationType, events]);

  return (
    <React.Fragment>
      {cam.pathHistory?.coordinates.length > 1 && (
        <Polyline
          positions={cam.pathHistory.coordinates.map((c) => toLatLng(c))}
          weight={2}
          // geodesic: true,
          // zIndex: 16,
        />
      )}
      <Marker
        position={toLatLng(cam.referencePoint.coordinates)}
        icon={
          new L.Icon({
            iconSize: new L.Point(iconSizePx, iconSizePx),
            iconUrl: icon,
            iconAnchor: new L.Point(19, 19),
          })
        }
        eventHandlers={eventHandlers}
      >
        <CamInfoTooltip
          hoveringElement={{
            type: HoveringElementType.CAM,
            cam,
          }}
        />
      </Marker>
    </React.Fragment>
  );
};
export default React.memo(CamMarker);
