import { Coordinate } from '_lib/api';

export enum RsuStatusCode {
  UP = 'up',
  WARNING = 'warning',
  DOWN = 'down',
  AGG = 'agg',
  ERROR = 'error',
  UNKNOWN = 'unknown',
}

export interface TraceZoneCoordinates {
  type: string;
  bbox: number[];
  coordinates: Coordinate[];
}

export interface HistoryZoneCoordinates {
  type: string;
  bbox: number[];
  coordinates: Coordinate[];
}

export interface ReferencePoint {
  type: string;
  bbox: number[];
  coordinates: Coordinate[];
}

export interface DeviceReducerState {
  loading: boolean;
}
