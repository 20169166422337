import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  formControl: {
    marginLeft: 0,
  },
  formControlLabel: {
    fontSize: '14px',
    marginLeft: 10,
  },
  checkbox: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  checkboxChecked: {
    color: '#005abb',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export default styles;
