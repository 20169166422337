import { RSU } from '_lib/api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ManageRsuForm {
  latitude: number;
  longitude: number;
  distributionHost: string;
  distributionPort: number;
  name: string;
  stationId: string;
  roadSegmentId?: string;
  id?: string;
  direction?: string;
  objectType?: string;
  km?: string;
}

const initialState: ManageRsuForm = {
  latitude: NaN,
  longitude: NaN,
  distributionHost: '',
  distributionPort: NaN,
  name: '',
  stationId: '',
  direction: null,
  objectType: null,
  km: null,
};

const manageRsuFormReducer = createSlice({
  name: 'rsuManage',
  initialState,
  reducers: {
    nameChanged(state, action: PayloadAction<string>) {
      state.name = action.payload;
    },
    portChanged(state, action: PayloadAction<number>) {
      state.distributionPort = action.payload;
    },
    ipChanged(state, action: PayloadAction<string>) {
      state.distributionHost = action.payload;
    },
    latitudeChanged(state, action: PayloadAction<number>) {
      state.latitude = action.payload;
    },
    longitudeChanged(state, action: PayloadAction<number>) {
      state.longitude = action.payload;
    },
    stationIdChanged(state, action: PayloadAction<string>) {
      state.stationId = action.payload;
    },
    roadSegmentIdChanged(state, action: PayloadAction<string>) {
      state.roadSegmentId = action.payload;
    },
    directionChanged(state, action: PayloadAction<string>) {
      state.direction = action.payload;
    },
    objectTypeChanged(state, action: PayloadAction<string>) {
      state.objectType = action.payload;
    },
    kmChanged(state, action: PayloadAction<string>) {
      state.km = action.payload;
    },
    prefillForm(_state, action: PayloadAction<RSU>) {
      const device = action.payload;
      return {
        ...initialState,
        latitude: device.location.coordinates[1],
        longitude: device.location.coordinates[0],
        distributionPort: device.distribution.port,
        distributionHost: device.distribution.host,
        name: device.name,
        id: device.id,
        stationId: device.stationId,
        roadSegmentId: device.roadSegmentId,
        direction: device.direction,
        objectType: device.type,
        km: device.km,
      };
    },
    resetForm(_state, _action: PayloadAction<void>) {
      return initialState;
    },
  },
});

export default manageRsuFormReducer;
