import { makeStyles } from '@material-ui/core';

const styles = makeStyles((_theme) => ({
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: '0 15px',
    textTransform: 'uppercase',
  },
}));

export default styles;
