import { ReactLocalization } from '@fluent/react';
import { selectedRoadSegmentIdsSelector } from '_store/roads/selectors';
import { isFuture } from 'date-fns';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as ivimEvents from '../../app/assets/ivimEvents.json';
import ivimsReducer from './ivimsReducers';
import { Ivim } from './ivimsTypes';
import {
  ivimFilterCreatedFromSelector,
  ivimFilterCreatedToSelector,
  ivimFilterDateSelector,
  ivimFilterStatusSelector,
  ivimsSelectorForSelectedRoadSegment,
} from './selectors';

export const defaultIcon = 'TrafficSign_DangerWarning.png';

export const textLanguageISO639 = [
  { value: 'it', label: 'it' },
  { value: 'en', label: 'en' },
  { value: 'fr', label: 'fr' },
  { value: 'de', label: 'de' },
];

export const ivimStatuses = (ivim: Ivim) => {
  const disabled = ivim.disabled;
  const scheduled = isFuture(new Date(ivim.starts));
  const isIvimActive = disabled || scheduled ? false : isFuture(new Date(ivim.ends));
  return {
    isIvimActive,
    disabled,
    scheduled,
  };
};

export const mapPictogramText = (
  pictogramNature: number,
  pictogramSerialNumber: number,
  serviceCategoryCode: string,
  serviceSubCategoryCode: string,
) => {
  const matchedPictogram = ivimEvents.find(
    (i) =>
      i.pictogramCategoryCode === pictogramNature &&
      i.pictogramSerialNumber === pictogramSerialNumber &&
      i.serviceCategoryCode === serviceCategoryCode &&
      i.serviceSubCategoryCode === serviceSubCategoryCode,
  );
  if (matchedPictogram) {
    return matchedPictogram.description;
  }
  return '-';
};

export const useIvimsRequest = ({
  currentPage,
  itemsPerPage,
}: {
  currentPage?: number;
  itemsPerPage?: number;
}) => {
  const dispatch = useDispatch();
  const selectedRoadSegmentIds = useSelector(selectedRoadSegmentIdsSelector);
  const ivimsStatus = useSelector(ivimFilterStatusSelector);
  const ivimsDate = useSelector(ivimFilterDateSelector);
  const ivimsCreatedFrom = useSelector(ivimFilterCreatedFromSelector);
  const ivimsCreatedTo = useSelector(ivimFilterCreatedToSelector);

  const dispatchIvimsRequest = (roadSegmentIds?: string[]) => {
    roadSegmentIds = roadSegmentIds?.length > 0 ? roadSegmentIds : selectedRoadSegmentIds;
    dispatch(
      ivimsReducer.actions.ivimsRequest({
        status: ivimsStatus,
        roadSegmentIds: roadSegmentIds,
        date: ivimsDate,
        createdFrom: ivimsCreatedFrom,
        createdTo: ivimsCreatedTo,
        currentPage,
        itemsPerPage,
      }),
    );
  };

  return dispatchIvimsRequest;
};

export const serviceProviderOption = {
  value: process.env.SERVICE_PROVIDER_ID || 'emeras',
  label: process.env.SERVICE_PROVIDER_ID || 'emeras',
};

export const mapServiceCategoryCode = (code: string, l10n: ReactLocalization) => {
  const serviceCategoryCode = [...new Set(ivimEvents.map((i) => i.serviceCategoryCode))];
  const matchedCode = serviceCategoryCode.find((c) => c === code);
  if (matchedCode) {
    return l10n.getString(`forms-ivim-ser-cat-cod-enum-${matchedCode}`);
  }
  return '-';
};

export const mapServiceCategorySubCode = (code: string, l10n: ReactLocalization) => {
  const serviceSubCategoryCode = [...new Set(ivimEvents.map((i) => i.serviceSubCategoryCode))];
  const matchedCode = serviceSubCategoryCode.find((c) => c === code);
  if (matchedCode) {
    return l10n.getString(`forms-ivim-ser-cat-sub-cod-enum-${matchedCode}`);
  }
  return '-';
};

export function useIvimsForRoadSegment(roadSegmentIds: string[]) {
  const memoizedSelector = useMemo(
    () => ivimsSelectorForSelectedRoadSegment(roadSegmentIds),
    [roadSegmentIds],
  );
  return useSelector(memoizedSelector);
}
