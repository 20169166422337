import { useLocalization } from '@fluent/react';
import { faFilter, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, IconButton, Popover, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useAppDispatch } from '_store';
import applicationReducer from '_store/application/reducer';
import { DialogType } from '_store/application/types';
import denmsReducer from '_store/denm/denmsReducers';
import { DenmStatusFilter } from '_store/denm/denmsTypes';
import React, { useState } from 'react';
import styles from '../styles';
import { PopoverFilters } from './PopoverFilters';

interface DenmListFiltersProps {
  setFilteredStatus?: (value: DenmStatusFilter) => void;
  itemsPerPage: number;
  setChecked?: (value: string[]) => void;
}

export const DenmListFilters = ({
  setFilteredStatus,
  itemsPerPage,
  setChecked,
}: DenmListFiltersProps) => {
  const { l10n } = useLocalization();
  const dispatch = useAppDispatch();
  const classes = styles();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [searchFilter, setSearchFilter] = useState('');

  const openFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const searchById = () => {
    dispatch(denmsReducer.actions.denmRequest({ id: searchFilter }));
    dispatch(
      applicationReducer.actions.infoSelected({
        type: DialogType.DENM,
        id: searchFilter,
      }),
    );
  };

  const open = Boolean(anchorEl);
  const id = open ? 'denm-list-popover' : undefined;
  return (
    <>
      <Button
        variant="outlined"
        className={classes.filtersButton}
        endIcon={<FontAwesomeIcon icon={faFilter} size="xs" />}
        onClick={openFilters}
        size="small"
      >
        {l10n.getString('forms-event-filters-button')}
      </Button>
      <Box style={{ display: 'grid', gridTemplateColumns: '3fr 2fr', gap: 8 }} marginBottom={2}>
        <TextField
          className={classes.input}
          style={{ marginTop: 16 }}
          key={'search'}
          label={l10n.getString('forms-denm-search')}
          InputLabelProps={{ variant: 'standard' }}
          onChange={(ev) => setSearchFilter(ev.target.value)}
          fullWidth
          type="text"
          value={searchFilter}
          InputProps={{
            endAdornment: searchFilter && (
              <IconButton onClick={() => setSearchFilter('')} size="small">
                <CloseIcon />
              </IconButton>
            ),
          }}
        />
        <Button
          variant="outlined"
          className={classes.filtersButton}
          endIcon={<FontAwesomeIcon icon={faMagnifyingGlass} size="xs" />}
          onClick={searchById}
          size="small"
          disabled={!searchFilter}
        >
          {l10n.getString('forms-denm-search-by-id-button')}
        </Button>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div className={classes.filtersPopover}>
            <PopoverFilters
              setChecked={setChecked}
              setFilteredStatus={setFilteredStatus}
              itemsPerPage={itemsPerPage}
            />
          </div>
        </Popover>
      </Box>
    </>
  );
};
