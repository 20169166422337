import { useLocalization } from '@fluent/react';
import { Box, Card, IconButton, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { RootState, useAppDispatch } from '_store';
import applicationReducer from '_store/application/reducer';
import { DialogType } from '_store/application/types';
import pointFormReducer from '_store/forms/create-point';
import ivimFormReducer from '_store/forms/manage-ivim';
import manageRsuFormReducer from '_store/forms/manage-rsu';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ArrowBack } from '@material-ui/icons';
import denmFormReducer from '_store/forms/manage-denm';
import IvimCrudLayout from '../../../components/scenes/Ivim';
import IvimListLayout from '../../../components/scenes/Ivim/IvimList';
import { IvimTypeEnum } from '../../../components/scenes/Ivim/types';
import MessageDialog from '../../items/MessageDialog';
import CamListLayout from '../../scenes/Cam/CamList';
import CreateDenmLayout from '../../scenes/Denm/CreateDenm';
import DenmListLayout from '../../scenes/Denm/DenmList';
import { DenmTypeEnum } from '../../scenes/Denm/types';
import DeviceLayout from '../../scenes/Device';
import DeviceLayoutSingle from '../../scenes/Device/single';
import CamLayout from '../../scenes/Event/Cam';
import DenmLayout from '../../scenes/Event/Denm';
import IvimLayout from '../../scenes/Event/Ivim';
import HomeLayout from '../../scenes/Home';
import RsuCrudLayout from '../../scenes/Rsu';
import { CrudTypeEnum } from '../../scenes/Rsu/types';
import SettingsLayout from '../../scenes/Settings';
import { CardHeaderBox } from './CardHeaderBox';
import styles from './styles';
import { canInfoViewBeClosed } from './utils';

enum EntryTypeType {
  NEW_POINTS = 'newPoints',
  DELETE_POINTS = 'deletePoints',
  EXTEND_POINTS = 'extendPoints',
}

const InfoView: FC = () => {
  const isClosable = canInfoViewBeClosed();
  const { l10n } = useLocalization();
  const classes = styles();
  const dispatch = useAppDispatch();

  const dialog = useSelector((state: RootState) => state.application.activeDialog);

  const [entryType, setEntryType] = useState<{
    type: EntryTypeType | CrudTypeEnum | DenmTypeEnum | IvimTypeEnum;
    id: string;
  }>({
    type: null,
    id: null,
  });

  const [showMessage, setShowMessage] = useState({
    active: false,
    typeAction: '',
  });

  useEffect(() => {
    if (
      (entryType.type !== null &&
        dialog.type !== DialogType.DEVICE &&
        entryType.type === CrudTypeEnum.NEW_RSU) ||
      (dialog.type !== DialogType.DENM_LIST &&
        dialog.type !== DialogType.UPDATE_DENM &&
        (entryType.type === DenmTypeEnum.NEW_DENM ||
          entryType.type === DenmTypeEnum.UPDATE_DENM ||
          entryType.type === DenmTypeEnum.CLONE_DENM)) ||
      (dialog.type !== DialogType.IVIM_LIST &&
        dialog.type !== DialogType.UPDATE_IVIM &&
        (entryType.type === IvimTypeEnum.NEW_IVIM ||
          entryType.type === IvimTypeEnum.UPDATE_IVIM ||
          entryType.type === IvimTypeEnum.CLONE_IVIM))
    ) {
      setShowMessage({ active: true, typeAction: 'sideButton' });
    }
  }, [dialog]);

  const closeInfoView = () => {
    setShowMessage({ active: true, typeAction: 'closeButton' });
  };

  const onGoingBack = () => {
    setShowMessage({ active: true, typeAction: 'backButton' });
  };

  const closeDialog = () => {
    setEntryType({ type: null, id: null });
  };

  const goToDenmList = () => {
    dispatch(applicationReducer.actions.infoSelected({ type: DialogType.DENM_LIST, id: '1' }));
  };

  const onMessageLeaveConfirmed = (type: string) => {
    if (type === 'confirm') {
      if (['backButton', 'sideButton'].includes(showMessage.typeAction)) {
        setEntryType({ type: null, id: null });
        if (
          [
            EntryTypeType.NEW_POINTS,
            EntryTypeType.DELETE_POINTS,
            EntryTypeType.EXTEND_POINTS,
            CrudTypeEnum.NEW_RSU,
            CrudTypeEnum.UPDATE_RSU,
            DenmTypeEnum.UPDATE_DENM,
            DenmTypeEnum.CLONE_DENM,
            IvimTypeEnum.UPDATE_IVIM,
            IvimTypeEnum.CLONE_IVIM,
          ].includes(entryType.type as CrudTypeEnum | EntryTypeType | DenmTypeEnum | IvimTypeEnum)
        ) {
          dispatch(applicationReducer.actions.manageSelectionAborted());
          dispatch(applicationReducer.actions.pointSelectionAborted());
          dispatch(applicationReducer.actions.deletePointsAborted());
        }
      } else if (entryType.type !== EntryTypeType.DELETE_POINTS) {
        dispatch(applicationReducer.actions.dialogClosed());
      } else {
        dispatch(applicationReducer.actions.dialogClosed());
        dispatch(applicationReducer.actions.deletePointsAborted());
        dispatch(applicationReducer.actions.pointSelectionAborted());
      }
    } else if (entryType.type === CrudTypeEnum.NEW_RSU) {
      dispatch(
        applicationReducer.actions.infoSelected({
          type: DialogType.DEVICE,
          id: '3',
        }),
      );
    } else if (
      entryType.type === DenmTypeEnum.NEW_DENM ||
      entryType.type === DenmTypeEnum.UPDATE_DENM ||
      entryType.type === DenmTypeEnum.CLONE_DENM
    ) {
      dispatch(
        applicationReducer.actions.infoSelected({
          type: DialogType.DENM_LIST,
          id: '1',
        }),
      );
    } else if (
      entryType.type === IvimTypeEnum.NEW_IVIM ||
      entryType.type === IvimTypeEnum.UPDATE_IVIM ||
      entryType.type === IvimTypeEnum.CLONE_IVIM
    ) {
      dispatch(
        applicationReducer.actions.infoSelected({
          type: DialogType.IVIM_LIST,
          id: '2',
        }),
      );
    }

    setShowMessage((sm) => ({ ...sm, active: false }));
  };

  const closePage = () => {
    dispatch(applicationReducer.actions.dialogClosed());
    dispatch(denmFormReducer.actions.resetForm());
    dispatch(ivimFormReducer.actions.resetForm());
    dispatch(pointFormReducer.actions.resetForm());
    dispatch(manageRsuFormReducer.actions.resetForm());
    dispatch(applicationReducer.actions.pointSelectionAborted());
    dispatch(applicationReducer.actions.manageSelectionAborted());
  };

  const onCloseInfoView = () => {
    setShowMessage({ active: true, typeAction: dialog.type });
  };

  const messageInfoView = (type: string) => {
    if (type === 'confirm') {
      closePage();
    }

    setShowMessage((sm) => ({ ...sm, active: false }));
  };

  if (entryType.type === null) {
    const createNewRsu = () => {
      setEntryType({ ...entryType, type: CrudTypeEnum.NEW_RSU });
    };

    const updateRsu = (idExpanded) => {
      setEntryType({ type: CrudTypeEnum.UPDATE_RSU, id: idExpanded });
    };

    const updateDenm = (idExpanded) => {
      setEntryType({ type: DenmTypeEnum.UPDATE_DENM, id: idExpanded });
      dispatch(
        applicationReducer.actions.infoSelected({
          type: DialogType.DENM_LIST,
          id: idExpanded,
        }),
      );
    };

    const cloneDenm = (idExpanded) => {
      setEntryType({ type: DenmTypeEnum.CLONE_DENM, id: idExpanded });
      dispatch(
        applicationReducer.actions.infoSelected({
          type: DialogType.DENM_LIST,
          id: idExpanded,
        }),
      );
    };

    const createNewDenm = () => {
      setEntryType({ ...entryType, type: DenmTypeEnum.NEW_DENM });
    };

    const updateIvim = (idExpanded) => {
      setEntryType({ type: IvimTypeEnum.UPDATE_IVIM, id: idExpanded });
      dispatch(
        applicationReducer.actions.infoSelected({
          type: DialogType.IVIM_LIST,
          id: idExpanded,
        }),
      );
    };

    const cloneIvim = (idExpanded) => {
      setEntryType({ type: IvimTypeEnum.CLONE_IVIM, id: idExpanded });
      dispatch(
        applicationReducer.actions.infoSelected({
          type: DialogType.IVIM_LIST,
          id: idExpanded,
        }),
      );
    };

    const createNewIvim = () => {
      setEntryType({ ...entryType, type: IvimTypeEnum.NEW_IVIM });
    };

    let LayoutType = null;
    let CardHeaderTitle = null;

    const dialogActions = {
      [DialogType.DENM_LIST]: {
        action: createNewDenm,
        text: l10n.getString('forms-denm-add-new'),
      },
      [DialogType.DENM_PATH]: {
        action: createNewDenm,
        text: l10n.getString('forms-denm-add-new'),
      },
      [DialogType.IVIM_LIST]: {
        action: createNewIvim,
        text: l10n.getString('forms-ivim-add-new'),
      },
      [DialogType.IVIM_PATH]: {
        action: createNewIvim,
        text: l10n.getString('forms-ivim-add-new'),
      },
      [DialogType.DEVICE]: {
        action: createNewRsu,
        text: l10n.getString('forms-rsu-add-new'),
      },
      [DialogType.DEVICE_PATH]: {
        action: createNewRsu,
        text: l10n.getString('forms-rsu-add-new'),
      },
    };

    switch (dialog.type) {
      case DialogType.DENM:
        LayoutType = (
          <DenmLayout
            id={dialog.id}
            onUpdateDenm={(idExpanded) => updateDenm(idExpanded)}
            onCloneDenm={(idExpanded) => cloneDenm(idExpanded)}
          />
        );
        break;
      case DialogType.DENM_LIST:
      case DialogType.DENM_PATH:
        CardHeaderTitle = l10n.getString('forms-denm-list');
        LayoutType = (
          <DenmListLayout
            onUpdateDenm={(idExpanded) => updateDenm(idExpanded)}
            onCloneDenm={(idExpanded) => cloneDenm(idExpanded)}
          />
        );
        break;
      case DialogType.IVIM_LIST:
      case DialogType.IVIM_PATH:
        CardHeaderTitle = l10n.getString('forms-ivim-list');
        LayoutType = (
          <IvimListLayout
            onUpdateIvim={(idExpanded) => updateIvim(idExpanded)}
            onCloneIvim={(idExpanded) => cloneIvim(idExpanded)}
          />
        );
        break;

      case DialogType.IVIM:
        LayoutType = (
          <IvimLayout
            id={dialog.id}
            onUpdateIvim={(idExpanded) => updateIvim(idExpanded)}
            onCloneIvim={(idExpanded) => cloneIvim(idExpanded)}
          />
        );
        break;
      case DialogType.DEVICE:
      case DialogType.DEVICE_PATH:
        CardHeaderTitle = l10n.getString('forms-rsu-list');

        LayoutType = <DeviceLayout onUpdateRsu={(idExpanded) => updateRsu(idExpanded)} />;
        break;
      case DialogType.SINGLE_DEVICE:
        LayoutType = (
          <DeviceLayoutSingle id={dialog.id} onUpdateRsu={(idExpanded) => updateRsu(idExpanded)} />
        );
        break;
      case DialogType.SETTINGS:
        CardHeaderTitle = l10n.getString('forms-settings');
        LayoutType = <SettingsLayout />;
        break;
      case DialogType.CAM:
        LayoutType = <CamLayout id={dialog.id} />;
        break;
      case DialogType.CAM_LIST:
        CardHeaderTitle = l10n.getString('forms-cam-list');
        LayoutType = <CamListLayout />;
        break;
      default:
        LayoutType = <HomeLayout />;
    }

    const { action, text } = dialogActions[dialog.type] || {};

    return (
      <Card className={classes.cardStyle}>
        <Box className={classes.icons}>
          {dialog.type === DialogType.DENM && (
            <Tooltip title={l10n.getString('forms-denm-go-to-the-list')} placement="right">
              <IconButton className={classes.backIcon} onClick={goToDenmList}>
                <ArrowBack />
              </IconButton>
            </Tooltip>
          )}
          <IconButton className={classes.close} onClick={isClosable ? onCloseInfoView : closePage}>
            <CloseIcon />
          </IconButton>
        </Box>

        {showMessage.active ? (
          <MessageDialog statusMessage={showMessage} onCloseDialog={messageInfoView} />
        ) : null}

        <CardHeaderBox
          cardHeaderTitle={CardHeaderTitle}
          onFabClick={action}
          onFabClickText={text}
        />

        {LayoutType}
      </Card>
    );
  }
  return (
    <>
      {[CrudTypeEnum.NEW_RSU, CrudTypeEnum.UPDATE_RSU].includes(entryType.type as CrudTypeEnum) && (
        <Card className={classes.cardStyle}>
          <RsuCrudLayout
            id={entryType.id}
            type={entryType.type as CrudTypeEnum}
            onClose={closeInfoView}
            onGoingBack={onGoingBack}
          />
        </Card>
      )}

      {[DenmTypeEnum.NEW_DENM, DenmTypeEnum.UPDATE_DENM, DenmTypeEnum.CLONE_DENM].includes(
        entryType.type as DenmTypeEnum,
      ) && (
        <Card className={classes.cardStyle}>
          <CreateDenmLayout
            id={entryType.id}
            type={entryType.type as DenmTypeEnum}
            onClose={closeInfoView}
            onGoingBack={onGoingBack}
            closeDialog={closeDialog}
          />
        </Card>
      )}

      {[IvimTypeEnum.NEW_IVIM, IvimTypeEnum.UPDATE_IVIM, IvimTypeEnum.CLONE_IVIM].includes(
        entryType.type as IvimTypeEnum,
      ) && (
        <Card className={classes.cardStyle}>
          <IvimCrudLayout
            id={entryType.id}
            type={entryType.type as IvimTypeEnum}
            onClose={closeInfoView}
            onGoingBack={onGoingBack}
            closeDialog={closeDialog}
          />
        </Card>
      )}

      {showMessage.active && (
        <MessageDialog statusMessage={showMessage} onCloseDialog={onMessageLeaveConfirmed} />
      )}
    </>
  );
};

export default React.memo(InfoView);
