import fetchAPI from '_lib/api/fetchWrapper';
import apiRoute from '_lib/api/paths';
import {
  Coordinate,
  CreateIVIMPayload,
  DisableIVIMPayload,
  GeoJsonPoint,
  GetIVIMListPaylaod,
  Method,
  UpdateIVIMPayload,
} from '_lib/api/types';
import { PointSelectionType, ToastTitle } from '_store/application/types';
import { selectedRoadSegmentIdsSelector } from '_store/roads/selectors';
import { PayloadAction } from '@reduxjs/toolkit';
import { differenceInMilliseconds } from 'date-fns';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { delay, put, select } from 'typed-redux-saga';

import appReducer from '../application/reducer';
import ivimsReducer from './ivimsReducers';
import { ivimsSelectorForSelectedRoadSegment } from './selectors';
import { getIsoDate } from '_store/utils';
import { IvimStatusFilter } from './ivimsTypes';

const getIvimDate = (date: string | null) => {
  if (date) {
    return getIsoDate(date);
  }
  const lastDay = new Date(Date.now() - 24 * 60 * 60 * 1000);
  return lastDay.toISOString();
};

interface IvimListPayloadProps {
  date: string;
  status: IvimStatusFilter | '';
  roadSegmentIds?: string[];
  createdFrom?: Date;
  createdTo?: Date;
  serviceCategoryCode?: string;
  serviceSubCategoryCode?: string;
  page?: number;
  limit?: number;
}

const getIvimRequestPayload = ({
  date,
  status,
  roadSegmentIds,
  createdFrom,
  createdTo,
  serviceCategoryCode,
  serviceSubCategoryCode,
  page,
  limit,
}: IvimListPayloadProps) => {
  const dateString = getIvimDate(date);
  const roadSegments =
    roadSegmentIds && roadSegmentIds.length > 0
      ? roadSegmentIds.map((id) => `roadSegmentId=${id}`).join('&')
      : '';

  const createdFromParams = createdFrom ? createdFrom.toISOString() : dateString;
  const createdToParams = createdTo ? `createdBefore=${createdTo.toISOString()}` : '';

  const statusToParams = {
    [IvimStatusFilter.IN_PROGRESS]: {
      active: true,
      disabled: false,
      scheduled: false,
    },
    [IvimStatusFilter.DISABLED]: { disabled: true, active: true },
    [IvimStatusFilter.CLOSED]: { active: false },
    [IvimStatusFilter.SCHEDULED]: { scheduled: true },
  };

  let statusParams = '';
  if (status !== '') {
    const params = statusToParams[status];
    statusParams = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');
  }

  const serviceCategoryCodeParams = serviceCategoryCode
    ? `serviceCategoryCode=${serviceCategoryCode}`
    : '';
  const serviceSubCategoryCodeParams = serviceSubCategoryCode
    ? `serviceSubCategoryCode=${serviceSubCategoryCode}`
    : '';

  return {
    status: statusParams,
    roadSegments: roadSegments,
    createdFrom: createdFromParams,
    createdTo: createdToParams,
    serviceCategoryCode: serviceCategoryCodeParams,
    serviceSubCategoryCode: serviceSubCategoryCodeParams,
    page,
    limit,
  };
};

export function* getIVIMEvents(action: PayloadAction<GetIVIMListPaylaod>) {
  const {
    roadSegmentIds,
    date,
    createdFrom,
    createdTo,
    status,
    serviceCategoryCode,
    serviceSubCategoryCode,
    currentPage,
    itemsPerPage,
  } = action.payload;

  try {
    const payload = getIvimRequestPayload({
      date,
      status,
      roadSegmentIds,
      createdFrom,
      createdTo,
      serviceCategoryCode,
      serviceSubCategoryCode,
      page: currentPage,
      limit: itemsPerPage,
    });
    let resultsResponse = [];
    let totalResponse = 0;

    const response = yield fetchAPI(apiRoute.getIVIMs(payload));
    if (response) {
      totalResponse = response.total;
      resultsResponse = response.results;
    }
    yield* put(
      ivimsReducer.actions.ivimsResponse({ results: resultsResponse, total: totalResponse }),
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error getIVIMEvents >>>>>>>', e);
  }
}

export function* statusTimer(action: PayloadAction<any>) {
  const pendingTime = 30000;
  const date = new Date();
  const utcDate = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );

  const timeDelay = differenceInMilliseconds(new Date(action.payload.expiresAt), new Date(utcDate));
  yield delay(timeDelay);

  const currentIVIMs = yield* select(
    ivimsSelectorForSelectedRoadSegment(selectedRoadSegmentIdsSelector as unknown as string[]),
  );

  if (currentIVIMs.find((d) => d.id === action.payload.id)) {
    yield put(
      ivimsReducer.actions.ivimUpsertOne({ ...action.payload, visibilityStatus: 'pending' }),
    );
    yield delay(pendingTime);
  }

  yield put(ivimsReducer.actions.ivimRemove(action.payload.id));
}

export function* createIVIM(action: PayloadAction<CreateIVIMPayload>) {
  yield put(appReducer.actions.dialogRequestStarted());
  // FIXME
  // const roads = yield* select(roadsSelector);
  let toastContent: ToastTitle;

  const params = action.payload;
  try {
    const result = yield fetchAPI(apiRoute.createIVIM, {
      method: Method.POST,
      params: { ...params },
    });
    toastContent = {
      id: 'forms-ivim-creation-success',
    };
    yield put(appReducer.actions.dialogRequestFinished(result));
  } catch (e) {
    toastContent = {
      id: 'forms-ivim-creation-fail',
      args: { error: e?.packet?.message ?? 'Unknown error' },
    };
  } finally {
    yield put(appReducer.actions.removeMapElements(PointSelectionType.IVIM_REFERENCE_POINT));
    yield put(appReducer.actions.removeMapElements(PointSelectionType.IVIM_DET_ZONE));
    yield put(appReducer.actions.removeMapElements(PointSelectionType.IVIM_REL_ZONE));
    yield put(appReducer.actions.toastAdded(toastContent));
  }
}

export function* updateIVIM(action: PayloadAction<UpdateIVIMPayload>) {
  yield put(appReducer.actions.dialogRequestStarted());
  // FIXME
  // const roads = yield* select(roadsSelector);
  let toastContent: ToastTitle;

  try {
    const { id: ivimId, ivimPayload } = action.payload;
    const result = yield fetchAPI(apiRoute.updateIVIM(ivimId), {
      method: Method.PATCH,
      params: { ...ivimPayload },
    });
    toastContent = {
      id: 'forms-ivim-update-success',
    };

    yield put(appReducer.actions.dialogRequestFinished(result));
  } catch (e) {
    toastContent = {
      id: 'forms-denm-ivim-fail',
      args: { error: e?.packet?.message ?? 'Unknown error' },
    };
  } finally {
    yield put(appReducer.actions.removeMapElements(PointSelectionType.DENM_REFERENCE_POINT));
    yield put(appReducer.actions.removeMapElements(PointSelectionType.DENM_HISTORY));
    yield put(appReducer.actions.removeMapElements(PointSelectionType.DENM_TRACES));
    yield put(appReducer.actions.toastAdded(toastContent));
  }
}

export function* deleteIVIM(action: PayloadAction<string>) {
  yield* put(appReducer.actions.dialogRequestStarted());

  try {
    const id = action.payload;
    yield fetchAPI(apiRoute.deleteIVIM(id), { method: Method.DELETE });
    yield* put(appReducer.actions.ivimDeleted({ result: { ok: true }, id }));
    yield* put(appReducer.actions.dialogRequestFinished({ ok: true }));
    yield put(ivimsReducer.actions.ivimRemove(id));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error deleteivim >>>>>>>', e);
  }
}

export function* deleteIVIMs(action: PayloadAction<string[]>) {
  yield* put(appReducer.actions.dialogRequestStarted());

  try {
    const ids = action.payload;
    yield fetchAPI(apiRoute.deleteIVIMs, { method: Method.POST, params: { eventIds: ids } });
    yield* put(appReducer.actions.ivimDeleted({ result: { ok: true }, id: ids }));
    yield put(ivimsReducer.actions.ivimRemoveMany(ids));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error deleteIVIM >>>>>>>', e);
  }
}

export function* toggleDisableIVIM(action: PayloadAction<DisableIVIMPayload>) {
  yield* put(appReducer.actions.dialogRequestStarted());

  try {
    const { ids, disable } = action.payload;
    yield fetchAPI(apiRoute.disableIVIM(String(disable)), {
      method: Method.POST,
      params: { eventIds: ids },
    });
    yield* put(appReducer.actions.ivimsDisabled({ result: { ok: true }, ids }));
    yield put(ivimsReducer.actions.ivimRemoveMany(ids));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error disableIVIM >>>>>>>', e);
  }
}

export const ivimsSagas = createSliceSaga({
  name: 'ivims',
  caseSagas: {
    ivimsRequest: getIVIMEvents,
    createIVIM,
    updateIVIM,
    deleteIVIM,
    statusTimer,
    deleteIVIMs,
    disableIVIM: toggleDisableIVIM,
  },
  sagaType: SagaType.Watch,
});
