import { useLocalization } from '@fluent/react';
import { Coordinate, GeoJson, GeoJsonPoint } from '_lib/api';

export interface IvimZoneCoordinates {
  type: string;
  bbox?: number[];
  coordinates: Coordinate[];
}

export interface Ivim {
  id: string;
  sequenceNumber: number;
  iviType: number;
  serviceCategoryCode: string;
  serviceSubCategoryCode: string;
  pictogramNature: number;
  pictogramSerialNumber: number;
  language: string;
  text: string[];
  countryCode: string;
  providerIdentifier: string;
  direction: number;
  broadcastingFrequency: number;
  detZoneCoordinates: IvimZoneCoordinates;
  relZoneCoordinates: IvimZoneCoordinates;
  referencePoint: GeoJsonPoint;
  roadSegmentId: string;
  starts: string;
  ends: string;
  expiresAt: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  disabled: boolean;
}

export enum IvimStatusFilter {
  IN_PROGRESS = 'in_progress',
  CLOSED = 'closed',
  DISABLED = 'disabled',
  SCHEDULED = 'scheduled',
}

export enum IvimDateFilterType {
  RELATIVE = 'relative',
  ABSOLUTE = 'absolute',
}

export interface IvimReducerState {
  subscriptionId?: string;
  loading: boolean;
  statusFilter: IvimStatusFilter | '';
  dateFilter: string;
  createdFromFilter: Date;
  createdToFilter: Date;
  dateFilterType: IvimDateFilterType;
  roadSegmentId: string;
  total: number;
  paginationCurrentPage?: number;
  paginationItemsPerPage?: number;
  serviceCategoryCodeFilter?: string;
  serviceSubCategoryCodeFilter?: string;
}

export const ivimType = () => {
  const { l10n } = useLocalization();
  return [
    { value: 0, label: l10n.getString('forms-ivim-type-danger') },
    { value: 1, label: l10n.getString('forms-ivim-type-regulatory') },
    { value: 2, label: l10n.getString('forms-ivim-type-traffic') },
    { value: 3, label: l10n.getString('forms-ivim-type-pollution') },
    { value: 4, label: l10n.getString('forms-ivim-type-not-traffic') },
  ];
};
