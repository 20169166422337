import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginBottom: 10,
  },
  cardContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '16px 8px',
  },
  cardOuterContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    padding: 0,
  },
  expand: {
    display: 'flex',
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },

  detailStyle: {
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: 0,
  },
  diagnostic: {
    display: 'flex',
    alignItems: 'center',
  },
  updateStyle: {
    color: '#005abb',
    textDecorationLine: 'underline',
    textTransform: 'none',
    padding: 0,
    fontWeight: 'normal',
    marginRight: 5,
  },
  deleteStyle: {
    color: '#cd3a3a',
    textDecorationLine: 'underline',
    textTransform: 'none',
    padding: 0,
    fontWeight: 'normal',
    border: 'none',
  },
  textStyle: {
    whiteSpace: 'pre-line',
  },
  gridVertical: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardHeader: {
    justifyContent: 'space-between',
    display: 'flex',
    fontSize: '0.9em',
    fontWeight: 'bold',
  },
  cardHeaderIcon: {
    width: 'auto',
    height: '2rem',
    marginRight: '0.5rem',
  },
  cardHeaderDescription: { fontWeight: 'normal', fontSize: '0.9em' },
  cardHeaderDate: {
    fontWeight: 'normal',
    fontSize: '0.8em',
    color: '#444e53',
  },
  cardHeaderStatus: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    margin: 0,
  },
  viewDetailButton: {
    textTransform: 'none',
    justifyContent: 'flex-start',
    padding: 0,
    fontWeight: 'normal',
  },
  checkbox: {
    marginTop: 15,
  },
  iconButton: {
    cursor: 'pointer',
    padding: 5,
    borderRadius: 50,
    color: 'white',
    marginRight: 5,
  },
  referenceDenmBox: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    marginBottom: 15,
    border: '1px solid #e0e0e0',
    borderStyle: 'dashed',
    borderRadius: 2,
    padding: '15px 10px',
    gap: 8,
  },
}));

export default styles;
