import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  expand: {
    display: 'flex',
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  viewDetailButton: {
    textTransform: 'none',
    justifyContent: 'flex-start',
    padding: 0,
    fontWeight: 'normal',
  },
}));

export default styles;
