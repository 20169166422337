import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  createStore,
  Dispatch,
} from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { fork } from 'redux-saga/effects';

import apiReducer from './api/reducer';
import { apiSagas } from './api/sagas';
import applicationReducer from './application/reducer';
import demnsReducers from './denm/denmsReducers';
import { denmsSagas } from './denm/sagas';
import devicesReducers from './devices/devicesReducers';
import { devicesSagas } from './devices/sagas';
import formReducer from './forms';
import ivimsReducers from './ivim/ivimsReducers';
import { ivimsSagas } from './ivim/sagas';
import roadsReducers from './roads/roadsReducers';
import { routesSagas } from './roads/sagas';
import camsReducer from './vehicles/vehiclesReducers';
import settingsReducer from './settings/settingsReducers';
import { settingsSaga } from './settings/sagas';

const sagaMiddleware = createSagaMiddleware();

function* rootSaga() {
  yield fork(apiSagas.saga);
  yield fork(routesSagas.saga);
  yield fork(devicesSagas.saga);
  yield fork(denmsSagas.saga);
  yield fork(ivimsSagas.saga);
  yield fork(settingsSaga.saga);
}

const reducers = combineReducers({
  application: applicationReducer.reducer,
  api: apiReducer.reducer,
  forms: formReducer,
  denms: demnsReducers.reducer,
  ivims: ivimsReducers.reducer,
  roads: roadsReducers.reducer,
  devices: devicesReducers.reducer,
  vehicles: camsReducer.reducer,
  settings: settingsReducer.reducer,
});

const store = createStore(reducers, composeWithDevTools(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = (): Dispatch<AnyAction> => useDispatch<AppDispatch>();
export type RootState = ReturnType<typeof reducers>;

export default store;
