import React from 'react';

interface DotComponentProps {
  status: boolean;
  isDisabled?: boolean;
  scheduled?: boolean;
}

export const DotComponent = ({
  status,
  isDisabled = false,
  scheduled = false,
}: DotComponentProps) => {
  let backgroundColor = '#82919b';
  if (isDisabled) {
    backgroundColor = '#ffa500';
  } else if (scheduled) {
    backgroundColor = '#b900ff';
  } else if (status) {
    backgroundColor = '#29c706';
  }

  return (
    <div
      style={{
        height: 10,
        width: 10,
        backgroundColor,
        marginRight: 5,
        borderRadius: 5,
      }}
    />
  );
};
