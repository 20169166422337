import { CardContent } from '@material-ui/core';
import React, { FC } from 'react';

import styles from './styles';

const HomeLayout: FC = () => {
  const classes = styles();
  return <CardContent className={classes.cardContent}></CardContent>;
};

export default React.memo(HomeLayout);
