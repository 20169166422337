import { WebStorageStateStore } from 'oidc-client-ts';
import { AuthProviderProps } from 'react-oidc-context';

export const keycloak: AuthProviderProps = {
  authority: `${process.env.KEYCLOAK_URL}/realms/${process.env.KEYCLOAK_REALM}`,
  client_id: process.env.KEYCLOAK_CLIENT_ID || '',
  redirect_uri: process.env.KEYCLOAK_REDIRECT_URI || '',
  silent_redirect_uri: process.env.KEYCLOAK_REDIRECT_URI || '',
  onSigninCallback: () => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
  automaticSilentRenew: true,
  revokeTokensOnSignout: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};
