import { Card, CardContent, Typography } from '@material-ui/core';
import { RootState, useAppDispatch } from '_store';
import applicationReducer from '_store/application/reducer';
import React, { FC } from 'react';

import { useLocalization } from '@fluent/react';
import { DialogType } from '_store/application/types';
import { Ivim } from '_store/ivim/ivimsTypes';
import { ivimStatuses } from '_store/ivim/utils';
import { useSelector } from 'react-redux';
import { Accordion } from '../../../../../components/items/Accordion';
import { CheckboxItem } from '../../../../../components/items/CheckboxItem';
import { DotComponent } from '../../../../../components/scenes/Event/DotComponent';
import { EventStatus } from '../../../../../components/scenes/Event/EventStatus';
import { toLatLng } from '../../../../routes/MapView/utils';
import CardDetails from './Details';
import styles from './styles';

export interface CardListItemProps {
  text: string;
  ivim: Ivim;
  asCard: boolean;
  onUpdate?: (idExpanded: string) => void;
  onDelete?: (id: string) => void;
  onDisable?: (id: string) => void;
  onEnable?: (id: string) => void;
  onCheckedChange?: (id: string) => void;
  checked?: boolean;
  showCheckbox?: boolean;
  onClone?: (id: string) => void;
}

const CardListItem: FC<CardListItemProps> = ({
  text,
  ivim,
  onUpdate,
  onDelete,
  asCard,
  onDisable,
  onEnable,
  onCheckedChange,
  checked,
  showCheckbox = false,
  onClone,
}) => {
  const { l10n } = useLocalization();
  const classes = styles();
  const dispatch = useAppDispatch();
  const mapRef = useSelector((state: RootState) => state.application.mapRef);

  const onAccordionExpanded = () => {
    const markerPosition = toLatLng(ivim?.referencePoint?.coordinates);
    mapRef.flyTo(markerPosition, 15, { animate: true, duration: 1 });

    setTimeout(() => {
      dispatch(
        applicationReducer.actions.infoSelected({
          type: DialogType.IVIM_PATH,
          id: ivim.id,
        }),
      );
    }, 1000);
  };

  const onAccordionClose = () => {
    dispatch(
      applicationReducer.actions.infoSelected({
        type: DialogType.IVIM_LIST,
        id: '2',
      }),
    );
  };

  const { isIvimActive, disabled, scheduled } = ivimStatuses(ivim);
  const ivimStartsDate = new Date(ivim.starts);

  const { statusClass, statusText } = EventStatus({
    active: isIvimActive,
    date: ivimStartsDate,
    disabled,
    scheduled,
  });

  const details = (
    <CardDetails
      ivim={ivim}
      onUpdate={() => (onUpdate ? onUpdate(ivim.id) : null)}
      onDelete={() => (onDelete ? onDelete(ivim.id) : null)}
      onClone={() => (onClone ? onClone(ivim.id) : null)}
      showButtons={true}
      isActive={isIvimActive}
      onDisable={() => (onDisable ? onDisable(ivim.id) : null)}
      onEnable={() => (onEnable ? onEnable(ivim.id) : null)}
      isDisabled={disabled}
    />
  );

  const body = (
    <div className={classes.cardOuterContent}>
      <div className={classes.checkbox}>
        {showCheckbox && (
          <CheckboxItem checked={checked} itemId={ivim.id} handleCheckedChange={onCheckedChange} />
        )}
      </div>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.cardHeader}>
          {/* <img src={IvimIcon} className={classes.cardHeaderIcon} /> */}
        </Typography>
        <Typography className={classes.cardHeaderDescription}>{text}</Typography>
        <div className={classes.cardHeaderStatus}>
          {DotComponent({
            status: isIvimActive,
            isDisabled: disabled,
            scheduled,
          })}

          <Typography variant="caption" className={statusClass}>
            {statusText}
          </Typography>
        </div>
        {asCard ? (
          <div style={{ marginTop: '20px' }}>
            <Accordion
              title={l10n.getString('view-details')}
              onExpanded={onAccordionExpanded}
              onClose={onAccordionClose}
            >
              {details}
            </Accordion>
          </div>
        ) : (
          details
        )}
      </CardContent>
    </div>
  );

  if (asCard) {
    return <Card className={classes.root}>{body}</Card>;
  }
  return body;
};

export default React.memo(CardListItem);
