import React from 'react';

import { CardContent, Grid, Typography } from '@material-ui/core';
import { Denm, DenmEtsiMetaData } from '_store/denm/denmsTypes';
import styles from './styles';

export interface EtsiMetaDataProps {
  etsiData: DenmEtsiMetaData;
}

export const EtsiMetaData = ({ etsiData }: EtsiMetaDataProps) => {
  const classes = styles();

  const convertSpeedValue = (speedValue: number) => {
    return `${speedValue * 0.01} m/s (${speedValue * 0.01 * 3.6} km/h)`;
  };

  return (
    <CardContent className={classes.detailStyle}>
      <Grid container spacing={1} style={{ marginTop: 10, marginLeft: 5 }}>
        <Grid container spacing={1}>
          <Typography component={'span'} variant={'body2'} paragraph className={classes.textStyle}>
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              Sequence number: <strong>{etsiData.actionID?.sequenceNumber}</strong> {'\n'}
            </Typography>
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              Originating station ID: <strong>{etsiData.actionID?.originatingStationID}</strong>{' '}
              {'\n'}
            </Typography>
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              Speed value: <strong>{convertSpeedValue(etsiData.speedValue)} </strong> {'\n'}
            </Typography>
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              Station type: <strong>{etsiData.stationType}</strong>
              {'\n'}
            </Typography>
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              Relevance distance: <strong>{etsiData.relevanceDistance}</strong>
              {'\n'}
            </Typography>
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              Information quality: <strong>{etsiData.informationQuality}</strong>
              {'\n'}
            </Typography>
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              Relevance traffic direction: <strong>{etsiData.relevanceTrafficDirection}</strong>
              {'\n'}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  );
};
