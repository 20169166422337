import { defaultIcon, useCause } from '_store/denm/utils';
import { useLocalization } from '@fluent/react';
import React, { FC } from 'react';
import { Tooltip } from 'react-leaflet';

import denmIcons from '../../../icons/denm';
import { iconForCause } from '../../../icons/utils/denm';
import styles from '../styles';
// import { defaultOffset } from '.';
import { HoveringDENM, HoverTooltipProps } from './types';

const DenmInfoTooltip: FC<HoverTooltipProps> = ({ hoveringElement }) => {
  const { l10n } = useLocalization();
  const classes = styles();

  const { denm /* , offset */ } = hoveringElement as HoveringDENM;

  const [cause, subCause] = useCause(denm);

  return (
    <Tooltip direction="top" sticky /* offset={offset || defaultOffset} */>
      <div className={classes.tooltipHeader}>
        {cause && (
          <>
            <img
              src={denmIcons[iconForCause(cause, subCause) ?? defaultIcon]?.png}
              style={{
                width: 'auto',
                height: '2rem',
                marginRight: '0.5rem',
              }}
            />
            <div>
              <div>{cause.description}</div>
              <div className={classes.tooltipSubHeader}>{subCause.description}</div>
            </div>
          </>
        )}
      </div>
      <table>
        <tbody>
          <tr>
            <th className={classes.tooltipTableHeader}>
              {l10n.getString('forms-info-box-id-info')}
            </th>
            <td>{denm.id}</td>
          </tr>
          <tr>
            <th className={classes.tooltipTableHeader}>{l10n.getString('forms-info-box-node')}</th>
            <td>
              [{denm.referencePoint.coordinates[1]}, {denm.referencePoint.coordinates[0]}]
            </td>
          </tr>
        </tbody>
      </table>
    </Tooltip>
  );
};

export default DenmInfoTooltip;
