import { UDPDistributionKind } from '_lib/api';
import { RootState } from '_store';
import { RsuStatusCode } from '_store/devices/devicesTypes';
import { useLocalization } from '@fluent/react';
import React, { FC } from 'react';
import { Tooltip } from 'react-leaflet';
import { useSelector } from 'react-redux';

import { RsuStatus } from '../../../icons';
import styles from '../styles';
// import { defaultOffset } from '.';
import { HoveringRSU, HoverTooltipProps } from './types';

const RsuInfoTooltip: FC<HoverTooltipProps> = ({ hoveringElement }) => {
  const classes = styles();
  const { l10n } = useLocalization();
  const { device /* , offset */ } = hoveringElement as HoveringRSU;
  const { name, distribution } = device;

  const diagnostic = useSelector((state: RootState) => state.api.diagnostic[device.id]);
  let iconStatus: RsuStatusCode;
  if (diagnostic) {
    const hdd = diagnostic.statusDetails?.diskStatus;
    const udp = diagnostic.service;
    if (hdd) {
      if (udp) {
        iconStatus = RsuStatusCode.UP;
      } else {
        iconStatus = RsuStatusCode.ERROR;
      }
    } else {
      iconStatus = RsuStatusCode.WARNING;
    }
  }

  return (
    <Tooltip direction="top" sticky /* offset={offset || defaultOffset} */>
      <div className={classes.inlineHeader}>
        <div style={{ marginRight: '0.7rem' }}>
          <RsuStatus status={iconStatus} />
        </div>
        <div style={{ marginBottom: '0.3rem' }} className={classes.tooltipHeader}>
          {l10n.getString('forms-info-box-rsu-info', { name })}
        </div>
      </div>
      <table>
        <tbody>
          <tr>
            <th className={classes.tooltipTableHeader}>
              {l10n.getString('forms-info-box-ip-info')}
            </th>
            <td>{(distribution as UDPDistributionKind).host}</td>
          </tr>
          <tr>
            <th className={classes.tooltipTableHeader}>
              {l10n.getString('forms-info-box-port-info')}
            </th>
            <td>{(distribution as UDPDistributionKind).port}</td>
          </tr>
        </tbody>
      </table>
    </Tooltip>
  );
};

export default RsuInfoTooltip;
