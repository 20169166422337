import { useLocalization } from '@fluent/react';
import { Box, Button, Typography } from '@material-ui/core';
import { DenmColors } from '../../../../../../app/components/routes/MapView/utils';
import React from 'react';

interface TracesListProps {
  selectedTraceIndex: number;
  highlightTrace: (index: number) => void;
  deleteTrace: (index: number) => void;
  index: number;
}

export const TracesList = ({
  selectedTraceIndex,
  highlightTrace,
  deleteTrace,
  index,
}: TracesListProps) => {
  const { l10n } = useLocalization();

  const borderLeft = selectedTraceIndex === index ? `4px solid ${DenmColors.TRACE_SELECTED}` : '';
  const paddingLeft = selectedTraceIndex === index ? '10px' : '0px';

  return (
    <Box display={'flex'} key={index} justifyContent={'space-between'} marginTop={'20px'}>
      <Typography
        style={{ alignSelf: 'center', cursor: 'pointer', borderLeft, paddingLeft }}
        variant="caption"
        onClick={() => highlightTrace(index)}
      >{`${l10n.getString('forms-denm-single-trace-zone')} ${index + 2}`}</Typography>
      <Button
        variant="outlined"
        onClick={() => {
          deleteTrace(index);
        }}
        color="primary"
        style={{ borderRadius: 20, textTransform: 'none', fontSize: '11px' }}
        size="small"
      >
        {l10n.getString('forms-denm-points-delete')}
      </Button>
    </Box>
  );
};
