import { useAppDispatch } from '_store';
import applicationReducer from '_store/application/reducer';
import { ToastData } from '_store/application/types';
import { useLocalization } from '@fluent/react';
import { IconButton, Snackbar, SnackbarContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { FC } from 'react';

const CustomToast: FC<ToastData> = ({ id, title }) => {
  const dispatch = useAppDispatch();
  const { l10n } = useLocalization();

  const close = () => {
    dispatch(applicationReducer.actions.toastRemoved(id));
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      close();
    }
  };
  const onCloseButtonClick = () => {
    close();
  };

  return (
    <Snackbar
      style={{ width: '100%', height: '10%', marginBottom: 0 }}
      open={true}
      autoHideDuration={8000}
      onClose={handleClose}
    >
      <SnackbarContent
        style={{ width: '90%', height: '100%' }}
        message={l10n.getString(title.id, title.args)}
        action={
          <IconButton aria-label="close" color="inherit" onClick={onCloseButtonClick}>
            <CloseIcon />
          </IconButton>
        }
      />
    </Snackbar>
  );
};

export default React.memo(CustomToast);
