import { Method, NewRSU } from '_lib/api';
import fetchAPI from '_lib/api/fetchWrapper';
import apiRoute from '_lib/api/paths';
import { ToastTitle } from '_store/application/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { put } from 'typed-redux-saga';

import applicationReducer from '../application/reducer';
import manageRsuFormReducer from '../forms/manage-rsu';
import devicesReducer from './devicesReducers';

export function* getAllDevices(action: PayloadAction<string[]>) {
  const roadSegmentIds = action.payload;
  const roadSegments = roadSegmentIds.map((id) => `roadSegmentId=${id}`).join('&');
  try {
    const response = yield fetchAPI(apiRoute.devices(roadSegments));
    yield* put(devicesReducer.actions.devicesResponse(response.results));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('ERROR getAllDevices >>>>>>> ', e);
  }
}

export function* getRSU(action: PayloadAction<string>) {
  const id = action.payload;
  try {
    const response = yield fetchAPI(apiRoute.device(id));
    yield* put(devicesReducer.actions.deviceResponse(response));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('ERROR getRSU >>>>>>> ', e);
  }
}

export function* createRSU(action: PayloadAction<NewRSU>) {
  let toastContent: ToastTitle;
  const params = action.payload;
  try {
    const result = yield fetchAPI(apiRoute.createRSU, { params, method: Method.POST });
    if (result.id) {
      toastContent = {
        id: 'forms-rsu-creation-success',
      };
      yield put(devicesReducer.actions.devicesAddOne(result));
      yield put(applicationReducer.actions.dialogRequestFinished(result));
      yield* put(manageRsuFormReducer.actions.resetForm());
      yield* put(applicationReducer.actions.dialogClosed());
    } else {
      toastContent = {
        id: 'forms-rsu-creation-fail',
        args: { error: result.message ?? 'Unknown error' },
      };
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('ERROR createRSU >>>>>>> ', e);
    toastContent = {
      id: 'forms-rsu-creation-fail',
      args: { error: e?.packet?.message ?? 'Unknown error' },
    };
  } finally {
    yield put(applicationReducer.actions.toastAdded(toastContent));
  }
}

export function* updateRSU(action: PayloadAction<{ updatedRSU: NewRSU; id: string }>) {
  let toastContent: ToastTitle;

  try {
    const { id } = action.payload;
    const { updatedRSU } = action.payload;

    const response = yield fetchAPI(apiRoute.updateRSU(id), {
      method: Method.PATCH,
      params: { ...updatedRSU },
    });

    yield put(devicesReducer.actions.devicesUpsertOne(response));

    toastContent = { id: 'forms-rsu-update-success' };
    yield* put(applicationReducer.actions.dialogRequestFinished(response));
    yield* put(manageRsuFormReducer.actions.resetForm());
    yield* put(applicationReducer.actions.dialogClosed());
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error updateRSU >>>>>>>', e);
    toastContent = {
      id: 'forms-rsu-update-fail',
      args: {
        error: typeof e.packet.message === 'string' ? e.packet.message : e.packet.message[0],
      },
    };
  } finally {
    yield* put(applicationReducer.actions.toastAdded(toastContent));
  }
}

export function* deleteRSU(action: PayloadAction<string>) {
  yield* put(applicationReducer.actions.dialogRequestStarted());

  try {
    const id = action.payload;
    yield fetchAPI(apiRoute.deleteRSU(id), { method: Method.DELETE });

    yield* put(applicationReducer.actions.rsuDeleted({ result: { ok: true }, id }));
    yield* put(applicationReducer.actions.dialogRequestFinished({ ok: true }));
    yield put(devicesReducer.actions.devicesRemove(id));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error deleteRSU >>>>>>>', e);
  }
}

export const devicesSagas = createSliceSaga({
  name: 'devices',
  caseSagas: {
    devicesRequest: getAllDevices,
    deviceRequest: getRSU,
    createRSU,
    updateRSU,
    deleteRSU,
  },
  sagaType: SagaType.Watch,
});
