import { makeStyles } from '@material-ui/core';

const styles = makeStyles((_theme) => ({
  downloadButton: {
    textTransform: 'none',
    color: '#005abb',
    fontSize: 13,
    backgroundColor: '#ffffff',
    border: '1px solid',
    borderRadius: 4,
    padding: '12px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    marginBottom: 10,
    cursor: 'pointer',
    width: '100%',
  },
}));

export default styles;
