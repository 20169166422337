import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styles from './styles';
import { DialogTitle } from './components/DialogTitle';
import { useLocalization } from '@fluent/react';
import SelectItem from '../SelectItem';
import * as ivimEvents from '../../../assets/ivimEvents.json';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { PictogramsTable } from './components/Table';
import { IVIMPictrogram } from '_store/api/types';

interface PictogramsModalInterface {
  isOpen: boolean;
  onClose: () => void;
  busy: boolean;
  formStateServiceCategoryCode: string;
  formStateServiceSubCategoryCode: string;
  onChangeServiceCategoryCode: (event: React.ChangeEvent<{ name?: string; value: any }>) => void;
  onChangeServiceSubCategoryCode: (event: React.ChangeEvent<{ name?: string; value: any }>) => void;
  pictogramSelected?: string;
  onChangePictogram: (pictogram: IVIMPictrogram) => void;
}

export const PictogramsModal = ({
  isOpen,
  onClose,
  busy,
  formStateServiceCategoryCode,
  formStateServiceSubCategoryCode,
  onChangeServiceCategoryCode,
  onChangeServiceSubCategoryCode,
  pictogramSelected,
  onChangePictogram,
}: PictogramsModalInterface) => {
  const classes = styles();
  const { l10n } = useLocalization();

  const [open, setOpen] = useState(isOpen);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const serviceCategoryCode = [...new Set(ivimEvents.map((i) => i.serviceCategoryCode))];

  const serviceSubCategoryCode =
    formStateServiceCategoryCode === null
      ? []
      : [
          ...new Set(
            ivimEvents
              .filter((ev) => ev.serviceCategoryCode === formStateServiceCategoryCode)
              .map((ev) => ev.serviceSubCategoryCode),
          ),
        ];

  const pictogramNames = ivimEvents
    .filter(
      (ev) =>
        ev.serviceCategoryCode === formStateServiceCategoryCode &&
        ev.serviceSubCategoryCode === formStateServiceSubCategoryCode,
    )
    .map((ev, i) => ({
      value: ev,
      label: ev.description,
    }));

  const handleClose = () => {
    onClose();
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className={classes.dialog}
        maxWidth="lg"
      >
        <DialogTitle onClose={handleClose}>
          {l10n.getString('forms-ivim-pictogram-modal-title')}
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContent}>
          <div className={classes.filterRow}>
            <SelectItem
              text={l10n.getString('forms-ivim-ser-cat-cod')}
              options={serviceCategoryCode.map((n) => ({
                value: n,
                label: l10n.getString(`forms-ivim-ser-cat-cod-enum-${n}`, {
                  defaultValue: n,
                }),
              }))}
              disabled={busy}
              value={formStateServiceCategoryCode ?? ''}
              onChange={(ev) => onChangeServiceCategoryCode(ev)}
              tooltip={l10n.getString('forms-ivim-sv-cat-code-tp')}
              style={{ width: '150px' }}
              labelStyle={{ width: '150px' }}
            />

            <SelectItem
              text={l10n.getString('forms-ivim-ser-sub-cat-cod')}
              options={serviceSubCategoryCode.map((n) => ({
                value: n,
                label: l10n.getString(`forms-ivim-ser-cat-sub-cod-enum-${n}`, {
                  defaultValue: n,
                }),
              }))}
              disabled={formStateServiceCategoryCode === null || busy}
              value={formStateServiceSubCategoryCode ?? ''}
              onChange={(ev) => onChangeServiceSubCategoryCode(ev)}
              tooltip={l10n.getString('forms-ivim-sv-sub-cat-code-tp')}
              style={{ width: '150px' }}
              labelStyle={{ width: '150px' }}
            />
            <TextField
              style={{ marginTop: 16 }}
              key={'search'}
              label={l10n.getString('forms-ivim-pictogram-search')}
              InputLabelProps={{ variant: 'standard' }}
              onChange={(ev) => setFilter(ev.target.value)}
              fullWidth
              type="text"
              value={filter}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton edge="start" size="small">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),

                endAdornment: filter && (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setFilter('')}
                    size="small"
                  >
                    <CloseIcon />
                  </IconButton>
                ),
              }}
            />
          </div>
          <div>
            <PictogramsTable
              rows={
                pictogramNames
                  .filter((p) => p.label.toLowerCase().includes(filter.toLowerCase()))
                  .map((p) => p.value) as any
              }
              rowSelected={pictogramSelected}
              selectPictogram={(row) => onChangePictogram(row)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            {l10n.getString('close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
