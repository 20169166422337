import { makeStyles } from '@material-ui/core';

const styles = makeStyles((_theme) => ({
  cardContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginTop: 0,
    paddingBottom: 0,
    backgroundColor: '#fff',
  },
}));

export default styles;
