/* eslint-disable simple-import-sort/imports */
import { Cam } from '_store/vehicles/vehiclesTypes';
import { Map } from 'leaflet';
import React, { FC, ReactElement } from 'react';

import CamMarker from './markers/CamMarker';

export interface VehiclesProps {
  vehicles: Cam[];
  mapRef: Map;
}

const Vehicles: FC<VehiclesProps> = ({ vehicles, mapRef }): ReactElement => {
  return (
    <>
      {vehicles &&
        vehicles.map((cam) => (
          <CamMarker key={`vehicle-${cam.stationId}`} cam={cam} mapRef={mapRef} />
        ))}
    </>
  );
};
export default React.memo(Vehicles);
