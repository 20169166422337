import { withStyles } from '@material-ui/core';

const styles = withStyles({
  tag: {
    backgroundColor: '#005abb',
    borderRadius: 2,
    height: 24,
    position: 'relative',
    zIndex: 0,
    '& .MuiChip-label': {
      color: '#fff',
    },
    '& .MuiChip-deleteIcon': {
      color: '#005abb',
    },
    '&:after': {
      content: '""',
      right: 10,
      top: 6,
      height: 12,
      width: 12,
      position: 'absolute',
      backgroundColor: 'white',
      zIndex: -1,
    },
  },
});

export default styles;
