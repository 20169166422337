import { Box, Card, CardContent, Tooltip } from '@material-ui/core';
import { RSU } from '_lib/api/types';
import { RootState, useAppDispatch } from '_store';
import { RsuStatusCode } from '_store/devices/devicesTypes';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { useLocalization } from '@fluent/react';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { blue, red } from '@material-ui/core/colors';
import { Accordion } from '../../../../components/items/Accordion';
import { RsuStatus } from '../../../icons';
import { toLatLng } from '../../../routes/MapView/utils';
import CardDetails from './Details';
import styles from './styles';
import applicationReducer from '_store/application/reducer';
import { DialogType } from '_store/application/types';

export interface CardListItemProps {
  text: string;
  device: RSU;
  asCard: boolean;
  onUpdate?: (idExpanded: string) => void;
  onDelete?: (id: string) => void;
}

const CardListItem: FC<CardListItemProps> = ({ text, device, onUpdate, onDelete, asCard }) => {
  const { l10n } = useLocalization();
  const classes = styles();
  const dispatch = useAppDispatch();
  const diagnostic = useSelector((state: RootState) => state.api.diagnostic[device.id]);
  const mapRef = useSelector((state: RootState) => state.application.mapRef);

  const onAccordionExpanded = () => {
    const markerPosition = toLatLng(device?.location?.coordinates);
    mapRef.flyTo(markerPosition, 15, { animate: true, duration: 1 });

    setTimeout(() => {
      dispatch(
        applicationReducer.actions.infoSelected({
          type: DialogType.DEVICE_PATH,
          id: device.id,
        }),
      );
    }, 1000);
  };

  const onAccordionClose = () => {
    dispatch(
      applicationReducer.actions.infoSelected({
        type: DialogType.DEVICE,
        id: '4',
      }),
    );
  };

  let iconStatus: RsuStatusCode;
  if (diagnostic) {
    const hdd = diagnostic.statusDetails?.diskStatus;
    const udp = diagnostic.service;
    if (hdd) {
      if (udp) {
        iconStatus = RsuStatusCode.UP;
      } else {
        iconStatus = RsuStatusCode.ERROR;
      }
    } else {
      iconStatus = RsuStatusCode.WARNING;
    }
  }

  const statusExists = Boolean(diagnostic && diagnostic.statusDetails);

  const details = (
    <CardDetails
      device={device}
      diagnostic={{
        connection: statusExists,
        service: statusExists,
        statusDetails: diagnostic?.statusDetails,
      }}
    />
  );

  const body = (
    <CardContent className={classes.cardContent}>
      <Box className={classes.headerBox}>
        <div className={classes.headerIconAndTitle}>
          <RsuStatus status={iconStatus} />
          <span>{text}</span>
        </div>
        <div>
          <Tooltip title={l10n.getString('forms-event-update-btn')}>
            <FontAwesomeIcon
              icon={faPen}
              style={{
                backgroundColor: blue[400],
              }}
              className={classes.iconButton}
              size="xs"
              onClick={() => onUpdate(device.id)}
            />
          </Tooltip>
          <Tooltip title={l10n.getString('forms-event-delete-btn')}>
            <FontAwesomeIcon
              icon={faTrash}
              style={{
                backgroundColor: red[400],
              }}
              className={classes.iconButton}
              size="xs"
              onClick={() => onDelete(device.id)}
            />
          </Tooltip>
        </div>
      </Box>

      {asCard ? (
        <>
          <Accordion
            title={l10n.getString('view-details')}
            onExpanded={onAccordionExpanded}
            onClose={onAccordionClose}
          >
            {details}
          </Accordion>
        </>
      ) : (
        details
      )}
    </CardContent>
  );

  if (asCard) {
    return <Card className={classes.root}>{body}</Card>;
  }
  return body;
};

export default React.memo(CardListItem);
