export enum CrudTypeEnum {
  NEW_RSU = 'newRsu',
  UPDATE_RSU = 'updateRsu',
  DELETE_RSU = 'deleteRsu',
}

export type CrudType = {
  type: CrudTypeEnum;
  id: string;
  onClose: () => void;
  onGoingBack: () => void;
};

export type RsuManageType = {
  type: CrudTypeEnum;
  id: string;
};
