export enum DatePeriodEnum {
  lastHour = 'lastHour',
  lastDay = 'lastDay',
  lastWeek = 'lastWeek',
  lastMonth = 'lastMonth',
  last3Months = 'last3Months',
  last6Months = 'last6Months',
  lastYear = 'lastYear',
}

export const getIsoDate = (period: string) => {
  const date = new Date();
  switch (period) {
    case DatePeriodEnum.lastHour:
      date.setHours(date.getHours() - 1);
      break;
    case DatePeriodEnum.lastDay:
      date.setDate(date.getDate() - 1);
      break;
    case DatePeriodEnum.lastWeek:
      date.setDate(date.getDate() - 7);
      break;
    case DatePeriodEnum.lastMonth:
      date.setMonth(date.getMonth() - 1);
      break;
    case DatePeriodEnum.last3Months:
      date.setMonth(date.getMonth() - 3);
      break;
    case DatePeriodEnum.last6Months:
      date.setMonth(date.getMonth() - 6);
      break;
    case DatePeriodEnum.lastYear:
      date.setFullYear(date.getFullYear() - 1);
      break;
    default:
      break;
  }
  return date.toISOString();
};

export const formatDateForCSV = (date: string) => {
  return new Date(date)
    .toLocaleString('it-IT', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })
    .replace(',', ' -');
};

export const stringToBoolean = (value: string | null) => value === 'true';

export const hasDefinedProperty = (obj: any): boolean => {
  return Object.values(obj).some((value) => value !== undefined);
};

export const hasDefinedPropertyDeep = (obj: any): boolean => {
  for (let key in obj) {
    if (obj[key] !== undefined && obj[key] !== null) {
      if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        return hasDefinedPropertyDeep(obj[key]);
      } else {
        return true;
      }
    }
  }
  return false;
};
