import { zoomSelector } from '_store/application/selectors';
import { MapElement, MapElementType, PolylineMapElement } from '_store/application/types';
import React, { FC } from 'react';
import { Circle, Polyline, Tooltip } from 'react-leaflet';
import { useSelector } from 'react-redux';

import { metersPerPixel, toLatLng } from '../utils';

const elementMap = {
  [MapElementType.CIRCLE]: ({ center, radius, options }) => (
    <Circle className="map-element-circle" center={center} radius={radius} {...options} />
  ),
  [MapElementType.CIRCLE_GENERATED]: ({ center, radius, options }) => (
    <Circle className="map-element-circle" center={center} radius={radius} {...options} />
  ),
  [MapElementType.POLYLINE]: ({ options, path, distance }) => (
    <Polyline className="map-element-polyline" positions={path} {...options}>
      {distance && (
        <Tooltip direction="center" offset={[0, 0]} opacity={1} permanent>
          <span>{distance.toFixed(3)} Km</span>
        </Tooltip>
      )}
    </Polyline>
  ),
};

const Elements: FC<{
  elements?: MapElement[];
  style?: any;
  index?: number;
}> = ({ elements, style, index = '_' }) => {
  const zoom = useSelector(zoomSelector);
  return (
    <span>
      {elements &&
        elements
          .map((point) => {
            const element = {
              ...style,
              ...(style ? { loc: point } : point),
              radius: (style || point).radius * metersPerPixel(point.loc[1], zoom),
            };
            return element as MapElement;
          })
          .filter((element) => element.type)
          .map((element, key) => {
            const Component = elementMap[element.type];

            return (
              <Component
                key={`${element.type}_${index}_${key}`}
                center={toLatLng(element.loc)}
                radius={element.radius}
                options={element.options}
                path={(element as PolylineMapElement)?.points?.map((p) =>
                  toLatLng([p[0] + element.loc[0], p[1] + element.loc[1]]),
                )}
                distance={(element as PolylineMapElement)?.distance}
              />
            );
          })}
    </span>
  );
};

export default Elements;
