import { Coordinate, MapNode } from '_lib/api';
import { createSlice,PayloadAction } from '@reduxjs/toolkit';

export interface CreatePOINTForm {
  pointForMap?: string;
  direction?: '1' | '-1';
  link?: string;
  distance: string;
  referencePoint: Coordinate[];
  distPointsToMeter: Coordinate[];
  nodesToDelete?: MapNode[];
}

const initialState: CreatePOINTForm = {
  pointForMap: null,
  direction: null,
  link: null,
  distance: null,
  referencePoint: null,
  distPointsToMeter: [],
  nodesToDelete: [],
};

const pointFormReducer = createSlice({
  name: 'point',
  initialState,
  reducers: {
    referencePointChanged(state, action: PayloadAction<Coordinate[]>) {
      state.referencePoint = action.payload;
    },
    pointChanged(state, action: PayloadAction<string>) {
      state.pointForMap = action.payload;
    },
    linkChanged(state, action: PayloadAction<string>) {
      state.link = action.payload;
    },
    distanceChanged(state, action: PayloadAction<string>) {
      state.distance = action.payload;
    },
    directionChanged(state, action: PayloadAction<string>) {
      state.direction = action.payload === '1' ? '1' : '-1';
    },
    setPointsDistToMeter(state, action: PayloadAction<Coordinate[]>) {
      state.distPointsToMeter = action.payload;
    },
    pointToDelete(state, action: PayloadAction<MapNode>) {
      state.nodesToDelete.push(action.payload);
    },
    resetForm() {
      return initialState;
    },
  },
});

export default pointFormReducer;
