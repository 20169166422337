import { makeStyles } from '@material-ui/core';

const styles = makeStyles((_theme) => ({
  cardContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#fff',
  },
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    paddingLeft: 10,
    paddingTop: 10,
  },
  containerBox: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
    border: '1px solid #e0e0e0',
    borderStyle: 'dashed',
    borderRadius: 2,
    padding: '15px 10px',
    gap: 8,
  },
  switch: {
    fontSize: 14,
  },
  heading: {
    fontSize: 14,
    fontWeight: 600,
  },
  iconButton: {
    cursor: 'pointer',
    padding: 5,
    borderRadius: 50,
    color: 'white',
    marginRight: 5,
    alignSelf: 'center',
  },
  addNewButton: {
    textTransform: 'none',
    fontSize: 13,
    border: '1px solid',
    color: '#005abb',
    height: 30,
  },
}));

export default styles;
