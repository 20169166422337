export const events = {
  // Old icons
  '0': { stationtype: 0, icon: 'unknown.png', text: 'unknown' },
  '1': { stationtype: 1, icon: 'pedestrian.png', text: 'pedestrian' },
  '2': { stationtype: 2, icon: 'cyclist.png', text: 'cyclist' },
  '3': { stationtype: 3, icon: 'moped.png', text: 'moped' },
  '4': { stationtype: 4, icon: 'motorcycles.png', text: 'motorcycles' },
  // '5': { stationtype: 5, icon: 'passengerCar.png', text: 'passengerCar' },
  '6': { stationtype: 6, icon: 'bus.png', text: 'bus' },
  '7': { stationtype: 7, icon: 'lightTruck.png', text: 'lightTruck' },
  // '8': { stationtype: 8, icon: 'heavyTruck.png', text: 'heavyTruck' },
  '9': { stationtype: 9, icon: 'trailer.png', text: 'trailer' },
  '10': { stationtype: 10, icon: 'specialVehicles.png', text: 'specialVehicles' },
  '11': { stationtype: 11, icon: 'tram.png', text: 'tram' },
  '15': { stationtype: 15, icon: 'roadSideUnit.png', text: 'roadSideUnit' },

  // New icons for demo
  '5': { stationtype: 5, icon: 'icons8-car.png', text: 'passengerCar' },
  '8': { stationtype: 8, icon: 'icons8-adr.png', text: 'heavyTruck' },
};

export default { events };
