import { useAppDispatch } from '_store';
import { devicesSagas } from '_store/devices/sagas';
import { devicesSelector } from '_store/devices/selectors';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import MessageDialog from '../../items/MessageDialog';
import CardListItem from './Card';

export interface DeviceLayoutSingleProps {
  id: string;
  onUpdateRsu?: (idExpanded: string) => void;
}

// detail card of selected RSU
const DeviceLayoutSingle: FC<DeviceLayoutSingleProps> = ({ id, onUpdateRsu }) => {
  const devices = useSelector(devicesSelector);
  const device = devices.find((d) => d.id === id);
  const dispatch = useAppDispatch();

  const [showMessage, setShowMessage] = useState({
    active: false,
    typeAction: 'delete',
    idToDelete: null,
    typeEvent: 'rsu',
  });

  if (!device) {
    return <>Could not find RSU</>;
  }

  const onMessageLeaveConfirmed = (type: string) => {
    if (type === 'confirm') {
      dispatch(devicesSagas.actions.deleteRSU(showMessage.idToDelete));
    }

    setShowMessage((sm) => ({
      ...sm,
      active: false,
      idToDelete: null,
    }));
  };

  const onDeleteRsu = (idToDelete) => {
    setShowMessage((sm) => ({
      ...sm,
      active: true,
      idToDelete,
    }));
  };

  return (
    <>
      {showMessage.active === true ? (
        <MessageDialog statusMessage={showMessage} onCloseDialog={onMessageLeaveConfirmed} />
      ) : null}
      <CardListItem
        device={device}
        asCard={false}
        text={device.name}
        onUpdate={() => onUpdateRsu(device.id)}
        onDelete={() => onDeleteRsu(device.id)}
      />
    </>
  );
};

export default React.memo(DeviceLayoutSingle);
