import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '..';
import { roadsAdapter } from './roadsReducers';

const roadStateDomain = (state: RootState) => state.roads;

export const loadingSelector = createSelector(roadStateDomain, (road) => road.loading);

export const roadsSelector = createSelector(roadStateDomain, (road) =>
  roadsAdapter.getSelectors().selectAll(road),
);

export const selectedRoadSegmentIdsSelector = createSelector(
  roadStateDomain,
  (state) => state.selectedRoadSegmentIds,
);
