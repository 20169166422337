import React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import styles from '../styles';

interface DialogTitleProps {
  children: React.ReactNode;
  onClose: () => void;
}

export const DialogTitle = ({ children, onClose }: DialogTitleProps) => {
  const classes = styles();
  return (
    <MuiDialogTitle disableTypography className={classes.dialogTitle}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};
