import { useLocalization } from '@fluent/react';
import { CardContent, IconButton, InputAdornment, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { RSU } from '_lib/api';
import { useAppDispatch } from '_store';
import { devicesSagas } from '_store/devices/sagas';
import { devicesSelector } from '_store/devices/selectors';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import MessageDialog from '../../items/MessageDialog';
import CardListItem from './Card';
import styles from './styles';

export interface DeviceLayoutProps {
  onUpdateRsu: (idExpanded: string) => void;
}

// expanded details inside RSU list items
const DeviceLayout: FC<DeviceLayoutProps> = (crudProps) => {
  const { l10n } = useLocalization();
  const classes = styles();
  const dispatch = useAppDispatch();

  const devices = useSelector(devicesSelector);

  const [filter, setFilter] = useState('');
  const [showMessage, setShowMessage] = useState({
    active: false,
    typeAction: 'delete',
    idToDelete: null,
    typeEvent: 'rsu',
  });

  const filterRSU = (val: RSU) => {
    const searchVal = filter.toLowerCase();
    const itemVal = val.name.toString().toLowerCase();
    return itemVal.indexOf(searchVal) > -1;
  };

  const onMessageLeaveConfirmed = (type: string) => {
    if (type === 'confirm') {
      dispatch(devicesSagas.actions.deleteRSU(showMessage.idToDelete));
    }

    setShowMessage((sm) => ({
      ...sm,
      active: false,
      idToDelete: null,
    }));
  };

  const onDeleteRsu = (id: string) => {
    setShowMessage((sm) => ({
      ...sm,
      active: true,
      idToDelete: id,
    }));
  };

  return (
    <CardContent className={classes.cardContent} style={{ overflow: 'hidden' }}>
      {showMessage.active === true ? (
        <MessageDialog statusMessage={showMessage} onCloseDialog={onMessageLeaveConfirmed} />
      ) : null}

      <TextField
        className={classes.input}
        key={'search'}
        label={l10n.getString('forms-rsu-search')}
        InputLabelProps={{ variant: 'standard' }}
        onChange={(ev) => setFilter(ev.target.value)}
        fullWidth
        type="text"
        value={filter}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton edge="start">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),

          endAdornment: filter && (
            <IconButton aria-label="toggle password visibility" onClick={() => setFilter('')}>
              <CloseIcon />
            </IconButton>
          ),
        }}
      />
      <div style={{ overflow: 'auto' }}>
        {devices &&
          devices
            .filter(filterRSU)
            .map((item) => (
              <CardListItem
                asCard={true}
                key={item.id}
                device={item}
                text={item.name}
                onUpdate={() => crudProps.onUpdateRsu(item.id)}
                onDelete={() => onDeleteRsu(item.id)}
              />
            ))}
      </div>
    </CardContent>
  );
};

export default React.memo(DeviceLayout);
