import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { Road, RoadsState } from './roadsTypes';

const initialState: RoadsState = {
  loading: false,
};

export const roadsAdapter = createEntityAdapter({
  selectId: (el: Road) => el.id,
});

const roadsReducer = createSlice({
  name: 'roads',
  initialState: roadsAdapter.getInitialState(initialState),
  reducers: {
    roadsRequest: (state) => {
      state.loading = true;
    },
    roadsResponse: (state, action) => {
      const roads = action.payload;
      roadsAdapter.setAll(state, roads);
      if (roads && roads.length > 0) {
        state.selectedRoadSegmentIds = action.payload.map((road) => road.id);
      }
      state.loading = false;
    },
    roadsAddOne: roadsAdapter.addOne,
    roadsRemove: roadsAdapter.removeOne,
    roadsAddMany: roadsAdapter.addMany,
    roadsRemoveAll: roadsAdapter.removeAll,
    selectRoad: (state, action) => {
      state.selectedRoadSegmentIds = action.payload;
    },
  },
});

export default roadsReducer;
