import { useState, useEffect } from 'react';

interface LaneStates {
  overtaking: boolean;
  fast: boolean;
  slow: boolean;
  emergency: boolean;
}

interface FormState {
  optional?: {
    alacarte?: {
      roadWorks?: {
        closedLanes?: number;
      };
    };
  };
}

const useLaneStates = (formState: FormState, isUpdate: boolean) => {
  const [laneStates, setLaneStates] = useState<LaneStates>({
    overtaking: false,
    fast: false,
    slow: false,
    emergency: false,
  });

  useEffect(() => {
    const { closedLanes } = formState.optional?.alacarte?.roadWorks || {};
    if (closedLanes !== undefined && isUpdate) {
      const bitmask = parseInt(closedLanes.toString(), 2);

      const newState: LaneStates = {
        overtaking: !!(bitmask & 0b1000),
        fast: !!(bitmask & 0b0100),
        slow: !!(bitmask & 0b0010),
        emergency: !!(bitmask & 0b0001),
      };

      setLaneStates(newState);
    }
  }, [formState.optional?.alacarte?.roadWorks?.closedLanes, isUpdate]);

  const updateLaneStates = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    const updatedStates = { ...laneStates, [name]: !laneStates[name as keyof LaneStates] };
    setLaneStates(updatedStates);
  };

  return {
    laneStates,
    updateLaneStates,
  };
};

export default useLaneStates;
