import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  formControl: {
    marginLeft: 0,
  },
  formControlLabel: {
    fontSize: '14px',
    marginLeft: 10,
  },
  checkbox: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  checkboxChecked: {
    color: '#005abb',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  iconButton: {
    cursor: 'pointer',
    padding: 10,
    borderRadius: 50,
    color: 'white',
  },
  referenceDenmBox: {
    display: 'grid',
    gridTemplateColumns: '11fr 1fr',
    marginBottom: 20,
    border: '1px solid #e0e0e0',
    borderStyle: 'dashed',
    borderRadius: 2,
    padding: '15px 10px',
    gap: 8,
  },
}));

export default styles;
