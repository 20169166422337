import ambientCondition_3_59 from './AmbientCondition-3-59.png';
import dangerWarning_1_11 from './DangerWarning-1-11.png';
import dangerWarning_1_32 from './DangerWarning-1-32.png';
import dangerWarning_1_33 from './DangerWarning-1-33.png';
import dangerWarning_1_34 from './DangerWarning-1-34.png';
import dangerWarning_1_35 from './DangerWarning-1-35.png';
import dangerWarning_1_36 from './DangerWarning-1-36.png';
import dangerWarning_1_49 from './DangerWarning-1-49.png';
import dangerWarning_1_61 from './DangerWarning-1-61.png';
import dangerWarning_1_62 from './DangerWarning-1-62.png';
import dangerWarning_2_11 from './DangerWarning-2-11.png';
import dangerWarning_2_12 from './DangerWarning-2-12.png';
import dangerWarning_2_24 from './DangerWarning-2-24.png';
import dangerWarning_2_25 from './DangerWarning-2-25.png';
import dangerWarning_2_36 from './DangerWarning-2-36.png';
import dangerWarning_2_37 from './DangerWarning-2-37.png';
import dangerWarning_2_38 from './DangerWarning-2-38.png';
import dangerWarning_2_39 from './DangerWarning-2-39.png';
import dangerWarning_2_44 from './DangerWarning-2-44.png';
import dangerWarning_2_46 from './DangerWarning-2-46.png';
import dangerWarning_2_47 from './DangerWarning-2-47.png';
import dangerWarning_2_53 from './DangerWarning-2-53.png';
import dangerWarning_2_54 from './DangerWarning-2-54.png';
import dangerWarning_2_55 from './DangerWarning-2-55.png';
import dangerWarning_2_56 from './DangerWarning-2-56.png';
import dangerWarning_2_58 from './DangerWarning-2-58.png';
import dangerWarning_2_68 from './DangerWarning-2-68.png';
import dangerWarning_2_69 from './DangerWarning-2-69.png';
import dangerWarning_2_71 from './DangerWarning-2-71.png';
import dangerWarning_2_96 from './DangerWarning-2-96.png';
import dangerWarning_2_97 from './DangerWarning-2-97.png';
import dangerWarning_3_19 from './DangerWarning-3-19.png';
import dangerWarning_3_32 from './DangerWarning-3-32.png';
import dangerWarning_3_46 from './DangerWarning-3-46.png';
import dangerWarning_3_47 from './DangerWarning-3-47.png';
import dangerWarning_3_48 from './DangerWarning-3-48.png';
import dangerWarning_3_61 from './DangerWarning-3-61.png';
import dangerWarning_3_62 from './DangerWarning-3-62.png';
import dangerWarning_3_63 from './DangerWarning-3-63.png';
import dangerWarning_3_64 from './DangerWarning-3-64.png';
import dangerWarning_3_65 from './DangerWarning-3-65.png';
import dangerWarning_3_66 from './DangerWarning-3-66.png';
import dangerWarning_3_67 from './DangerWarning-3-67.png';
import dangerWarning_3_68 from './DangerWarning-3-68.png';
import dangerWarning_3_78 from './DangerWarning-3-78.png';
import dangerWarning_3_79 from './DangerWarning-3-79.png';
import dangerWarning_3_81 from './DangerWarning-3-81.png';
import dangerWarning_3_82 from './DangerWarning-3-82.png';
import dangerWarning_3_95 from './DangerWarning-3-95.png';
import dangerWarning_3_96 from './DangerWarning-3-96.png';
import dangerWarning_4_11 from './DangerWarning-4-11.png';
import dangerWarning_4_12 from './DangerWarning-4-12.png';
import dangerWarning_4_13 from './DangerWarning-4-13.png';
import dangerWarning_4_14 from './DangerWarning-4-14.png';
import dangerWarning_9_99 from './DangerWarning-9-99.png';
import informative_1_68 from './Informative-1-68.png';
import informative_5_25 from './Informative-5-25.png';
import informative_5_38 from './Informative-5-38.png';
import informative_6_23 from './Informative-6-23.png';
import informative_8_11 from './Informative-8-11.png';
import informative_8_12 from './Informative-8-12.png';
import informative_8_51 from './Informative-8-51.png';
import publicFacilities_1_15 from './PublicFacilities-1-15.png';
import regulatory_1_11 from './Regulatory-1-11.png';
import regulatory_1_12 from './Regulatory-1-12.png';
import regulatory_1_13 from './Regulatory-1-13.png';
import regulatory_1_29 from './Regulatory-1-29.png';
import regulatory_1_78 from './Regulatory-1-78.png';
import regulatory_4_12 from './Regulatory-4-12.png';
import regulatory_4_15 from './Regulatory-4-15.png';
import regulatory_4_16 from './Regulatory-4-16.png';
import regulatory_4_17 from './Regulatory-4-17.png';
import regulatory_4_18 from './Regulatory-4-18.png';
import regulatory_4_19 from './Regulatory-4-19.png';
import regulatory_4_21 from './Regulatory-4-21.png';
import regulatory_4_22 from './Regulatory-4-22.png';
import regulatory_4_23 from './Regulatory-4-23.png';
import regulatory_4_24 from './Regulatory-4-24.png';
import regulatory_4_25 from './Regulatory-4-25.png';
import regulatory_4_26 from './Regulatory-4-26.png';
import regulatory_4_27 from './Regulatory-4-27.png';
import regulatory_4_33 from './Regulatory-4-33.png';
import regulatory_4_75 from './Regulatory-4-75.png';
import regulatory_4_76 from './Regulatory-4-76.png';
import regulatory_4_77 from './Regulatory-4-77.png';
import regulatory_4_99 from './Regulatory-4-99.png';
import regulatory_5_11 from './Regulatory-5-11.png';
import regulatory_5_12 from './Regulatory-5-12.png';
import regulatory_5_13 from './Regulatory-5-13.png';
import regulatory_5_14 from './Regulatory-5-14.png';
import regulatory_5_15 from './Regulatory-5-15.png';
import regulatory_5_27 from './Regulatory-5-27.png';
import regulatory_5_28 from './Regulatory-5-28.png';
import regulatory_5_29 from './Regulatory-5-29.png';
import regulatory_5_42 from './Regulatory-5-42.png';
import regulatory_5_44 from './Regulatory-5-44.png';
import regulatory_5_57 from './Regulatory-5-57.png';
import regulatory_5_58 from './Regulatory-5-58.png';
import regulatory_5_61 from './Regulatory-5-61.png';
import regulatory_5_77 from './Regulatory-5-77.png';
import regulatory_5_78 from './Regulatory-5-78.png';
import regulatory_7_11 from './Regulatory-7-11.png';
import regulatory_7_12 from './Regulatory-7-12.png';
import regulatory_7_13 from './Regulatory-7-13.png';
import regulatory_7_14 from './Regulatory-7-14.png';
import regulatory_7_15 from './Regulatory-7-15.png';
import regulatory_7_16 from './Regulatory-7-16.png';
import regulatory_7_17 from './Regulatory-7-17.png';
import regulatory_7_18 from './Regulatory-7-18.png';
import regulatory_7_19 from './Regulatory-7-19.png';
import regulatory_7_21 from './Regulatory-7-21.png';
import regulatory_7_23 from './Regulatory-7-23.png';
import regulatory_7_44 from './Regulatory-7-44.png';
import regulatory_7_45 from './Regulatory-7-45.png';
import regulatory_7_47 from './Regulatory-7-47.png';
import regulatory_7_48 from './Regulatory-7-48.png';
import regulatory_7_49 from './Regulatory-7-49.png';
import regulatory_7_53 from './Regulatory-7-53.png';
import regulatory_7_54 from './Regulatory-7-54.png';
import regulatory_7_73 from './Regulatory-7-73.png';
import regulatory_7_95 from './Regulatory-7-95.png';
import regulatory_7_96 from './Regulatory-7-96.png';
import roadCondition_1_11 from './RoadCondition-1-11.png';
import roadCondition_1_12 from './RoadCondition-1-12.png';

export const images = {
  ambientCondition_3_59,
  dangerWarning_1_11,
  dangerWarning_1_32,
  dangerWarning_1_33,
  dangerWarning_1_34,
  dangerWarning_1_35,
  dangerWarning_1_36,
  dangerWarning_1_49,
  dangerWarning_1_61,
  dangerWarning_1_62,
  dangerWarning_2_11,
  dangerWarning_2_12,
  dangerWarning_2_24,
  dangerWarning_2_25,
  dangerWarning_2_36,
  dangerWarning_2_37,
  dangerWarning_2_38,
  dangerWarning_2_39,
  dangerWarning_2_44,
  dangerWarning_2_46,
  dangerWarning_2_47,
  dangerWarning_2_53,
  dangerWarning_2_54,
  dangerWarning_2_55,
  dangerWarning_2_56,
  dangerWarning_2_58,
  dangerWarning_2_68,
  dangerWarning_2_69,
  dangerWarning_2_71,
  dangerWarning_2_96,
  dangerWarning_2_97,
  dangerWarning_3_19,
  dangerWarning_3_32,
  dangerWarning_3_46,
  dangerWarning_3_47,
  dangerWarning_3_48,
  dangerWarning_3_61,
  dangerWarning_3_62,
  dangerWarning_3_63,
  dangerWarning_3_64,
  dangerWarning_3_65,
  dangerWarning_3_66,
  dangerWarning_3_67,
  dangerWarning_3_68,
  dangerWarning_3_78,
  dangerWarning_3_79,
  dangerWarning_3_81,
  dangerWarning_3_82,
  dangerWarning_3_95,
  dangerWarning_3_96,
  dangerWarning_4_11,
  dangerWarning_4_12,
  dangerWarning_4_13,
  dangerWarning_4_14,
  dangerWarning_9_99,
  informative_1_68,
  informative_5_25,
  informative_5_38,
  informative_6_23,
  informative_8_11,
  informative_8_12,
  informative_8_51,
  publicFacilities_1_15,
  regulatory_1_11,
  regulatory_1_12,
  regulatory_1_13,
  regulatory_1_29,
  regulatory_1_78,
  regulatory_4_12,
  regulatory_4_15,
  regulatory_4_16,
  regulatory_4_17,
  regulatory_4_18,
  regulatory_4_19,
  regulatory_4_21,
  regulatory_4_22,
  regulatory_4_23,
  regulatory_4_24,
  regulatory_4_25,
  regulatory_4_26,
  regulatory_4_27,
  regulatory_4_33,
  regulatory_4_75,
  regulatory_4_76,
  regulatory_4_77,
  regulatory_4_99,
  regulatory_5_11,
  regulatory_5_12,
  regulatory_5_13,
  regulatory_5_14,
  regulatory_5_15,
  regulatory_5_27,
  regulatory_5_28,
  regulatory_5_29,
  regulatory_5_42,
  regulatory_5_44,
  regulatory_5_57,
  regulatory_5_58,
  regulatory_5_61,
  regulatory_5_77,
  regulatory_5_78,
  regulatory_7_11,
  regulatory_7_12,
  regulatory_7_13,
  regulatory_7_14,
  regulatory_7_15,
  regulatory_7_16,
  regulatory_7_17,
  regulatory_7_18,
  regulatory_7_19,
  regulatory_7_21,
  regulatory_7_23,
  regulatory_7_44,
  regulatory_7_45,
  regulatory_7_47,
  regulatory_7_48,
  regulatory_7_49,
  regulatory_7_53,
  regulatory_7_54,
  regulatory_7_73,
  regulatory_7_95,
  regulatory_7_96,
  roadCondition_1_11,
  roadCondition_1_12
};