import { useLocalization } from '@fluent/react';
import { CardContent, Grid, Typography } from '@material-ui/core';
import React, { FC } from 'react';

import styles from './styles';

interface RsuTargetProps {
  rsuList: string | number;
  broadcastingFrequency: string | number;
}

const RsuTargetInv: FC<RsuTargetProps> = ({ rsuList, broadcastingFrequency }) => {
  const classes = styles();
  const { l10n } = useLocalization();

  return (
    <CardContent className={classes.detailStyle}>
      <Grid container spacing={1} style={{ marginTop: 10 }}>
        <Grid item xs={12} className={classes.gridVertical}>
          <Typography component={'span'} variant={'body2'} paragraph className={classes.textStyle}>
            {l10n.getString('forms-ivim-rsu-target')}: {`\n`} {rsuList}
          </Typography>
          {broadcastingFrequency ? (
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              {l10n.getString('forms-ivim-broadcasting-frequency')}: {`\n`}
              {broadcastingFrequency}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default React.memo(RsuTargetInv);
