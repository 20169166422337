export enum IvimTypeEnum {
  NEW_IVIM = 'newIvim',
  UPDATE_IVIM = 'updateIvim',
  CLONE_IVIM = 'cloneIvim',
  DELETE_IVIM = 'deleteIvim',
  DISABLE_IVIM = 'disableIvim',
}

export type IvimType = {
  type: IvimTypeEnum;
  id: string;
  onClose: () => void;
  onGoingBack: () => void;
  closeDialog: () => void;
};
