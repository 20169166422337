import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  cardContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginTop: 0,
    paddingBottom: 0,
    backgroundColor: '#fff',
  },
  expand: {
    display: 'flex',
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  expandButton: {
    textTransform: 'none',
    justifyContent: 'flex-start',
    padding: 0,
    fontWeight: 'normal',
    marginTop: 10,
    color: '#444e53',
  },
  detailStyle: {
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: 0,
  },
  gridVertical: {
    display: 'flex',
    flexDirection: 'column',
  },
  textStyle: {
    whiteSpace: 'pre-line',
  },
  endsStyle: {
    display: 'flex',
    color: '#005abb',
    borderColor: '#005abb',
    textTransform: 'none',
    padding: 0,
    fontWeight: 'normal',
    marginRight: 5,
  },
  updateStyle: {
    color: '#005abb',
    textDecorationLine: 'underline',
    textTransform: 'none',
    padding: 0,
    fontWeight: 'normal',
    marginRight: 5,
    border: 'none',
  },
  deleteStyle: {
    color: '#cd3a3a',
    textDecorationLine: 'underline',
    textTransform: 'none',
    padding: 0,
    fontWeight: 'normal',
    border: 'none',
  },
  buttonsContainer: {
    display: 'flex',
    paddingLeft: 0,
    marginTop: 10,
    justifyContent: 'justify-start',
  },
  titleStyles: {
    justifyContent: 'space-between',
    display: 'flex',
    marginTop: 5,
  },
  captionStyle: {
    justifyContent: 'space-between',
    display: 'flex',
    marginTop: 5,
    color: '#82919b',
  },
  collapsedStyle: {},
  boxTitleStyle: {
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 0,
  },
  titleBoxStyle: {
    display: 'flex',
    justifyContent: 'flex-start',
    flex: 1,
    padding: 5,
  },
  titleBoxCamStyle: {
    fontSize: 16,
    paddingLeft: 0,
  },
  statusTextWaiting: {
    justifyContent: 'flex-start',
    color: '#444e53',
    padding: 5,
  },
  statusTextProgress: {
    justifyContent: 'flex-start',
    color: '#29c706',
    padding: 5,
  },
  statusTextClosed: {
    justifyContent: 'flex-start',
    color: '#82919b',
    padding: 5,
  },
  statusTextDisabled: {
    justifyContent: 'flex-start',
    color: '#ffa500',
    padding: 5,
  },
  statusTextScheduled: {
    justifyContent: 'flex-start',
    color: '#b900ff',
    padding: 5,
  },
  iconButton: {
    cursor: 'pointer',
    padding: 5,
    borderRadius: 50,
    color: 'white',
    marginRight: 5,
  },
}));

export default styles;
