import { makeStyles } from '@material-ui/core';

const styles = makeStyles((_theme) => ({
  cardContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    position: 'relative',
  },
  input: {
    backgroundColor: '#fff',
    marginTop: 0,
  },
  addNewStyle: {
    textTransform: 'none',
    marginTop: 10,
    color: '#005abb',
    borderRadius: 20,
    alignSelf: 'flex-end',
  },
  bulkButton: {
    textTransform: 'none',
    color: '#005abb',
    fontSize: 13,
    marginLeft: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },
  filtersButton: {
    textTransform: 'none',
    fontSize: 13,
    border: '1px solid',
    color: '#005abb',
    height: 30,
    marginTop: 35,
  },
  filtersPopover: {
    padding: 20,
    width: 500,
  },
  downloadButton: {
    textTransform: 'none',
    color: '#005abb',
    fontSize: 13,
    backgroundColor: '#ffffff',
    border: '1px solid',
    borderRadius: 4,
    padding: '10px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    marginBottom: 10,
    cursor: 'pointer',
    lineHeight: '1',
    letterSpacing: 'unset',
  },
}));

export default styles;
