import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
  CreateDENMPayload,
  DisableDENMPayload,
  GetDENMListPaylaod,
  GetDENMPayload,
  UpdateDENMPayload,
} from '_lib/api/types';

import { DatePeriodEnum } from '_store/utils';
import {
  Denm,
  DenmDateFilterType,
  DenmReducerState,
  DenmSourceFilter,
  DenmStatusFilter,
} from './denmsTypes';

const initialState: DenmReducerState = {
  loading: false,
  loadingCauses: false,
  causes: [],
  loadingSubCauses: false,
  subCauses: [],
  loadingSubCausesByCause: false,
  subCausesByCause: [],
  statusFilter: DenmStatusFilter.IN_PROGRESS,
  sourceFilter: [DenmSourceFilter.EMERAS],
  dateFilter: DatePeriodEnum.lastDay,
  subCauseFilter: '',
  causeFilter: '',
  createdFromFilter: null,
  createdToFilter: null,
  updatedFromFilter: null,
  updatedToFilter: null,
  dateFilterType: DenmDateFilterType.RELATIVE,
  metadata: null,
  roadSegmentId: null,
  sincroDispatchableFilter: false,
  allDenmsData: [],
  excludeCauseAndSubcauseFilter: false,
  loadingCSV: false,
  optionalSubCausesByCause: [],
  total: 0,
  showDenmFilteredById: false,
  paginationCurrentPage: 1,
  paginationItemsPerPage: 10,
};

export const denmsAdapter = createEntityAdapter({
  selectId: (el: Denm) => el.id,
});

const denmsReducer = createSlice({
  name: 'denms',
  initialState: denmsAdapter.getInitialState(initialState),
  reducers: {
    denmsRequest: (state, _action: PayloadAction<GetDENMListPaylaod>) => {
      const {
        sources,
        date,
        subCauseId,
        causeId,
        updatedFrom,
        updatedTo,
        sincroDispatchable,
        status,
        excludeCauseAndSubcause,
        getAllData,
        currentPage,
        itemsPerPage,
      } = _action.payload;

      state.loading = true;
      state.showDenmFilteredById = false;
      state.sourceFilter = sources ? (sources.length === 0 ? [] : sources) : state.sourceFilter;
      state.dateFilter = date ? date : state.dateFilter;
      state.subCauseFilter = subCauseId !== undefined ? subCauseId : state.subCauseFilter;
      state.causeFilter = causeId !== undefined ? causeId : state.causeFilter;
      state.updatedFromFilter = updatedFrom ? updatedFrom : state.updatedFromFilter;
      state.updatedToFilter = updatedTo ? updatedTo : state.updatedToFilter;
      state.statusFilter = status !== undefined ? status : state.statusFilter;
      if (sincroDispatchable !== undefined) {
        state.sincroDispatchableFilter = sincroDispatchable;
      }
      if (excludeCauseAndSubcause !== undefined) {
        state.excludeCauseAndSubcauseFilter = excludeCauseAndSubcause;
      }

      if (getAllData) {
        state.loadingCSV = true;
      }
      if (currentPage) {
        state.paginationCurrentPage = currentPage;
      }
      if (itemsPerPage) {
        state.paginationItemsPerPage = itemsPerPage;
      }
    },
    denmRequest: (state, _action: PayloadAction<GetDENMPayload>) => {
      state.loading = true;
      state.showDenmFilteredById = true;
    },
    denmsResponse: (state, action) => {
      if (state.loading) {
        denmsAdapter.setAll(state, action.payload.results);
        state.total = action.payload.total;
        state.loading = false;
      }
    },
    denmResponse: (state, action) => {
      if (action.payload) {
        denmsAdapter.removeAll(state);
        denmsAdapter.addOne(state, action.payload);
      }
      state.loading = false;
    },
    denmsAddOne: (state, action) => {
      denmsAdapter.addOne(state, action);
      state.loading = false;
    },
    denmsAddMany: denmsAdapter.addMany,
    denmUpdate: denmsAdapter.updateOne,

    denmRemove: denmsAdapter.removeOne,
    denmRemoveMany: denmsAdapter.removeMany,

    denmUpsertOne: (state, action) => {
      denmsAdapter.upsertOne(state, action);
      state.loading = false;
    },
    denmUpsertMany: denmsAdapter.upsertMany,

    createDENM: (state, _action: PayloadAction<CreateDENMPayload>) => {
      state.loading = true;
    },
    updateDENM: (state, _action: PayloadAction<UpdateDENMPayload>) => {
      state.loading = true;
    },
    deleteDENM: (state, _action: PayloadAction<string>) => {
      state.loading = true;
    },
    deleteDENMs: (state, _action: PayloadAction<string[]>) => {
      state.loading = true;
    },
    disableDENM: (state, _action: PayloadAction<DisableDENMPayload>) => {
      state.loading = true;
    },
    resetLoading: (state) => {
      state.loading = false;
    },
    causesRequest: (state) => {
      state.loadingCauses = true;
    },
    causesResponse: (state, action) => {
      state.loadingCauses = false;
      state.causes = action.payload;
    },
    subCausesRequest: (state, _action: PayloadAction<number>) => {
      state.loadingSubCauses = true;
    },
    subCausesResponse: (state, action) => {
      state.loadingSubCauses = false;
      state.subCauses = action.payload;
    },
    subCausesByCauseRequest: (state, _action: PayloadAction<string>) => {
      state.loadingSubCausesByCause = true;
    },
    subCausesByCauseResponse: (state, action) => {
      state.loadingSubCausesByCause = false;
      state.subCausesByCause = action.payload;
    },
    optionalSubCausesByCauseRequest: (state, _action: PayloadAction<string>) => {
      state.loadingSubCausesByCause = true;
    },
    optionalSubCausesByCauseResponse: (state, action) => {
      state.loadingSubCausesByCause = false;
      state.optionalSubCausesByCause = action.payload;
    },
    removeAllDenms: (state) => {
      denmsAdapter.removeAll(state);
    },
    changeDateFilterType: (state, _action: PayloadAction<DenmDateFilterType>) => {
      state.dateFilterType = _action.payload;
    },
    denmsAllDataResponse: (state, action) => {
      if (state.loading) {
        state.allDenmsData = action.payload;
        state.loading = false;
        state.loadingCSV = false;
      }
    },
    resetFilters: (state) => {
      state.sourceFilter = [DenmSourceFilter.EMERAS];
      state.statusFilter = DenmStatusFilter.IN_PROGRESS;
      state.dateFilter = DatePeriodEnum.lastDay;
      state.subCauseFilter = '';
      state.causeFilter = '';
      state.updatedFromFilter = null;
      state.updatedToFilter = null;
      state.sincroDispatchableFilter = false;
      state.excludeCauseAndSubcauseFilter = false;
    },
  },
});

export default denmsReducer;
