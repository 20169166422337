import { FormControl, Input, InputLabel, MenuItem, Select, Tooltip } from '@material-ui/core';
import React, { FC } from 'react';
import styles from './styles';

export interface Option {
  label: string;
  value: any;
}

type MultiSelectProps = {
  id?: string;
  text: string;
  options: Option[];
  value?: any[];
  disabled?: boolean;
  onChange?: (
    value: React.ChangeEvent<{
      name?: string;
      value: any;
    }>,
    child: React.ReactNode,
  ) => void;
  tooltip?: string;
  style?: React.CSSProperties;
  defaultValue?: any;
  labelStyle?: React.CSSProperties;
  tooltipPlacement?: 'right' | 'left' | 'top' | 'bottom';
};

const MultiSelectItem: FC<MultiSelectProps> = (props) => {
  const classes = styles();

  return (
    <FormControl key={props.id} className={classes.formControl}>
      <InputLabel className={classes.input} style={props.labelStyle}>
        {props.text}
      </InputLabel>
      <Tooltip title={props.tooltip} placement={props.tooltipPlacement ?? 'right'}>
        <Select
          disabled={props.disabled}
          onChange={props.onChange}
          value={props.value}
          input={<Input />}
          style={props.style}
          defaultValue={props.defaultValue}
          multiple={true}
        >
          {props.options.map((opt) => (
            <MenuItem key={opt.value} value={opt.value}>
              {opt.label}
            </MenuItem>
          ))}
        </Select>
      </Tooltip>
    </FormControl>
  );
};

export default React.memo(MultiSelectItem);
