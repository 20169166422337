import { useLocalization } from '@fluent/react';
import { CardContent, Chip, Divider, Grid, Typography } from '@material-ui/core';
import { RSU, UDPDistributionKind } from '_lib/api/types';
import React, { FC } from 'react';

import {
  IconDefinition,
  faClock,
  faEthernet,
  faHardDrive,
  faSatelliteDish,
  faStethoscope,
  faTemperatureQuarter,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DiagnosticStatusDetails } from '_store/api/types';
import { Accordion } from '../../../../components/items/Accordion';
import styles from './styles';

const statusGreen = '#4fca4f';
const statusRed = '#ff4242';

export interface CardDetailsProps {
  device: RSU;
  diagnostic: {
    connection: boolean;
    service: boolean;
    statusDetails: DiagnosticStatusDetails;
  };
}

const CardDetails: FC<CardDetailsProps> = ({ device, diagnostic }) => {
  const classes = styles();
  const { l10n } = useLocalization();

  const { host, port } = device.distribution as UDPDistributionKind;

  const convertUptime = (uptime: string) => {
    if (uptime) {
      const parts = uptime?.split(', ');
      const time = parts[parts.length - 1];
      const hours = time.split(':')[0];

      if (parts.length > 1) {
        return `${parts[0]} ${hours} hours`;
      } else {
        return `${hours} hours`;
      }
    }
    return '';
  };

  const showTextOrHiphen = (text: string | number | undefined) => (text ? text : '-');

  return (
    <CardContent className={classes.detailStyle}>
      <Grid container spacing={1} style={{ marginTop: 10, paddingRight: 20 }}>
        <Grid item xs={12}>
          <Typography component={'span'} variant={'body1'} paragraph>
            {l10n.getString('forms-rsu-detail-header')}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.gridVertical}>
          <Typography component={'span'} variant={'body2'} paragraph className={classes.textStyle}>
            {l10n.getString('forms-rsu-name')} <strong>{`\n ${device.name}`}</strong>
          </Typography>
          <Grid container direction="row" alignItems="flex-start" className={classes.innerGrid}>
            <Grid item xs={12} sm={8}>
              <Typography
                component={'span'}
                variant={'body2'}
                paragraph
                className={classes.textStyle}
              >
                {l10n.getString('forms-rsu-ip-rsu')} <strong>{`\n ${host}`}</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography
                component={'span'}
                variant={'body2'}
                paragraph
                className={classes.textStyle}
              >
                {l10n.getString('forms-rsu-port-rsu')} <strong>{`\n ${port}`}</strong>
              </Typography>
            </Grid>
          </Grid>

          <Grid container direction="row" alignItems="flex-start" className={classes.innerGrid}>
            <Grid item xs={12} sm={8}>
              <Typography
                component={'span'}
                variant={'body2'}
                paragraph
                className={classes.textStyle}
              >
                {l10n.getString('forms-rsu-lat')}{' '}
                <strong>{`\n ${device.location.coordinates[1]}`}</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography
                component={'span'}
                variant={'body2'}
                paragraph
                className={classes.textStyle}
              >
                {l10n.getString('forms-rsu-long')}{' '}
                <strong>{`\n ${device.location.coordinates[0]}`}</strong>
              </Typography>
            </Grid>
          </Grid>

          <Grid container direction="row" alignItems="flex-start" className={classes.innerGrid}>
            <Grid item xs={12} sm={8}>
              <Typography variant={'body2'} paragraph className={classes.textStyle}>
                {l10n.getString('forms-rsu-direction')}{' '}
                <strong>{`\n ${showTextOrHiphen(device.direction)}`}</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant={'body2'} paragraph className={classes.textStyle}>
                {l10n.getString('forms-rsu-km')}{' '}
                <strong>{`\n ${showTextOrHiphen(device.km)}`}</strong>
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" alignItems="flex-start" className={classes.innerGrid}>
            <Grid item xs={12} sm={8}>
              <Typography variant={'body2'} paragraph className={classes.textStyle}>
                {l10n.getString('forms-rsu-type')}{' '}
                <strong>{`\n ${showTextOrHiphen(device.type)}`}</strong>
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider variant="fullWidth" light className={classes.divider} />
          <Typography
            component={'span'}
            variant={'body1'}
            paragraph
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {l10n.getString('forms-rsu-diagnostic-header')}
          </Typography>
          <Grid container direction="row" alignItems="flex-start" className={classes.innerGrid}>
            <Grid item xs={12} sm={8}>
              <Typography component={'span'} variant={'body2'} className={classes.diagnostic}>
                {l10n.getString('forms-rsu-diag-device-uid')}{' '}
                <strong>{showTextOrHiphen(diagnostic.statusDetails?.deviceUid)}</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography component={'span'} variant={'body2'} className={classes.diagnostic}>
                {l10n.getString('forms-rsu-diag-station-id')}{' '}
                <strong>{showTextOrHiphen(diagnostic.statusDetails?.stationId)}</strong>
              </Typography>
            </Grid>
          </Grid>
          <Accordion title="Interface 1" className={classes.accordion}>
            <Grid item className={classes.gridVertical} xs={12}>
              <Typography component={'span'} variant={'body2'} className={classes.diagnostic}>
                txPacket{' '}
                <strong>{showTextOrHiphen(diagnostic.statusDetails?.interface1?.txPacket)}</strong>
              </Typography>
              <Typography component={'span'} variant={'body2'} className={classes.diagnostic}>
                rxPacket{' '}
                <strong>{showTextOrHiphen(diagnostic.statusDetails?.interface1?.rxPacket)}</strong>
              </Typography>
              <Typography component={'span'} variant={'body2'} className={classes.diagnostic}>
                rxRssiLastPacket{' '}
                <strong>
                  {showTextOrHiphen(diagnostic.statusDetails?.interface1?.rxRssiLastPacket)}
                </strong>
              </Typography>
            </Grid>
          </Accordion>
          <DiagnosticIcon
            title={l10n.getString('forms-rsu-diag-connection')}
            status={diagnostic.connection}
            icon={faEthernet}
          />
          <DiagnosticIcon
            title={l10n.getString('forms-rsu-diag-service')}
            status={diagnostic.service}
            icon={faStethoscope}
          />
          <DiagnosticIcon
            status={diagnostic.statusDetails?.diskStatus}
            icon={faHardDrive}
            title={!diagnostic.statusDetails?.diskStatus && l10n.getString('forms-rsu-diag-disk')}
            text={
              diagnostic.statusDetails?.diskFree &&
              `${diagnostic.statusDetails?.diskFree} MB ${l10n.getString(
                'forms-rsu-diag-disk-free',
              )}`
            }
          />
          <DiagnosticIcon
            status={diagnostic.statusDetails?.navFixIsValid}
            icon={faSatelliteDish}
            title={l10n.getString('forms-rsu-diag-gps')}
          />
          <DiagnosticIcon
            title={l10n.getString('forms-rsu-diag-cpu-temp')}
            icon={faTemperatureQuarter}
            text={diagnostic.statusDetails?.cpuTemp && `${diagnostic.statusDetails?.cpuTemp} °C`}
          />
          <DiagnosticIcon
            title={l10n.getString('forms-rsu-diag-stack-uptime')}
            icon={faClock}
            text={convertUptime(diagnostic.statusDetails?.stackUptime)}
          />
        </Grid>
      </Grid>
    </CardContent>
  );
};

interface DiagnosticIconProps {
  title?: string;
  status?: boolean;
  icon: IconDefinition;
  text?: string;
  showRedIcon?: boolean;
}

const DiagnosticIcon: React.FC<DiagnosticIconProps> = ({
  title,
  status,
  icon,
  text,
  showRedIcon,
}) => {
  const classes = styles();
  return (
    <Chip
      variant="outlined"
      icon={
        <FontAwesomeIcon
          icon={icon}
          style={{ color: status ? statusGreen : showRedIcon ? statusRed : 'lightgrey' }}
        />
      }
      label={`${title || ''} ${text || ''}`}
      className={classes.chip}
      size="small"
      style={{ borderColor: status ? statusGreen : showRedIcon ? statusRed : 'lightgrey' }}
    />
  );
};

export default React.memo(CardDetails);
