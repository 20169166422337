interface DenmProps {
  createdFrom?: string;
  updatedFrom: string;
  source: string;
  status: string;
  roadSegments?: string;
  causeId?: string;
  subCauseId?: string;
  createdTo?: string;
  updatedTo?: string;
  sincroDispatchable?: string;
  page?: number;
  limit?: number;
}

interface IvimProps {
  createdFrom: string;
  status: string;
  roadSegments?: string;
  createdTo?: string;
  page?: number;
  limit?: number;
  serviceCategoryCode?: string;
  serviceSubCategoryCode?: string;
}

const apiRoute = {
  getDENMs: ({
    createdFrom,
    updatedFrom,
    source,
    status,
    roadSegments,
    causeId,
    subCauseId,
    createdTo,
    updatedTo,
    sincroDispatchable,
    page,
    limit,
  }: DenmProps) =>
    `api/etsi/v1alpha1/denms?${roadSegments}&${status}&updatedAt=${updatedFrom}&${source}${causeId}${subCauseId}${updatedTo}${sincroDispatchable}${
      page ? `&page=${page}` : ''
    }${limit ? `&limit=${limit}` : ''}`,
  getDENM: (id: string) => `api/etsi/v1alpha1/denms/${id}`,
  createDENM: `api/etsi/v1alpha1/denms`,
  updateDENM: (id: string) => `api/etsi/v1alpha1/denms/${id}`,
  deleteDENM: (id: string) => `api/etsi/v1alpha1/denms/${id}`,
  deleteDENMs: `api/etsi/v1alpha1/denms/set_deleted`,
  disableDENM: (disable: string) => `api/etsi/v1alpha1/denms/set_disabled/${disable}`,
  getIVIMs: ({
    createdFrom,
    status,
    roadSegments,
    createdTo,
    serviceCategoryCode,
    serviceSubCategoryCode,
    page,
    limit,
  }: IvimProps) =>
    `api/etsi/v1alpha1/ivims?${roadSegments}&${status}&createdAt=${createdFrom}&${createdTo}&${serviceCategoryCode}&${serviceSubCategoryCode}${
      page ? `&page=${page}` : ''
    }${limit ? `&limit=${limit}` : ''}`,
  createIVIM: `api/etsi/v1alpha1/ivims`,
  updateIVIM: (id: string) => `api/etsi/v1alpha1/ivims/${id}`,
  deleteIVIM: (id: string) => `api/etsi/v1alpha1/ivims/${id}`,
  deleteIVIMs: `api/etsi/v1alpha1/ivims/set_deleted`,
  disableIVIM: (disable: string) => `api/etsi/v1alpha1/ivims/set_disabled/${disable}`,
  devices: (roadSegments: string) => `api/devices/v1alpha1/rsus?${roadSegments}`,
  device: (id: string) => `api/devices/v1alpha1/rsus/${id}`,
  createRSU: `api/devices/v1alpha1/rsus`,
  updateRSU: (id: string) => `api/devices/v1alpha1/rsus/${id}`,
  deleteRSU: (id: string) => `api/devices/v1alpha1/rsus/${id}`,
  roads: `api/roads/v1alpha1/segments`,
  causes: `api/etsi/v1alpha1/denm-causes`,
  subCauses: `api/etsi/v1alpha1/denm-subcauses?limit=200`,
  subCausesByCause: (causeId: string) => `api/etsi/v1alpha1/denm-causes/${causeId}/subcauses`,
  getSettings: `settings`,
  updateSettings: (path: string, setting: string) => `settings/${path}/${setting}`,
  getCams: `api/etsi/v1alpha1/cams`,
};

export default apiRoute;
