import { makeStyles } from '@material-ui/core';

const styles = makeStyles((_theme) => ({
  cardContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#fff',
  },
  header: {
    backgroundColor: '#fff',
    display: 'flex',
  },
  closeBnt: {
    width: 35,
    height: 35,
    alignSelf: 'flex-end',
    textTransform: 'none',
  },
  backBnt: {
    textTransform: 'none',
  },
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
  },
  inputStyle: {
    marginTop: 10,
  },
}));

export default styles;
