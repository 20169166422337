import { makeStyles } from '@material-ui/core';

const styles = makeStyles((_theme) => ({
  cardContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    position: 'relative',
    gap: 8,
  },
  boxContent: {
    border: '1px solid #e0e0e0',
    borderRadius: 4,
    padding: 8,
    marginBottom: 8,
  },
}));

export default styles;
