import { useLocalization } from '@fluent/react';
import { Box } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React from 'react';
import styles from './styles';
import SelectItem from '../../app/components/items/SelectItem';

interface PaginationComponentProps {
  currentPage: number;
  totalPages: number;
  handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  itemsPerPage: number;
  setItemsPerPage: (value: number) => void;
  goToPage: (page: number) => void;
}

export const PaginationComponent = ({
  currentPage,
  totalPages,
  handleChange,
  itemsPerPage,
  setItemsPerPage,
  goToPage,
}: PaginationComponentProps) => {
  const { l10n } = useLocalization();
  const classes = styles();

  return (
    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Pagination
        count={totalPages}
        color="primary"
        page={currentPage}
        onChange={handleChange}
        size="small"
        siblingCount={0}
        classes={{ root: classes.pagination }}
      />
      <SelectItem
        text={''}
        value={itemsPerPage}
        options={[10, 30, 50, 100].map((i) => ({ label: i.toString(), value: i }))} // eslint-disable-line
        onChange={(e) => {
          setItemsPerPage(e.target.value as number);
          goToPage(1);
        }}
        tooltip={l10n.getString('forms-event-pagination-rows')}
        style={{ marginTop: 0 }}
      />
    </Box>
  );
};
