import { MapElement, PointSelectionType } from '_store/application/types';
import React, { FC, useEffect, useState } from 'react';

import Elements from './Elements';

const ExtraSelectionElements: FC<{ extra?: Record<PointSelectionType, MapElement[]> }> = ({
  extra,
}) => {
  const [extraElementsFiltered, setExtraElementsFiltered] = useState([]);
  const [renderKey, setRenderKey] = useState(0);

  useEffect(() => {
    if (extra) {
      const newExtraElementsFiltered = Object.keys(extra).map((type: PointSelectionType) => ({
        type,
        elements: extra[type].map((element) => {
          if (element.options.color === element.options.color) {
            return element;
          }

          return {
            ...element,
            options: {
              ...element.options,
              color: element.options.color,
            },
          };
        }),
      }));

      setExtraElementsFiltered(newExtraElementsFiltered);
      setRenderKey((prevKey) => prevKey + 1);
    }
  }, [extra]);

  return (
    <>
      {extraElementsFiltered.map((e, key) => (
        <Elements elements={e.elements} index={key} key={`${e.type}${key}${renderKey}`} />
      ))}
    </>
  );
};

export default ExtraSelectionElements;
