import { Coordinate, MapNode } from '_lib/api';
import { FluentVariable } from '@fluent/bundle';
import { Map as LeafletMap, PathOptions } from 'leaflet';

export interface ApplicationState {
  currentLocale: string;
  activeDialog: Dialog;
  pointSelections: PointSelection;
  basePoint: { poi: MapNode; bound: string };
  deletePoints: ManageDelete;
  manageSelections: ManageSelection;
  currentSelection: PointSelectionType;
  extraMapElements: Record<string, MapElement[]>;
  activeLayers: Record<string, boolean>;
  countLayers: number;
  toasts: ToastData[];
  centerCoordsPosition: MapDefaultPosition;
  eventCoordsPosition: MapDefaultPosition;
  ready: boolean;
  mapRef: LeafletMap;
  zoom: number;
  extraTraceMapElements: Record<string, Coordinate[]>;
  userLoggedIn: boolean;
}

export const enum DialogType {
  HOME = 'Home',
  CREATE_IVIM = 'CreateIvim',
  CREATE_DENM = 'CreateDenm',
  DENM = 'Denm',
  IVIM = 'Ivim',
  DEVICE = 'Device',
  SINGLE_DEVICE = 'SingleDevice',
  DENM_LIST = 'DenmList',
  DENM_PATH = 'DenmPath',
  UPDATE_DENM = 'UpdateDenm',
  CLONE_DENM = 'CloneDenm',
  IVIM_PATH = 'IvimPath',
  IVIM_LIST = 'IvimList',
  UPDATE_IVIM = 'UpdateIvim',
  DEVICE_PATH = 'DevicePath',
  SETTINGS = 'Settings',
  CAM = 'Cam',
  CAM_LIST = 'CamList',
  CONNEX = 'Connex',
}

export interface Dialog {
  type?: DialogType;
  id: string;
  busy: boolean;
}

export enum PointCount {
  ONE = 'one',
  TWO = 'two',
  MANY = 'many',
  TWENTYTHREE = 'twentythree',
  FORTY = 'forty',
}

export interface PointSelection {
  active: boolean;
  pointCount: PointCount;
  points: Coordinate[];
  style: MapElement | null;
}

export interface ManageDelete {
  active: boolean;
  pointCount?: PointCount;
  direction: string;
  points: MapNode[];
  pointsNumberTo?: number[];
  pointsNumberFrom?: number[];
  style: MapElement | null;
}

export interface ManageSelection {
  active: boolean;
  pointCount: PointCount;
  direction: string;
  points: MapNode[];
  pointsNumberTo?: number[];
  pointsNumberFrom?: number[];
  style: MapElement | null;
}

export enum MapElementType {
  CIRCLE = 'circle',
  POLYLINE = 'polyline',
  CIRCLE_GENERATED = 'circle-generated',
}

export type MapElement = CircleMapElement | PolylineMapElement | CircleMapElementGenerated;

export interface CircleMapElement {
  type: MapElementType.CIRCLE;
  loc: Coordinate;
  radius: number;
  options?: PathOptions;
}

export interface PolylineMapElement {
  type: MapElementType.POLYLINE;
  loc: Coordinate;
  points: Coordinate[]; // As offsets from loc
  extremeRefPoint?: MapNode;
  options?: PathOptions;
  radius?: number;
  distance?: number;
}

export interface CircleMapElementGenerated {
  type: MapElementType.CIRCLE_GENERATED;
  loc: Coordinate;
  radius: number;
  options?: PathOptions;
}

export enum PointSelectionType {
  DENM_REFERENCE_POINT = 'denm/refpoint',
  DENM_HISTORY = 'denm/history',
  DENM_TRACES = 'denm/traces',
  IVIM_REFERENCE_POINT = 'ivim/refpoint',
  IVIM_DET_ZONE = 'ivim/detzone',
  IVIM_REL_ZONE = 'ivim/relzone',
  MEASUREMENT = 'measurement',
}

export interface PointSelectionParams {
  type: PointSelectionType;
  pointCount: PointCount;
  style: MapElement | null;
}

export interface ToastTitle {
  id: string;
  args?: Record<string, FluentVariable>;
}
export interface ToastData {
  id: string;
  title: ToastTitle;
}

export interface MapDefaultPosition {
  center: { lat: number; lng: number };
}
