import { RootState } from '_store';
import { DialogType } from '_store/application/types';
import { useSelector } from 'react-redux';

import { isFormValid as isFormValidDenm } from '../../scenes/Denm/CreateDenm/DenmCreate';
import { isFormValid as isFormValidIvim } from '../../scenes/Ivim/CreateIvim';

export const canInfoViewBeOpened = (dialog, selection) =>
  !(dialog && dialog.type === DialogType.CREATE_DENM) && !selection.active;

export const canInfoViewBeClosed = () => {
  const formStateDenm = useSelector((state: RootState) => state.forms['manage-denm']);
  const formStateIvim = useSelector((state: RootState) => state.forms['create-ivim']);
  const pointSelection = useSelector((state: RootState) => state.application.pointSelections);
  return (
    isFormValidDenm(formStateDenm) ||
    isFormValidIvim(formStateIvim) ||
    formStateIvim.rsutarget.length > 0 ||
    pointSelection.points.length > 0
  );
};
