import React from 'react';

import { CardContent, Grid, Typography } from '@material-ui/core';
import { Denm, DenmSincroMetaData } from '_store/denm/denmsTypes';
import styles from './styles';
import { useLocalization } from '@fluent/react';

export interface SincroMetaDataProps {
  sincroData: DenmSincroMetaData;
}

export const SincroMetaData = ({ sincroData }: SincroMetaDataProps) => {
  const classes = styles();
  const { l10n } = useLocalization();

  const showSincroDataOrNA = (value: string | undefined) => {
    return value ? value : 'N/A';
  };

  return (
    <CardContent className={classes.detailStyle}>
      <Grid container spacing={1} style={{ marginTop: 10, marginLeft: 5 }}>
        <Grid container spacing={1}>
          <Typography component={'span'} variant={'body2'} paragraph className={classes.textStyle}>
            <strong>Start point: {'\n'}</strong>
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              Km: {showSincroDataOrNA(sincroData.startPoint?.km?.toString())} {'\n'}
            </Typography>
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              Road tag: {showSincroDataOrNA(sincroData.startPoint?.roadTag)} {'\n'}
            </Typography>
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              Distance:{' '}
              {showSincroDataOrNA(
                isNaN(sincroData.startPoint?.distance)
                  ? undefined
                  : `${(sincroData.startPoint.distance * 1000).toFixed(1)} m`,
              )}
              {'\n'}
            </Typography>
          </Typography>
        </Grid>
        <Grid container spacing={1}>
          <Typography component={'span'} variant={'body2'} paragraph className={classes.textStyle}>
            <strong>End point: {'\n'}</strong>
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              Km: {showSincroDataOrNA(sincroData.endPoint?.km?.toString())} {'\n'}
            </Typography>
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              Road tag: {showSincroDataOrNA(sincroData.endPoint?.roadTag)} {'\n'}
            </Typography>
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              Distance:{' '}
              {showSincroDataOrNA(
                isNaN(sincroData.endPoint?.distance)
                  ? undefined
                  : `${(sincroData.endPoint.distance * 1000).toFixed(1)} m`,
              )}
              {'\n'}
            </Typography>
          </Typography>
        </Grid>
        <Grid container spacing={1}>
          <Typography component={'span'} variant={'body2'} paragraph className={classes.textStyle}>
            <strong>
              {l10n.getString('details-sincro-dispatchable')}: {'\n'}
            </strong>
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              {sincroData.dispatchable ? 'Yes' : 'No'} {'\n'}
            </Typography>
          </Typography>
        </Grid>
        <Grid container spacing={1}>
          <Typography component={'span'} variant={'body2'} paragraph className={classes.textStyle}>
            <strong>
              {l10n.getString('details-sincro-not-dispatchable-reason')}: {'\n'}
            </strong>
            {showSincroDataOrNA(sincroData.nonDispatchableReason)} {'\n'}
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  );
};
