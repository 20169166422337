import { useLocalization } from '@fluent/react';
import { Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { Tooltip } from 'react-leaflet';

import styles from '../styles';
import { defaultOffset } from '.';
import { HoveringIVIM, HoverTooltipProps } from './types';

const IvimInfoTooltip: FC<HoverTooltipProps> = ({ hoveringElement }) => {
  const { l10n } = useLocalization();
  const classes = styles();
  // const { ivimEvents } = useSelector((state: RootState) => state.api.guiData.dictionaries);
  // const ivimDetails = useSelector((state: RootState) => state.api.ivimDetails);
  const { ivim, offset } = hoveringElement as HoveringIVIM;
  // const details = ivimDetails[ivim.idivim];
  // const ivimEvent = details
  //   ? ivimEvents.find(
  //       (i) =>
  //         i.serviceCategoryCode == details.serviceCategoryCode &&
  //         i.serviceCategorySubCode === details.serviceCategorySubCode,
  //     )
  //   : null;
  // const icon = ivimEvent
  // ? ivimIcons[ivimEvent.icon]
  // : ivimIcons['TrafficSign_DangerWarning.png'];
  return (
    <Tooltip direction="top" offset={offset || defaultOffset}>
      <Typography variant="caption" className={classes.captionStyle}>
        {new Date(ivim.starts).toLocaleString()}
      </Typography>
      <div className={classes.tooltipHeader}>
        {/* <img
          src={icon.png}
          style={{ width: 'auto', height: '2rem', marginRight: '0.5rem' }} /> */}
        <div>
          {/* <div>
            {l10n.getString(`forms-ivim-ser-cat-cod-enum-${details.serviceCategoryCode}`, {
              defaultValue: details.serviceCategoryCode,
            })}
          </div>
          {ivim?.text.length > 0 ? (
            <div className={classes.tooltipSubHeader}>
              {l10n.getString(`forms-ivim-ser-cat-sub-cod-enum-${details.serviceCategorySubCode}`, {
                defaultValue: details.serviceCategorySubCode,
              })}
            </div>
          ) : null} */}
        </div>
      </div>
      <table>
        <tbody>
          <tr>
            <th className={classes.tooltipTableHeader}>
              {l10n.getString('forms-info-box-id-info')}
            </th>
            <td>{ivim.id}</td>
          </tr>
          <tr>
            <th className={classes.tooltipTableHeader}>{l10n.getString('forms-info-box-node')}</th>
            <td>
              {ivim.id}
              {/* ({ivim.detZoneCoordinates[0][1]}, {ivim.detZoneCoordinates[0][0]}) */}
            </td>
          </tr>
        </tbody>
      </table>
    </Tooltip>
  );
};

export default IvimInfoTooltip;
