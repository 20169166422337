import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { AppLocalizationProvider } from "./app/locale/l10n";
import AppWrapper from './AppWrapper';
import store from './store';

const Container = () => (
    <AppLocalizationProvider>
      <Provider store={store}>
        <AppWrapper />
      </Provider>
    </AppLocalizationProvider>
  );

render(<Container />, document.getElementById('root'));
