import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, CardHeader, Divider } from '@material-ui/core';
import React from 'react';
import styles from '../styles';

interface CardHeaderBoxProps {
  cardHeaderTitle: string;
  onFabClick?: () => void;
  onFabClickText?: string;
}

export const CardHeaderBox = ({
  cardHeaderTitle,
  onFabClick,
  onFabClickText,
}: CardHeaderBoxProps) => {
  const classes = styles();

  return (
    <>
      <Box
        className={classes.headerBox}
        style={{
          marginTop: 10,
        }}
      >
        <CardHeader
          title={cardHeaderTitle}
          className={classes.header}
          titleTypographyProps={{ variant: 'h6' }}
        />
        {onFabClick && (
          <Button
            variant="outlined"
            className={classes.addNewButton}
            endIcon={<FontAwesomeIcon icon={faPlus} size="xs" />}
            onClick={onFabClick}
            size="small"
          >
            {onFabClickText}
          </Button>
        )}
      </Box>
      {onFabClick && <Divider style={{ marginTop: 12 }} />}
    </>
  );
};
