import applicationReducer from '_store/application/reducer';
import { dialogSelector, selectionSelector } from '_store/application/selectors';
import { DialogType } from '_store/application/types';
import { Ivim } from '_store/ivim/ivimsTypes';
import { defaultIcon, ivimStatuses } from '_store/ivim/utils';
import L, { Map } from 'leaflet';
import React, { FC, ReactElement, useMemo } from 'react';
import { Marker } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';

import { eventIcon } from '../../../../icons';
import ivimIcons from '../../../../icons/ivim';
import { canInfoViewBeOpened } from '../../../InfoView/utils';
import IvimInfoTooltip from '../../InfoTooltips/IvimInfoTooltip';
import { HoveringElementType } from '../../InfoTooltips/types';
import { toLatLng } from '../../utils';

const iconSize = new L.Point(48, 48);
const iconAnchor = new L.Point(iconSize.x / 2, iconSize.y);
const tooltipOffset = new L.Point(0, -iconSize.y);

const IvimMarker: FC<{
  ivim: Ivim;
  mapRef: Map;
}> = ({ ivim, mapRef }): ReactElement => {
  const dispatch = useDispatch();

  const dialog = useSelector(dialogSelector);
  const selection = useSelector(selectionSelector);

  const markerPosition = useMemo(() => toLatLng(ivim?.referencePoint?.coordinates), [ivim]);
  const eventHandlers = useMemo(
    () => ({
      click: () => {
        if (canInfoViewBeOpened(dialog, selection)) {
          dispatch(
            applicationReducer.actions.infoSelected({
              type: DialogType.IVIM,
              id: ivim.id,
            }),
          );
          mapRef.panTo(markerPosition);
        }
      },
    }),
    [mapRef, ivim, markerPosition, dialog, selection],
  );

  const { isIvimActive, disabled, scheduled } = ivimStatuses(ivim);
  const isIvimSelected =
    dialog &&
    [DialogType.IVIM, DialogType.IVIM_PATH].includes(dialog.type) &&
    dialog.id === ivim.id;

  return (
    <Marker
      position={markerPosition}
      icon={
        new L.Icon({
          iconSize,
          // Icons should be based on service category code, but we have only one right now
          iconUrl: `data:image/svg+xml;utf8,${eventIcon(
            ivimIcons[defaultIcon].base64,
            scheduled ? 'scheduled' : disabled ? 'disabled' : 'progress',
          )}`,
          iconAnchor,
          className: `marker-event-${isIvimSelected ? 'selected' : 'unselected'} marker-event-${
            disabled || scheduled || isIvimActive ? 'progress' : 'terminated'
          }`,
        })
      }
      eventHandlers={eventHandlers}
    >
      <IvimInfoTooltip
        hoveringElement={{
          type: HoveringElementType.IVIM,
          ivim,
          offset: tooltipOffset,
        }}
      />
    </Marker>
  );
};

export default React.memo(IvimMarker);
