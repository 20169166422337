import { MapNode } from '_lib/api';
import applicationReducer from '_store/application/reducer';
import {
  activeLayersSelector,
  countLayersSelector,
  dialogSelector,
  extraSelector,
  mapPositionSelector,
  selectionSelector,
  zoomSelector,
} from '_store/application/selectors';
import { devicesSelector } from '_store/devices/selectors';
import { Road } from '_store/roads/roadsTypes';
import { roadsSelector, selectedRoadSegmentIdsSelector } from '_store/roads/selectors';
import { camsSelector } from '_store/vehicles/selectors';
import { Map as LeafletMap } from 'leaflet';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDenmsForRoadSegment } from '_store/denm/utils';
import { getFromLocalStorage } from '../../../../../utils';
import { calculateCenterCoordinates, findCoordinatesBounds, getBoundsZoomLevel } from '../utils';
import { useIvimsForRoadSegment } from '_store/ivim/utils';

export default () => {
  const googleMapsApiKey = process.env.GMAPS_API_KEY;
  const dispatch = useDispatch();

  const roads: Road[] = useSelector(roadsSelector);
  const selectedRoadSegmentIds: string[] = useSelector(selectedRoadSegmentIdsSelector);
  const denms = useDenmsForRoadSegment(selectedRoadSegmentIds);
  const ivim = useIvimsForRoadSegment(selectedRoadSegmentIds);
  const vehicles = useSelector(camsSelector);
  const devices = useSelector(devicesSelector);
  const mapPosition = useSelector(mapPositionSelector);
  const zoom = useSelector(zoomSelector);
  const dialog = useSelector(dialogSelector);
  const extra = useSelector(extraSelector);
  const selection = useSelector(selectionSelector);
  const activeLayers = useSelector(activeLayersSelector);
  const countLayers = useSelector(countLayersSelector);

  const links: Record<number, MapNode[]> = {};
  // if (nodes) {
  //   [...nodes]
  //     .sort((a, b) => a.number - b.number)
  //     .forEach((n) => {
  //       const linkdir = `${n.link}-${n.direction}`;
  //       if (linkdir in links) {
  //         links[linkdir].push(n);
  //       } else {
  //         links[linkdir] = [n];
  //       }
  //     });
  // }

  const [mapRef, setMapRef] = useState<LeafletMap>(null);

  const [loading, setLoading] = useState(true);
  const [centerCoordinates, setCenterCoordinates] = useState(undefined);

  const loadHandler = useCallback(
    (map: LeafletMap) => {
      // Store a reference to the map instance in state
      if (!mapRef) {
        map.on('zoom', (e) => {
          dispatch(applicationReducer.actions.updateZoom(e.target.getZoom()));
        });
        setMapRef(map);
        dispatch(applicationReducer.actions.setMapRef(map));
      }

      const layers = ['event', 'informative', 'rsu', 'data'];

      layers.forEach((layer) => {
        dispatch(
          applicationReducer.actions.updateActiveLayer({
            layer,
            status: getFromLocalStorage(layer) ?? true,
          }),
        );
      });
    },
    [mapRef],
  );

  const meterPerPixel = useCallback(
    () =>
      (156543.03392 * Math.cos((mapPosition.center.lat * Math.PI) / 180)) / 2 ** mapRef.getZoom(),
    [mapPosition, mapRef],
  );

  useEffect(() => {
    if (selectedRoadSegmentIds.length > 0 && mapRef) {
      const groupOfCoordinates = selectedRoadSegmentIds.flatMap(
        (id) => roads.find((r) => r.id === id)?.waypoints.coordinates || [],
      );

      if (groupOfCoordinates.length > 0) {
        const coordinates = groupOfCoordinates.flat();
        const bounds = findCoordinatesBounds(coordinates);
        const newZoomLevel = getBoundsZoomLevel(bounds, mapRef);
        dispatch(applicationReducer.actions.updateZoom(newZoomLevel));
        mapRef.setZoom(newZoomLevel);
        mapRef.flyToBounds(bounds, {
          animate: true,
          duration: 1.5,
        });
      }
    }
  }, [selectedRoadSegmentIds, mapRef, mapPosition]);

  useEffect(() => {
    setCenterCoordinates(
      calculateCenterCoordinates(roads, selectedRoadSegmentIds, mapPosition.center),
    );
  }, [roads, selectedRoadSegmentIds, mapPosition, mapRef]);

  useEffect(() => {
    if (centerCoordinates) {
      setLoading(false);
    }
  }, [centerCoordinates]);

  useEffect(() => {
    if (mapRef) {
      mapRef.invalidateSize();
    }
  }, [dialog]);

  return {
    roads,
    devices,
    denms,
    ivim,
    vehicles,
    mapPosition,
    dialog,
    extra,
    selection,
    activeLayers,
    countLayers,
    mapRef,
    loading,
    links,
    loadHandler,
    meterPerPixel,
    centerCoordinates,
    googleMapsApiKey,
    zoom,
  };
};
