import { makeStyles } from '@material-ui/core';

const styles = makeStyles((_theme) => ({
  pagination: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default styles;
