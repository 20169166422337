import { RSU } from '_lib/api';
import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DeviceReducerState } from './devicesTypes';

const initialState: DeviceReducerState = {
  loading: false,
};

export const devicesAdapter = createEntityAdapter({
  selectId: (el: RSU) => el.id,
});

const devicesReducer = createSlice({
  name: 'devices',
  initialState: devicesAdapter.getInitialState(initialState),
  reducers: {
    devicesRequest: (state, _action: PayloadAction<string>) => {
      state.loading = true;
    },
    devicesResponse: (state, action: PayloadAction<RSU[]>) => {
      if (state.loading) {
        devicesAdapter.setAll(state, action.payload);
        state.loading = false;
      }
    },
    deviceRequest: (state, _action: PayloadAction<string>) => {
      state.loading = true;
    },
    deviceResponse: (state, action: PayloadAction<RSU>) => {
      const updatedRSU = action.payload;
      devicesAdapter.updateOne(state, { id: updatedRSU.id, changes: updatedRSU });
      state.loading = false;
    },
    devicesAddOne: devicesAdapter.addOne,
    devicesAddMany: devicesAdapter.addMany,
    devicesUpdate: devicesAdapter.updateOne,
    devicesRemove: devicesAdapter.removeOne,
    devicesUpsertOne: devicesAdapter.upsertOne,
  },
});

export default devicesReducer;
