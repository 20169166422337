import { makeStyles } from '@material-ui/core';

const styles = makeStyles((_theme) => ({
  drawerPaper: {
    // height: '15vh',
    alignItems: 'center',
    justifyContent: 'flex-end',
    display: 'flex',
    paddingLeft: 10,
  },

  avatar: {
    backgroundColor: '#444e53',
  },

  dateText: {
    margin: 10,
    color: '#444e53',
  },
  dateTextBold: {
    margin: 10,
    color: '#444e53',
    fontWeight: 'bold',
  },
  subHeaderStyle: {
    color: '#005abb',
    textDecorationLine: 'underline',
    textTransform: 'none',
    padding: 0,
  },
  spacer: {
    flex: 1,
  },
  pollStatus: {
    marginLeft: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
  },
  liveIndicator: {},
  toggleRefresh: {
    fontSize: '9pt',
  },
  logoImage: {
    height: '5vh',
    margin: '10px 15px',
  },
}));

export default styles;
