import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Coordinate } from '_lib/api';
import { IVIMPictrogram } from '_store/api/types';
import { Ivim } from '_store/ivim/ivimsTypes';

export interface CreateIVIMForm {
  countryCode: string;
  providerIdentifier: string;
  iviType: number;
  rsutarget: number[];
  broadcastingFrequency: number;
  detZoneCoordinates: Coordinate[];
  relZoneCoordinates: Coordinate[];
  referencePoint: Coordinate;
  serviceCategoryCode: string;
  serviceSubCategoryCode: string;
  pictogramText: string;
  pictogramNature: number;
  pictogramSerialNumber: number;
  text: string;
  extratext: string;
  language: string;
  validto: Date;
  validfrom: Date;
  roadSegmentId?: string;
}

const initialState: CreateIVIMForm = {
  countryCode: null,
  providerIdentifier: null,
  iviType: null,
  rsutarget: [],
  broadcastingFrequency: null,
  relZoneCoordinates: null,
  detZoneCoordinates: null,
  referencePoint: null,
  serviceCategoryCode: null,
  serviceSubCategoryCode: null,
  pictogramText: null,
  pictogramNature: null,
  pictogramSerialNumber: null,
  text: null,
  extratext: null,
  language: null,
  validto: null,
  validfrom: null,
  roadSegmentId: null,
};

const ivimFormReducer = createSlice({
  name: 'create-ivim',
  initialState,
  reducers: {
    serviceCountryCodeChanged(state, action: PayloadAction<string>) {
      state.countryCode = action.payload;
    },
    serviceCountryIDChanged(state, action: PayloadAction<string>) {
      state.providerIdentifier = action.payload;
    },
    rsuChanged(state, action: PayloadAction<number[]>) {
      state.rsutarget = action.payload;
    },
    relzoneChanged(state, action: PayloadAction<Coordinate[]>) {
      state.relZoneCoordinates = action.payload;
    },
    textChanged(state, action: PayloadAction<string>) {
      state.text = action.payload;
    },
    extraTextChanged(state, action: PayloadAction<string>) {
      state.extratext = action.payload;
    },
    languageTextChanged(state, action: PayloadAction<string>) {
      state.language = action.payload;
    },
    detzoneChanged(state, action: PayloadAction<Coordinate[]>) {
      state.detZoneCoordinates = action.payload;
    },
    referencePointChanged(state, action: PayloadAction<Coordinate>) {
      state.referencePoint = action.payload;
    },
    pictogramChanged(state, action: PayloadAction<IVIMPictrogram>) {
      const { description, pictogramCategoryCode, pictogramSerialNumber } = action.payload;
      state.pictogramText = description;
      state.pictogramNature = pictogramCategoryCode;
      state.pictogramSerialNumber = pictogramSerialNumber;
    },
    pictogramTextChanged(state, action: PayloadAction<string>) {
      state.pictogramText = action.payload;
    },
    validFromChanged(state, action: PayloadAction<Date>) {
      state.validfrom = action.payload;
    },
    validToChanged(state, action: PayloadAction<Date>) {
      state.validto = action.payload;
    },
    broadcastingFrequencyChanged(state, action: PayloadAction<number>) {
      state.broadcastingFrequency = action.payload;
    },
    typeChanged(state, action: PayloadAction<number>) {
      state.iviType = action.payload;
    },
    serviceCategoryCodeChanged(state, action: PayloadAction<string>) {
      if (action.payload !== state.serviceCategoryCode) {
        // Cleanup dependant fields
        state.serviceSubCategoryCode = null;
        state.pictogramText = null;
      }
      state.serviceCategoryCode = action.payload;
    },
    serviceSubCategoryCodeChanged(state, action: PayloadAction<string>) {
      if (action.payload !== state.serviceSubCategoryCode) {
        // Cleanup dependant fields
        state.pictogramText = null;
      }
      state.serviceSubCategoryCode = action.payload;
    },
    roadSegmentIdChanged(state, action: PayloadAction<string>) {
      state.roadSegmentId = action.payload;
    },
    resetForm() {
      return initialState;
    },
    prefillForm(_state, action: PayloadAction<Ivim>) {
      const ivim = action.payload;
      return {
        ...initialState,
        sequenceNumber: ivim.sequenceNumber,
        iviType: ivim.iviType,
        serviceCategoryCode: ivim.serviceCategoryCode,
        serviceSubCategoryCode: ivim.serviceSubCategoryCode,
        pictogramNature: ivim.pictogramNature,
        pictogramSerialNumber: ivim.pictogramSerialNumber,
        text: ivim.text[0],
        extratext: ivim.text[1],
        language: ivim.language,
        countryCode: ivim.countryCode,
        providerIdentifier: ivim.providerIdentifier,
        direction: ivim.direction,
        broadcastingFrequency: ivim.broadcastingFrequency,
        detZoneCoordinates: ivim.detZoneCoordinates.coordinates,
        relZoneCoordinates: ivim.relZoneCoordinates.coordinates,
        roadSegmentId: ivim.roadSegmentId,
        validfrom: new Date(ivim.starts),
        validto: new Date(ivim.ends),
        referencePoint: ivim.referencePoint.coordinates,
      };
    },
  },
});

export default ivimFormReducer;
