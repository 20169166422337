import { useLocalization } from '@fluent/react';
import { Box, CardContent, Typography } from '@material-ui/core';
import React, { FC, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { camsSelector, loadingSelector } from '_store/vehicles/selectors';
import { events } from '../../../components/icons/utils/cam';
import styles from './styles';

interface CamLayoutProps {
  id: string;
}

const CamLayout: FC<CamLayoutProps> = ({ id }) => {
  const classes = styles();
  const { l10n } = useLocalization();
  const cams = useSelector(camsSelector);
  const isCamsLoading = useSelector(loadingSelector);

  const persistentVehicleRole = useRef<string>('---');

  const cam = cams.find((cam) => cam.id === id);

  useEffect(() => {
    if (cam?.vehicleRole && persistentVehicleRole.current === '---') {
      persistentVehicleRole.current = cam.vehicleRole;
    }
  }, [cam]);

  if (isCamsLoading) {
    return <FontAwesomeIcon icon={faSpinner} spin style={{ marginLeft: 10 }} />;
  }

  if (!cam) {
    return (
      <CardContent className={classes.cardContent}>
        <Box>
          <Typography
            variant="h6"
            className={[classes.titleBoxStyle, classes.titleBoxCamStyle].join(' ')}
          >
            {l10n.getString('forms-info-box-cam-not-found')}
          </Typography>
        </Box>
      </CardContent>
    );
  }

  const speedValueConverted = Math.round(cam.speedValue * 0.036);
  const stationTypeText = events[cam.stationType]?.text || 'unknown';

  return (
    <CardContent className={classes.cardContent}>
      <Box className={classes.boxTitleStyle}>
        <Typography
          variant="h6"
          className={[classes.titleBoxStyle, classes.titleBoxCamStyle].join(' ')}
        >
          ID: {cam.id}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2" className={classes.textStyle}>
          {l10n.getString('forms-info-box-station-id')}: {cam.stationId}
        </Typography>
        <Typography variant="body2" className={classes.textStyle}>
          {l10n.getString('forms-info-box-speed')}:{' '}
          {l10n.getString('forms-info-box-speed-fmt', { speed: speedValueConverted })}
        </Typography>
        <Typography variant="body2" className={classes.textStyle}>
          {l10n.getString('forms-info-box-station-type')}:{' '}
          {l10n.getString(`types-stationTypes-${stationTypeText}`)}
        </Typography>
        <Typography variant="body2" className={classes.textStyle}>
          {l10n.getString('forms-info-box-vehicle-role')}: {persistentVehicleRole.current}
        </Typography>
      </Box>
    </CardContent>
  );
};

export default React.memo(CamLayout);
