import { RSU } from '_lib/api';
import L, { Map } from 'leaflet';
import React, { FC, ReactElement } from 'react';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { v4 as uuidv4 } from 'uuid';

import { RsuStatusCode } from '_store/devices/devicesTypes';
import { iconSizePx, rsuStatusIcon } from '../utils';
import RsuMarker from './markers/RsuMarker';

const Devices: FC<{ devices: RSU[]; mapRef: Map }> = ({ devices, mapRef }): ReactElement => {
  const uniqueKey = uuidv4();
  const iconSize = new L.Point(iconSizePx, iconSizePx);
  const iconAnchor = new L.Point(iconSize.x / 2, iconSize.y);

  return (
    <MarkerClusterGroup
      key={`${uniqueKey}_devices`}
      maxClusterRadius={160}
      disableClusteringAtZoom={13}
      iconCreateFunction={(cluster) => {
        const markers = cluster.getAllChildMarkers();

        let iconColor = RsuStatusCode.UP;
        const hasDeadMarker = markers.some((marker) => marker.getAttribution() === 'dead');

        if (hasDeadMarker) {
          iconColor = RsuStatusCode.UNKNOWN;
        }

        const iconUrl = rsuStatusIcon(iconColor);
        const count = markers.length;

        const digits = count.toString().length;

        const html = `
          <div class="cluster-icon-container">
            <img src="${iconUrl}" class="cluster-icon" />
            <div class="cluster-count" data-digits="${digits}">${count}</div>
          </div>
        `;

        return L.divIcon({
          html,
          className: 'marker-event-unselected',
          iconSize: iconSize,
          iconAnchor: iconAnchor,
        });
      }}
    >
      {devices.map((device, key) => (
        <RsuMarker key={`${uniqueKey}${key}`} device={device} mapRef={mapRef} />
      ))}
    </MarkerClusterGroup>
  );
};

export default React.memo(Devices);
