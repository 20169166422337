import fetchAPI from '_lib/api/fetchWrapper';
import apiRoute from '_lib/api/paths';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { put } from 'typed-redux-saga';

import roadsReducers from './roadsReducers';
import { Road } from './roadsTypes';

export function* roadsRequest(_action: PayloadAction<void>) {
  try {
    const response = yield fetchAPI(apiRoute.roads);
    yield put(roadsReducers.actions.roadsResponse(response.results));
    return response as Road[];
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('ERROR roadsRequest >>>>>>> ', e);
  }
  return undefined;
}

export const routesSagas = createSliceSaga({
  name: 'roads',
  caseSagas: {
    roadsRequest,
  },
  sagaType: SagaType.Watch,
});
