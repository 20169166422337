import { useLocalization } from '@fluent/react';
import { faCar, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, TextField, Tooltip } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { Obu } from '_lib/api';
import React, { useCallback, useState } from 'react';
import styles from './styles';
import _ from 'lodash';

interface ObuFormProps {
  obuList: Obu[];
  onObusChange: (obus: Obu[]) => void;
}

export const ObuForm = ({ obuList, onObusChange }: ObuFormProps) => {
  const classes = styles();
  const { l10n } = useLocalization();

  const [obus, setObus] = useState<Obu[]>(obuList);

  const debouncedOnObusChange = useCallback(
    _.debounce((updatedObus: Obu[]) => {
      onObusChange(updatedObus);
    }, 500),
    [],
  );

  const updateObu = (id: string, field: keyof Obu, value: string) => {
    const updatedObus = obus.map((obu) => (obu.id === id ? { ...obu, [field]: value } : obu));
    setObus(updatedObus);
    debouncedOnObusChange(updatedObus);
  };

  const addObu = () => {
    const newObu: Obu = { id: '', name: '' };
    const updatedObus = [...obus, newObu];
    setObus(updatedObus);
  };

  const removeObu = (id: string) => {
    const updatedObus = obus.filter((obu) => obu.id !== id);
    setObus(updatedObus);
    onObusChange(updatedObus);
  };

  return (
    <>
      {obus.map((obu, key) => (
        <Box key={key} mb={2} className={classes.box}>
          <TextField
            id={`obu-name-${key}`}
            label={l10n.getString('forms-settings-obu-name')}
            type="text"
            value={obu.name}
            onChange={(e) => updateObu(obu.id, 'name', e.target.value)}
            style={{ marginRight: 16 }}
          />
          <TextField
            id={`obu-id-${key}`}
            label={l10n.getString('forms-settings-obu-id')}
            type="text"
            value={obu.id}
            onChange={(e) => updateObu(obu.id, 'id', e.target.value)}
            style={{ marginRight: 16 }}
          />
          <Tooltip title={l10n.getString('forms-settings-remove-obu')}>
            <FontAwesomeIcon
              icon={faTrash}
              style={{
                backgroundColor: red[400],
              }}
              className={classes.iconButton}
              size="xs"
              onClick={() => removeObu(obu.id)}
            />
          </Tooltip>
        </Box>
      ))}
      <Button
        variant="outlined"
        className={classes.addNewButton}
        endIcon={<FontAwesomeIcon icon={faCar} size="xs" />}
        onClick={addObu}
        size="small"
      >
        {l10n.getString('forms-settings-add-obu')}
      </Button>
    </>
  );
};
