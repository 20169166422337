import { useLocalization } from '@fluent/react';
import { Button, CardContent, TextField, Tooltip } from '@material-ui/core';
import { DistributionKind, NewRSU } from '_lib/api';
import { RootState, useAppDispatch } from '_store';
import { devicesSagas } from '_store/devices/sagas';
import { devicesSelector } from '_store/devices/selectors';
import manageRsuFormReducer, { ManageRsuForm } from '_store/forms/manage-rsu';
import { roadsSelector, selectedRoadSegmentIdsSelector } from '_store/roads/selectors';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import SelectItem from '../../items/SelectItem';
import { toLatLng } from '../../routes/MapView/utils';
import styles from './styles';
import { CrudTypeEnum, RsuManageType } from './types';

// TODO Replace this with a proper validation library (when we have more time)
const isTextValueValid = (value?: string) =>
  value !== undefined && value !== null && value.length > 0;

const isNumberValid = (number: number) =>
  number !== undefined && number !== null && !Number.isNaN(number);

const isLatitude = (lat: number) =>
  isNumberValid(lat) && Number.isFinite(lat) && Math.abs(lat) <= 90;

const isLongitude = (lng: number) =>
  isNumberValid(lng) && Number.isFinite(lng) && Math.abs(lng) <= 180;

const isFormValid = (form: ManageRsuForm): boolean =>
  isTextValueValid(form.name) &&
  isTextValueValid(form.distributionHost) &&
  isNumberValid(form.distributionPort) &&
  isLatitude(form.latitude) &&
  isLongitude(form.longitude);
const RsuManage: FC<RsuManageType> = ({ id, type }) => {
  const { l10n } = useLocalization();
  const classes = styles();
  const dispatch = useAppDispatch();
  const [touched, setTouched] = useState({
    name: false,
    roadSegmentId: false,
    distributionHost: false,
    distributionPort: false,
    latitude: false,
    longitude: false,
    direction: false,
    type: false,
    km: false,
  });

  const roads = useSelector(roadsSelector);
  const devices = useSelector(devicesSelector);
  const mapRef = useSelector((state: RootState) => state.application.mapRef);
  const selectedRoadSegmentIds: string[] = useSelector(selectedRoadSegmentIdsSelector);
  const formState = useSelector((state: RootState) => state.forms['manage-rsu']);

  const { busy } = useSelector((state: RootState) => state.application.activeDialog);

  useEffect(() => {
    if (id) {
      const device = devices.find((dev) => dev.id === id);
      if (device) {
        dispatch(manageRsuFormReducer.actions.prefillForm(device));
      }
    }
  }, [id]);

  const submitForm = () => {
    const device: NewRSU = {
      distribution: {
        kind: DistributionKind.UDP,
        host: formState.distributionHost,
        port: formState.distributionPort,
      },
      location: {
        type: 'Point',
        coordinates: [formState.longitude, formState.latitude],
      },
      name: formState.name,
      roadSegmentId:
        formState.roadSegmentId ??
        (selectedRoadSegmentIds && selectedRoadSegmentIds.length === 1
          ? selectedRoadSegmentIds[0]
          : null),
      id: type === CrudTypeEnum.NEW_RSU ? undefined : formState.id,
      direction: formState.direction,
      type: formState.objectType,
      km: formState.km,
    };
    switch (type) {
      case CrudTypeEnum.NEW_RSU:
        dispatch(devicesSagas.actions.createRSU(device));
        break;
      case CrudTypeEnum.UPDATE_RSU:
        dispatch(
          devicesSagas.actions.updateRSU({
            updatedRSU: device,
            id: device.id,
          }),
        );
        break;
      default:
        break;
    }
    mapRef.panTo(toLatLng(device.location.coordinates));
  };

  const onChangeName = useCallback((ev) => {
    if (!touched.name) {
      setTouched({ ...touched, name: true });
    }
    dispatch(manageRsuFormReducer.actions.nameChanged(ev.target.value));
  }, []);
  const onChangeIp = useCallback((ev) => {
    if (!touched.distributionHost) {
      setTouched({ ...touched, distributionHost: true });
    }
    dispatch(manageRsuFormReducer.actions.ipChanged(ev.target.value));
  }, []);
  const onChangePort = useCallback((ev) => {
    if (!touched.distributionPort) {
      setTouched({ ...touched, distributionPort: true });
    }
    dispatch(manageRsuFormReducer.actions.portChanged(parseInt(ev.target.value, 10)));
  }, []);
  const createChangeHandler = (
    field: string,
    validator: (num: number) => boolean,
    actionCreator: (num: number) => any,
  ) =>
    useCallback((ev) => {
      const number = parseFloat(ev.target.value.replace(/,/g, '.'));
      if (validator(number) || !ev.target.value) {
        if (!touched[field]) {
          setTouched({ ...touched, [field]: true });
        }
        return dispatch(actionCreator(number));
      }
      return null;
    }, []);

  const onChangeLatitude = createChangeHandler(
    'latitude',
    isLatitude,
    manageRsuFormReducer.actions.latitudeChanged,
  );
  const onChangeLongitude = createChangeHandler(
    'longitude',
    isLongitude,
    manageRsuFormReducer.actions.longitudeChanged,
  );
  const onChangeRoadSegmentId = useCallback((ev) => {
    dispatch(manageRsuFormReducer.actions.roadSegmentIdChanged(ev.target.value));
  }, []);

  return (
    <CardContent className={classes.cardContent}>
      <Tooltip title={l10n.getString('forms-rsu-name')} placement="right">
        <TextField
          id="name-rsu"
          label={l10n.getString('forms-rsu-name')}
          InputLabelProps={{ style: { fontSize: 12 } }}
          value={formState.name ? formState.name?.toString() : ''}
          disabled={busy}
          onChange={onChangeName}
          error={touched.name && !isTextValueValid(formState.name)}
        />
      </Tooltip>
      <Tooltip title={l10n.getString('forms-rsu-ip-rsu')} placement="right">
        <TextField
          id="ip-rsu"
          label={l10n.getString('forms-rsu-ip-rsu')}
          InputLabelProps={{ style: { fontSize: 12 } }}
          value={formState.distributionHost ?? ''}
          disabled={busy}
          onChange={onChangeIp}
          className={classes.inputStyle}
          error={touched.distributionHost && !isTextValueValid(formState.distributionHost)}
        />
      </Tooltip>
      <Tooltip title={l10n.getString('forms-rsu-port-rsu')} placement="right">
        <TextField
          id="port-rsu"
          label={l10n.getString('forms-rsu-port-rsu')}
          InputLabelProps={{ style: { fontSize: 12 } }}
          value={isNumberValid(formState.distributionPort) ? formState.distributionPort : ''}
          type="number"
          disabled={busy}
          onChange={onChangePort}
          className={classes.inputStyle}
          error={touched.distributionPort && !isNumberValid(formState.distributionPort)}
        />
      </Tooltip>
      <Tooltip title={l10n.getString('forms-rsu-lat')} placement="right">
        <TextField
          id="latitude"
          label={l10n.getString('forms-rsu-lat')}
          InputLabelProps={{ style: { fontSize: 12 } }}
          type="number"
          value={isNumberValid(formState.latitude) ? formState.latitude : ''}
          onChange={onChangeLatitude}
          className={classes.inputStyle}
          error={touched.latitude && !isLatitude(formState.latitude)}
        />
      </Tooltip>
      <Tooltip title={l10n.getString('forms-rsu-long')} placement="right">
        <TextField
          id="longitude"
          label={l10n.getString('forms-rsu-long')}
          InputLabelProps={{ style: { fontSize: 12 } }}
          type="number"
          value={isNumberValid(formState.longitude) ? formState.longitude : ''}
          onChange={onChangeLongitude}
          className={classes.inputStyle}
          error={touched.longitude && !isLongitude(formState.longitude)}
        />
      </Tooltip>

      <SelectItem
        text={l10n.getString('forms-rsu-direction')}
        options={[
          { value: 'north', label: l10n.getString('forms-rsu-direction-north') },
          { value: 'south', label: l10n.getString('forms-rsu-direction-south') },
          { value: 'east', label: l10n.getString('forms-rsu-direction-east') },
          { value: 'west', label: l10n.getString('forms-rsu-direction-west') },
        ]}
        value={formState.direction ?? ''}
        disabled={busy}
        onChange={(ev) => dispatch(manageRsuFormReducer.actions.directionChanged(ev.target.value))}
        tooltip={l10n.getString('forms-rsu-direction-tp')}
      />

      <Tooltip title={l10n.getString('forms-rsu-km-tp')} placement="right">
        <TextField
          id="text"
          label={l10n.getString('forms-rsu-km')}
          InputLabelProps={{ style: { fontSize: 13 } }}
          value={formState.km ?? ''}
          disabled={busy}
          onChange={(ev) => dispatch(manageRsuFormReducer.actions.kmChanged(ev.target.value))}
          style={{ marginTop: 10 }}
        />
      </Tooltip>

      <Tooltip title={l10n.getString('forms-rsu-type-tp')} placement="right">
        <TextField
          id="text"
          label={l10n.getString('forms-rsu-type')}
          InputLabelProps={{ style: { fontSize: 13 } }}
          value={formState.objectType ?? ''}
          disabled={busy}
          onChange={(ev) =>
            dispatch(manageRsuFormReducer.actions.objectTypeChanged(ev.target.value))
          }
          inputProps={{ maxLength: 8 }}
          style={{ marginTop: 10, marginBottom: 10 }}
        />
      </Tooltip>

      <SelectItem
        text={l10n.getString('forms-rsu-road')}
        options={roads.map((road) => ({ label: road.name, value: road.id }))}
        value={
          type === CrudTypeEnum.NEW_RSU
            ? formState.roadSegmentId ??
              (selectedRoadSegmentIds && selectedRoadSegmentIds.length == 1
                ? selectedRoadSegmentIds[0]
                : '')
            : ''
        }
        onChange={onChangeRoadSegmentId}
        tooltip={l10n.getString('forms-rsu-road')}
      />

      <Button
        variant="outlined"
        style={{
          marginTop: 20,
        }}
        disabled={!isFormValid(formState) || busy}
        onClick={submitForm}
      >
        {id != null ? l10n.getString('forms-rsu-update-rsu') : l10n.getString('forms-rsu-add-new')}
      </Button>
    </CardContent>
  );
};

export default React.memo(RsuManage);
