import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  button: {
    background: 'transparent',
    textTransform: 'capitalize',
    alignSelf: 'flex-start',
    fontSize: '0.875rem',
    color: theme.palette.text.primary,
  },
}));

export default styles;
