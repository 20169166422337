import { useLocalization } from '@fluent/react';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { FC, useCallback } from 'react';

import { AlertIcon } from '../../icons';
import styles from './styles';

export type MessageDialogProps = {
  statusMessage: {
    active: boolean;
    typeAction: string;
    typeEvent?: string;
  };
  onCloseDialog: (type: 'cancel' | 'confirm') => void;
};

const MessageDialog: FC<MessageDialogProps> = ({ statusMessage, onCloseDialog }) => {
  const { typeAction, typeEvent } = statusMessage;

  const classes = styles();
  const { l10n } = useLocalization();

  let formTitle = null;
  let formBody = null;
  let formNonCancelButton = null;

  // TODO This should really not be here! Move this data to the prop!
  switch (typeAction) {
    case 'delete':
      formTitle = l10n.getString('forms-warning-mess-want-to-delete', {
        type: typeEvent,
      });
      formBody = l10n.getString('forms-warning-mess-body-content-delete');
      formNonCancelButton = l10n.getString('forms-warning-mess-btn-delete');
      break;

    case 'disable':
      formTitle = l10n.getString('forms-warning-mess-want-to-disable', {
        type: typeEvent,
      });
      formBody = l10n.getString('forms-warning-mess-body-content-disable');
      formNonCancelButton = l10n.getString('forms-warning-mess-btn-disable');
      break;

    case 'enable':
      formTitle = l10n.getString('forms-warning-mess-want-to-enable', {
        type: typeEvent,
      });
      formBody = l10n.getString('forms-warning-mess-body-content-enable');
      formNonCancelButton = l10n.getString('forms-warning-mess-btn-enable');
      break;

    case 'ends':
      formTitle = l10n.getString('forms-warning-mess-want-to-end', {
        type: typeEvent,
      });
      formBody = l10n.getString('forms-warning-mess-body-content-ends');
      formNonCancelButton = l10n.getString('forms-warning-mess-btn-delete');
      break;

    case 'CreateDenm':
      formTitle = l10n.getString('forms-warning-mess-leave-denm');
      formBody = l10n.getString('forms-warning-mess-leave-ivim-denm');
      formNonCancelButton = l10n.getString('forms-warning-mess-btn-delete');
      break;

    case 'CreateIvim':
      formTitle = l10n.getString('forms-warning-mess-leave-ivim');
      formBody = l10n.getString('forms-warning-mess-leave-ivim-denm');
      formNonCancelButton = l10n.getString('forms-warning-mess-btn-delete');
      break;

    default:
      formTitle = l10n.getString('forms-warning-mess-title-page');
      formBody = l10n.getString('forms-warning-mess-body-content-page');
      formNonCancelButton = l10n.getString('forms-warning-mess-btn-page');
  }

  const closeDialog = useCallback(
    (type) => () => {
      onCloseDialog(type);
    },
    [onCloseDialog],
  );

  return (
    <Dialog open={statusMessage.active} aria-labelledby="form-dialog-title">
      <Box className={classes.titleBox}>
        <AlertIcon />
        <DialogTitle id="form-dialog-title">{formTitle}</DialogTitle>
      </Box>
      <DialogContent>
        <DialogContentText>{formBody}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog('cancel')} color="secondary">
          {l10n.getString('forms-warning-mess-btn-cancel')}
        </Button>
        <Button onClick={closeDialog('confirm')} color="primary">
          {formNonCancelButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(MessageDialog);
