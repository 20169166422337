import { useLocalization } from '@fluent/react';
import { Box, Button, CardContent, Divider, Tooltip, Typography } from '@material-ui/core';
import { DialogType } from '_store/application/types';
import React, { FC, useState } from 'react';

import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { faClone, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { blue, red } from '@material-ui/core/colors';
import { Accordion } from '../../../components/items/Accordion';
import { IconMenu } from '../../icons';
import denmIcons from '../../icons/denm';
import MessageDialog from '../../items/MessageDialog';
import { DotComponent } from './DotComponent';
import { EventStatus } from './EventStatus';
import RsuTargetInv from './RSU';
import styles from './styles';

export interface EventLayoutProps {
  onDelete?: () => void;
  onEnds?: () => void;
  header?: string;
  active?: boolean;
  typeMessage?: string;
  rsuList?: string;
  broadcastingFrequency?: string;
  date?: Date;
  type: DialogType.DENM | DialogType.IVIM;
  causeIcon?: string;
  onUpdate?: () => void;
  onDisable?: () => void;
  disabled: boolean;
  onEnable?: () => void;
  onClone?: () => void;
  scheduled?: boolean;
}

const EventLayout: FC<React.PropsWithChildren<EventLayoutProps>> = ({
  header,
  active,
  onDelete,
  onEnds,
  typeMessage,
  children,
  date,
  broadcastingFrequency,
  rsuList,
  type,
  causeIcon,
  onUpdate,
  onDisable,
  disabled,
  onEnable,
  onClone,
  scheduled = false,
}) => {
  const classes = styles();
  const { l10n } = useLocalization();

  const [showMessage, setShowMessage] = useState({
    active: false,
    typeAction: null,
    typeEvent: type,
  });

  const onMessageLeaveConfirmed = (t) => {
    if (t === 'confirm') {
      switch (showMessage.typeAction) {
        case 'delete':
          onDelete();
          break;
        case 'disable':
          onDisable();
          break;
        case 'enable':
          onEnable();
          break;
        case 'ends':
          onEnds();
          break;
        default:
          break;
      }
    }

    setShowMessage((sm) => ({
      ...sm,
      active: false,
    }));
  };

  const onDeleteEvent = () => {
    setShowMessage((sm) => ({
      ...sm,
      typeAction: 'delete',
      active: true,
    }));
  };

  const onDisableEvent = () => {
    setShowMessage((sm) => ({
      ...sm,
      typeAction: 'disable',
      active: true,
    }));
  };

  const onEnableEvent = () => {
    setShowMessage((sm) => ({
      ...sm,
      typeAction: 'enable',
      active: true,
    }));
  };

  const onEndsEvent = () => {
    setShowMessage((sm) => ({
      ...sm,
      typeAction: 'ends',
      active: true,
    }));
  };

  const { statusClass, statusText } = EventStatus({
    active,
    date,
    disabled,
    scheduled,
  });

  return (
    <CardContent className={classes.cardContent}>
      {date ? (
        <Typography variant="caption" className={classes.captionStyle}>
          {date.toLocaleString()}
        </Typography>
      ) : null}

      <Box className={classes.boxTitleStyle}>
        {type === DialogType.DENM && causeIcon ? (
          <img
            src={denmIcons[causeIcon]?.png}
            style={{
              width: 'auto',
              height: '2rem',
              marginRight: '0.5rem',
            }}
          />
        ) : (
          <IconMenu type={type} active={false} />
        )}
        <Typography variant="h6" className={classes.titleBoxStyle}>
          {header}
        </Typography>

        {DotComponent({ status: active, isDisabled: disabled, scheduled })}

        <Typography variant="caption" className={statusClass}>
          {statusText}
        </Typography>
      </Box>

      {typeMessage ? (
        <>
          <Typography variant="body2" className={classes.titleStyles}>
            {l10n.getString('forms-event-event-source')}
          </Typography>

          <Typography variant="caption" className={classes.titleStyles}>
            {l10n.getString(`forms-event-type-${typeMessage}`)}
          </Typography>
        </>
      ) : null}

      <Accordion title={l10n.getString('forms-event-evt-details')} isExpandedByDefault>
        {children}
      </Accordion>

      {rsuList && (
        <>
          <Divider />
          <Accordion title={l10n.getString('forms-event-rsu-target-inv')}>
            <RsuTargetInv rsuList={rsuList} broadcastingFrequency={broadcastingFrequency} />
          </Accordion>
        </>
      )}

      <Box className={classes.buttonsContainer}>
        {active && !disabled && onUpdate ? (
          <Tooltip title={l10n.getString('forms-map-update-btn')}>
            <FontAwesomeIcon
              icon={faPen}
              style={{
                backgroundColor: blue[400],
              }}
              className={classes.iconButton}
              size="xs"
              onClick={onUpdate}
            />
          </Tooltip>
        ) : null}
        {!active && onClone ? (
          <Tooltip title={l10n.getString('forms-map-clone-btn')}>
            <FontAwesomeIcon
              icon={faClone}
              style={{
                backgroundColor: blue[400],
              }}
              className={classes.iconButton}
              size="xs"
              onClick={onClone}
            />
          </Tooltip>
        ) : null}
        {active && onDisable ? (
          <Tooltip
            title={l10n.getString(disabled ? 'forms-map-enable-btn' : 'forms-map-disable-btn')}
          >
            <FontAwesomeIcon
              icon={disabled ? faEye : faEyeSlash}
              style={{
                backgroundColor: blue[400],
              }}
              className={classes.iconButton}
              size="xs"
              onClick={disabled ? onEnableEvent : onDisableEvent}
            />
          </Tooltip>
        ) : null}
        {(active || disabled) && onDelete ? (
          <Tooltip title={l10n.getString('forms-map-delete-btn')}>
            <FontAwesomeIcon
              icon={faTrash}
              style={{
                backgroundColor: red[400],
              }}
              className={classes.iconButton}
              size="xs"
              onClick={onDeleteEvent}
            />
          </Tooltip>
        ) : null}

        {onEnds ? (
          <Button
            variant="outlined"
            className={classes.endsStyle}
            onClick={onEndsEvent}
            disabled={!active}
          >
            {l10n.getString('forms-event-ends-btn')}
          </Button>
        ) : null}
      </Box>

      {showMessage.active ? (
        <MessageDialog statusMessage={showMessage} onCloseDialog={onMessageLeaveConfirmed} />
      ) : null}
    </CardContent>
  );
};

export default React.memo(EventLayout);
