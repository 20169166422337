import { useLocalization } from '@fluent/react';
import { Box, Divider, Typography } from '@material-ui/core';
import { Denm } from '_store/denm/denmsTypes';
import { causesSelector, subCausesByCauseOptionalSelector } from '_store/denm/selectors';
import { useDenmsOptionalCauses } from '_store/denm/utils';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Accordion } from '../../../../../components/items/Accordion';
import styles from './styles';
import useLaneStates from '../../CreateDenm/components/useLaneStates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

interface DenmOptionalDetailProps {
  denm: Denm;
  showManagement: boolean;
  showSituation: boolean;
  showLocation: boolean;
  showAlacarte: boolean;
}

export const DenmOptionalDetail = ({
  denm,
  showManagement,
  showSituation,
  showLocation,
  showAlacarte,
}: DenmOptionalDetailProps) => {
  const classes = styles();
  const { l10n } = useLocalization();
  const denmCauses = useSelector(causesSelector);
  const denmSubCausesOptional = useSelector(subCausesByCauseOptionalSelector);
  const { laneStates } = useLaneStates(denm, true);

  const cause = denmCauses.find(
    (cause) => cause.code === denm.optional?.situation?.linkedCause?.causeCode,
  );
  const subCause = denmSubCausesOptional.find(
    (subCause) => subCause.code === denm.optional?.situation?.linkedCause?.subCauseCode,
  );

  useDenmsOptionalCauses({
    optionalCauseCode: denm.optional?.situation?.linkedCause?.causeCode,
    optionalSubCauseCode: denm.optional?.situation?.linkedCause?.subCauseCode,
  });

  const laneStatuses = [
    { key: 'overtaking', text: l10n.getString('forms-denm-closed-lane-overtaking') },
    { key: 'fast', text: l10n.getString('forms-denm-closed-lane-fast') },
    { key: 'slow', text: l10n.getString('forms-denm-closed-lane-slow') },
    { key: 'emergency', text: l10n.getString('forms-denm-closed-lane-emergency') },
  ];

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      {showManagement && (
        <Accordion title={l10n.getString('forms-denm-optional-management-container')}>
          {denm.optional?.management?.altitude.altitudeValue && (
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              {l10n.getString('forms-denm-altitude-value')}:
              <strong>{denm.optional?.management?.altitude.altitudeValue}</strong> {`\n`}
            </Typography>
          )}
          {denm.optional?.management?.altitude.altitudeConfidence && (
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              {l10n.getString('forms-denm-altitude-confidence')}:
              <strong>{denm.optional?.management?.altitude.altitudeConfidence}</strong> {`\n`}
            </Typography>
          )}
          {denm.optional?.management?.relevanceTrafficDirection && (
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              {l10n.getString('forms-denm-traffic-direction')}:
              <strong>{denm.optional?.management?.relevanceTrafficDirection}</strong> {`\n`}
            </Typography>
          )}
          {denm.optional?.management?.stationType && (
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              {l10n.getString('forms-denm-station-type')}:
              <strong>{denm.optional?.management?.stationType}</strong> {`\n`}
            </Typography>
          )}
        </Accordion>
      )}
      {showSituation && (
        <Accordion title={l10n.getString('forms-denm-optional-situation-container')}>
          {denm.optional?.situation?.informationQuality && (
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              {l10n.getString('forms-denm-information-quality')}:
              <strong>{denm.optional?.situation?.informationQuality}</strong> {`\n`}
            </Typography>
          )}
          {denm.optional?.situation?.linkedCause && (
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              {l10n.getString('forms-denm-linked-cause')}:<strong>{cause?.description}</strong>{' '}
              {`\n`}
            </Typography>
          )}
          {denm.optional?.situation?.linkedCause && (
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              {l10n.getString('forms-denm-linked-subcause')}:
              <strong>{subCause?.description}</strong> {`\n`}
            </Typography>
          )}
        </Accordion>
      )}
      {showLocation && (
        <Accordion title={l10n.getString('forms-denm-optional-location-container')}>
          {denm.optional?.location?.speedValue && (
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              {l10n.getString('forms-denm-speed-value')}:
              <strong>{denm.optional?.location?.speedValue}</strong> {`\n`}
            </Typography>
          )}
          {denm.optional?.location?.headingValue && (
            <Typography
              component={'span'}
              variant={'body2'}
              paragraph
              className={classes.textStyle}
            >
              {l10n.getString('forms-denm-heading-value')}:
              <strong>{denm.optional?.location?.headingValue}</strong> {`\n`}
            </Typography>
          )}
        </Accordion>
      )}
      {showAlacarte && (
        <Accordion title={l10n.getString('forms-denm-optional-alacarte-container')}>
          {denm.optional?.alacarte?.roadWorks.closedLanes && (
            <>
              <Typography
                component={'span'}
                variant={'body2'}
                paragraph
                className={classes.textStyle}
                style={{ color: 'rgba(0, 0, 0, 0.54)' }}
              >
                {l10n.getString('forms-denm-closed-lanes')}
                {`\n`}
              </Typography>
              {laneStatuses.map(({ key, text }) => (
                <Typography
                  key={key}
                  component={'span'}
                  variant={'body2'}
                  paragraph
                  className={classes.textStyle}
                >
                  <strong>
                    {laneStates[key] ? (
                      <FontAwesomeIcon icon={faCheck} size="xs" />
                    ) : (
                      <FontAwesomeIcon icon={faXmark} size="xs" />
                    )}
                  </strong>{' '}
                  {text}
                  {`\n`}
                </Typography>
              ))}
            </>
          )}
          {denm.optional?.alacarte?.roadWorks.referenceDenms &&
            denm.optional?.alacarte?.roadWorks.referenceDenms.length > 0 && (
              <>
                <Divider style={{ margin: '16px 0' }} />
                <Typography
                  component={'span'}
                  variant={'body2'}
                  paragraph
                  className={classes.textStyle}
                  style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                >
                  {l10n.getString('forms-denm-reference-denms')}
                  {`\n`}
                </Typography>
                {denm.optional?.alacarte?.roadWorks.referenceDenms.map((denm, index) => (
                  <Fragment key={index}>
                    <Box className={classes.referenceDenmBox}>
                      <Typography
                        component={'span'}
                        variant={'body2'}
                        paragraph
                        className={classes.textStyle}
                      >
                        {l10n.getString('forms-denm-originating-station-id')}:
                        <strong>{denm.originatingStationID}</strong> {`\n`}
                      </Typography>
                      <Typography
                        component={'span'}
                        variant={'body2'}
                        paragraph
                        className={classes.textStyle}
                      >
                        {l10n.getString('forms-denm-sequence-number')}:
                        <strong>{denm.sequenceNumber}</strong> {`\n`}
                      </Typography>
                    </Box>
                  </Fragment>
                ))}
              </>
            )}
        </Accordion>
      )}
    </Box>
  );
};
