import { Causes, SubCauses } from '../../../../store/denm/denmsTypes';

const iconMap = [
  { cause: 'Traffic Condition', subcause: 'Unknown', icon: 'Traffic Condition_1.png' },
  {
    cause: 'Traffic Condition',
    subcause: 'Increased Volume Of Traffic',
    icon: 'Traffic Condition_1.png',
  },
  {
    cause: 'Traffic Condition',
    subcause: 'Traffic Jam Slowly Increasing',
    icon: 'Traffic Condition_1.png',
  },
  {
    cause: 'Traffic Condition',
    subcause: 'Traffic Jam Increasing',
    icon: 'Traffic Condition_1.png',
  },
  {
    cause: 'Traffic Condition',
    subcause: 'Traffic Jam Strongly Increasing',
    icon: 'Traffic Condition_1.png',
  },
  { cause: 'Traffic Condition', subcause: 'Traffic Stationary', icon: 'Traffic Condition_1.png' },
  {
    cause: 'Traffic Condition',
    subcause: 'Traffic Jam Slightly Decreasing',
    icon: 'Traffic Condition_1.png',
  },
  {
    cause: 'Traffic Condition',
    subcause: 'Traffic Jam Decreasing',
    icon: 'Traffic Condition_1.png',
  },
  {
    cause: 'Traffic Condition',
    subcause: 'Traffic Jam Stringly Decreasing',
    icon: 'Traffic Condition_1.png',
  },
  { cause: 'Accident', subcause: 'Unknown', icon: 'Accident_2.png' },
  { cause: 'Accident', subcause: 'Multi Vehicle Accident', icon: 'Accident_2.png' },
  { cause: 'Accident', subcause: 'Heavy Accident', icon: 'Accident_2.png' },
  { cause: 'Accident', subcause: 'Accident Involving Lorry', icon: 'Accident_2.png' },
  { cause: 'Accident', subcause: 'Accident Involving Bus', icon: 'Accident_2.png' },
  { cause: 'Accident', subcause: 'Accident Involving Hazardous Materials', icon: 'Accident_2.png' },
  { cause: 'Accident', subcause: 'Accident On Opposite Lane', icon: 'Accident_2.png' },
  { cause: 'Accident', subcause: 'Unsecured Accident', icon: 'Accident_2.png' },
  { cause: 'Accident', subcause: 'Assistance Requested', icon: 'Accident_2.png' },
  { cause: 'Road Works', subcause: 'Unknown', icon: 'Road Works_3.png' },
  { cause: 'Road Works', subcause: 'Major Road Works', icon: 'Road Works_3.png' },
  { cause: 'Road Works', subcause: 'Road Marking Work', icon: 'Road Works_3.png' },
  { cause: 'Road Works', subcause: 'Slow Moving Road Maintenance', icon: 'Road Works_3.png' },
  { cause: 'Road Works', subcause: 'Short Term Stationary Roadworks', icon: 'Road Works_3.png' },
  { cause: 'Road Works', subcause: 'Street Cleaning', icon: 'Road Works_3.png' },
  { cause: 'Road Works', subcause: 'Winter Service', icon: 'Road Works_3.png' },
  {
    cause: 'Adverse Weather Condition - Adhesion',
    subcause: 'Unknown',
    icon: 'Adverse Weather Condition_6.png',
  },
  {
    cause: 'Adverse Weather Condition - Adhesion',
    subcause: 'Heavy Frost On Road',
    icon: 'Adverse Weather Condition_6.png',
  },
  {
    cause: 'Adverse Weather Condition - Adhesion',
    subcause: 'Fuel On Road',
    icon: 'Adverse Weather Condition_6.png',
  },
  {
    cause: 'Adverse Weather Condition - Adhesion',
    subcause: 'Mud On Road',
    icon: 'Adverse Weather Condition_6.png',
  },
  {
    cause: 'Adverse Weather Condition - Adhesion',
    subcause: 'Snow On Road',
    icon: 'Adverse Weather Condition_6.png',
  },
  {
    cause: 'Adverse Weather Condition - Adhesion',
    subcause: 'Ice On Road',
    icon: 'Adverse Weather Condition_6.png',
  },
  {
    cause: 'Adverse Weather Condition - Adhesion',
    subcause: 'Black Ice On Road',
    icon: 'Adverse Weather Condition_6.png',
  },
  {
    cause: 'Adverse Weather Condition - Adhesion',
    subcause: 'Oil On Road',
    icon: 'Adverse Weather Condition_6.png',
  },
  {
    cause: 'Adverse Weather Condition - Adhesion',
    subcause: 'Loose Chippings',
    icon: 'Adverse Weather Condition_6.png',
  },
  {
    cause: 'Adverse Weather Condition - Adhesion',
    subcause: 'Instant Black Ice',
    icon: 'Adverse Weather Condition_6.png',
  },
  {
    cause: 'Adverse Weather Condition - Adhesion',
    subcause: 'Roads Salted',
    icon: 'Adverse Weather Condition_6.png',
  },
  {
    cause: 'Hazardous Location - Surface Condition',
    subcause: 'Unknown',
    icon: 'Hazardous Location_9.png',
  },
  {
    cause: 'Hazardous Location - Surface Condition',
    subcause: 'Rock Falls',
    icon: 'Hazardous Location_9.png',
  },
  {
    cause: 'Hazardous Location - Surface Condition',
    subcause: 'Earthquake Damage',
    icon: 'Hazardous Location_9.png',
  },
  {
    cause: 'Hazardous Location - Surface Condition',
    subcause: 'Sewer Collapse',
    icon: 'Hazardous Location_9.png',
  },
  {
    cause: 'Hazardous Location - Surface Condition',
    subcause: 'Subsidence',
    icon: 'Hazardous Location_9.png',
  },
  {
    cause: 'Hazardous Location - Surface Condition',
    subcause: 'Snow Drifts',
    icon: 'Hazardous Location_9.png',
  },
  {
    cause: 'Hazardous Location - Surface Condition',
    subcause: 'Storm Damage',
    icon: 'Hazardous Location_9.png',
  },
  {
    cause: 'Hazardous Location - Surface Condition',
    subcause: 'Burst Pipe',
    icon: 'Hazardous Location_9.png',
  },
  {
    cause: 'Hazardous Location - Surface Condition',
    subcause: 'Volcano Eruption',
    icon: 'Hazardous Location_9.png',
  },
  {
    cause: 'Hazardous Location - Surface Condition',
    subcause: 'Falling Ice',
    icon: 'Hazardous Location_9.png',
  },
  {
    cause: 'Hazardous Location - Obstacle On The Road',
    subcause: 'Unknown',
    icon: 'Obstacle On The Road_10.png',
  },
  {
    cause: 'Hazardous Location - Obstacle On The Road',
    subcause: 'Shed Load',
    icon: 'Obstacle On The Road_10.png',
  },
  {
    cause: 'Hazardous Location - Obstacle On The Road',
    subcause: 'Parts Of Vehicles',
    icon: 'Obstacle On The Road_10.png',
  },
  {
    cause: 'Hazardous Location - Obstacle On The Road',
    subcause: 'Parts Of Tyres',
    icon: 'Obstacle On The Road_10.png',
  },
  {
    cause: 'Hazardous Location - Obstacle On The Road',
    subcause: 'Big Objects',
    icon: 'Obstacle On The Road_10.png',
  },
  {
    cause: 'Hazardous Location - Obstacle On The Road',
    subcause: 'Fallen Trees',
    icon: 'Obstacle On The Road_10.png',
  },
  {
    cause: 'Hazardous Location - Obstacle On The Road',
    subcause: 'Hub Caps',
    icon: 'Obstacle On The Road_10.png',
  },
  {
    cause: 'Hazardous Location - Obstacle On The Road',
    subcause: 'Waiting Vehicles',
    icon: 'Obstacle On The Road_10.png',
  },
  {
    cause: 'Hazardous Location - Animal On The Road',
    subcause: 'Unknown',
    icon: 'Animal On The Road_11.png',
  },
  {
    cause: 'Hazardous Location - Animal On The Road',
    subcause: 'Wild Animals',
    icon: 'Animal On The Road_11.png',
  },
  {
    cause: 'Hazardous Location - Animal On The Road',
    subcause: 'Herd Of Animals',
    icon: 'Animal On The Road_11.png',
  },
  {
    cause: 'Hazardous Location - Animal On The Road',
    subcause: 'Small Animals',
    icon: 'Animal On The Road_11.png',
  },
  {
    cause: 'Hazardous Location - Animal On The Road',
    subcause: 'Large Animals',
    icon: 'Animal On The Road_11.png',
  },
  {
    cause: 'Human Presence On The Road',
    subcause: 'Unknown',
    icon: 'Human Presence On The Road_12.png',
  },
  {
    cause: 'Human Presence On The Road',
    subcause: 'Children On Roadway',
    icon: 'Human Presence On The Road_12.png',
  },
  {
    cause: 'Human Presence On The Road',
    subcause: 'Cyclist On Roadway',
    icon: 'Human Presence On The Road_12.png',
  },
  {
    cause: 'Human Presence On The Road',
    subcause: 'Motorcyclist On Roadway',
    icon: 'Human Presence On The Road_12.png',
  },
  { cause: 'Wrong Way Driving', subcause: 'Unknown', icon: 'Wrong Way Driving_14.png' },
  { cause: 'Wrong Way Driving', subcause: 'Wrong Lane', icon: 'Wrong Way Driving_14.png' },
  { cause: 'Wrong Way Driving', subcause: 'Wrong Direction', icon: 'Wrong Way Driving_14.png' },
  {
    cause: 'Rescue And Recovery Work In Progress',
    subcause: 'Unknown',
    icon: 'Rescue And Recovery Work In Progress_15.png',
  },
  {
    cause: 'Rescue And Recovery Work In Progress',
    subcause: 'Emergency Vehicles',
    icon: 'Rescue And Recovery Work In Progress_15.png',
  },
  {
    cause: 'Rescue And Recovery Work In Progress',
    subcause: 'Rescue Helicopter Landing',
    icon: 'Rescue And Recovery Work In Progress_15.png',
  },
  {
    cause: 'Rescue And Recovery Work In Progress',
    subcause: 'Police Activity Ongoing',
    icon: 'Rescue And Recovery Work In Progress_15.png',
  },
  {
    cause: 'Rescue And Recovery Work In Progress',
    subcause: 'Medical Emergency Ongoing',
    icon: 'Rescue And Recovery Work In Progress_15.png',
  },
  {
    cause: 'Rescue And Recovery Work In Progress',
    subcause: 'Child Abduction In Progress',
    icon: 'Rescue And Recovery Work In Progress_15.png',
  },
  {
    cause: 'Adverse Weather Condition - Extreme Weather Condition',
    subcause: 'Unknown',
    icon: 'Adverse Weather Condition_17.png',
  },
  {
    cause: 'Adverse Weather Condition - Extreme Weather Condition',
    subcause: 'Strong Winds',
    icon: 'Adverse Weather Condition_17.png',
  },
  {
    cause: 'Adverse Weather Condition - Extreme Weather Condition',
    subcause: 'Damaging Hail',
    icon: 'Adverse Weather Condition_17.png',
  },
  {
    cause: 'Adverse Weather Condition - Extreme Weather Condition',
    subcause: 'Hurricane',
    icon: 'Adverse Weather Condition_17.png',
  },
  {
    cause: 'Adverse Weather Condition - Extreme Weather Condition',
    subcause: 'Thunder Storm',
    icon: 'Adverse Weather Condition_17.png',
  },
  {
    cause: 'Adverse Weather Condition - Extreme Weather Condition',
    subcause: 'Tornado',
    icon: 'Adverse Weather Condition_17.png',
  },
  {
    cause: 'Adverse Weather Condition - Extreme Weather Condition',
    subcause: 'Blizzard',
    icon: 'Adverse Weather Condition_17.png',
  },
  {
    cause: 'Adverse Weather Condition - Visibility',
    subcause: 'Unknown',
    icon: 'Adverse Weather Condition_18.png',
  },
  {
    cause: 'Adverse Weather Condition - Visibility',
    subcause: 'Fog',
    icon: 'Adverse Weather Condition_18.png',
  },
  {
    cause: 'Adverse Weather Condition - Visibility',
    subcause: 'Smoke',
    icon: 'Adverse Weather Condition_18.png',
  },
  {
    cause: 'Adverse Weather Condition - Visibility',
    subcause: 'Heavy Snow Fall',
    icon: 'Adverse Weather Condition_18.png',
  },
  {
    cause: 'Adverse Weather Condition - Visibility',
    subcause: 'Heavy Rain',
    icon: 'Adverse Weather Condition_18.png',
  },
  {
    cause: 'Adverse Weather Condition - Visibility',
    subcause: 'Heavy Hail',
    icon: 'Adverse Weather Condition_18.png',
  },
  {
    cause: 'Adverse Weather Condition - Visibility',
    subcause: 'Low Sun Glare',
    icon: 'Adverse Weather Condition_18.png',
  },
  {
    cause: 'Adverse Weather Condition - Visibility',
    subcause: 'Sand Storms',
    icon: 'Adverse Weather Condition_18.png',
  },
  {
    cause: 'Adverse Weather Condition - Visibility',
    subcause: 'Swarms Of Insects',
    icon: 'Adverse Weather Condition_18.png',
  },
  {
    cause: 'Adverse Weather Condition - Precipitation',
    subcause: 'Unknown',
    icon: 'Adverse Weather Condition_19.png',
  },
  {
    cause: 'Adverse Weather Condition - Precipitation',
    subcause: 'Heavy Rain',
    icon: 'Adverse Weather Condition_19.png',
  },
  {
    cause: 'Adverse Weather Condition - Precipitation',
    subcause: 'Heavy Snow Fall',
    icon: 'Adverse Weather Condition_19.png',
  },
  {
    cause: 'Adverse Weather Condition - Precipitation',
    subcause: 'Soft Hail',
    icon: 'Adverse Weather Condition_19.png',
  },
  { cause: 'Slow Vehicle', subcause: 'Unknown', icon: 'Slow Vehicle_26.png' },
  { cause: 'Slow Vehicle', subcause: 'Maintenance Vehicle', icon: 'Slow Vehicle_26.png' },
  {
    cause: 'Slow Vehicle',
    subcause: 'Vehicles Slowing To Look At Accident',
    icon: 'Slow Vehicle_26.png',
  },
  { cause: 'Slow Vehicle', subcause: 'Abnormal Load', icon: 'Slow Vehicle_26.png' },
  { cause: 'Slow Vehicle', subcause: 'Abnormal Wide Load', icon: 'Slow Vehicle_26.png' },
  { cause: 'Slow Vehicle', subcause: 'Convoy', icon: 'Slow Vehicle_26.png' },
  { cause: 'Slow Vehicle', subcause: 'Snow Plough', icon: 'Slow Vehicle_26.png' },
  { cause: 'Slow Vehicle', subcause: 'Deicing', icon: 'Slow Vehicle_26.png' },
  { cause: 'Slow Vehicle', subcause: 'Salting Vehicles', icon: 'Slow Vehicle_26.png' },
  { cause: 'Dangerous End Of Queue', subcause: 'Unknown', icon: 'Dangerous End Of Queue_27.png' },
  {
    cause: 'Dangerous End Of Queue',
    subcause: 'Sudden End Of Queue',
    icon: 'Dangerous End Of Queue_27.png',
  },
  {
    cause: 'Dangerous End Of Queue',
    subcause: 'Queue Over Hill',
    icon: 'Dangerous End Of Queue_27.png',
  },
  {
    cause: 'Dangerous End Of Queue',
    subcause: 'Queue Around Bend',
    icon: 'Dangerous End Of Queue_27.png',
  },
  {
    cause: 'Dangerous End Of Queue',
    subcause: 'Queue In Tunnel',
    icon: 'Dangerous End Of Queue_27.png',
  },
  { cause: 'Vehicle Break Down', subcause: 'Unknown', icon: 'Vehicle Break Down_91.png' },
  { cause: 'Vehicle Break Down', subcause: 'Lack Of Fuel', icon: 'Vehicle Break Down_91.png' },
  {
    cause: 'Vehicle Break Down',
    subcause: 'Lack Of Battery Power',
    icon: 'Vehicle Break Down_91.png',
  },
  { cause: 'Vehicle Break Down', subcause: 'Engine Problem', icon: 'Vehicle Break Down_91.png' },
  {
    cause: 'Vehicle Break Down',
    subcause: 'Engine Cooling Problem',
    icon: 'Vehicle Break Down_91.png',
  },
  {
    cause: 'Vehicle Break Down',
    subcause: 'Engine Cooling Problem',
    icon: 'Vehicle Break Down_91.png',
  },
  {
    cause: 'Vehicle Break Down',
    subcause: 'Braking System Problem',
    icon: 'Vehicle Break Down_91.png',
  },
  { cause: 'Vehicle Break Down', subcause: 'Steering Problem', icon: 'Vehicle Break Down_91.png' },
  { cause: 'Vehicle Break Down', subcause: 'Tyre Puncture', icon: 'Vehicle Break Down_91.png' },
  { cause: 'Post Crash', subcause: 'Unknown', icon: 'Post Crash_92.png' },
  { cause: 'Post Crash', subcause: 'Accident Without ECall Triggered', icon: 'Post Crash_92.png' },
  {
    cause: 'Post Crash',
    subcause: 'Accident With ECall Manually Triggered',
    icon: 'Post Crash_92.png',
  },
  {
    cause: 'Post Crash',
    subcause: 'Accident With ECall Automatically Triggered',
    icon: 'Post Crash_92.png',
  },
  {
    cause: 'Post Crash',
    subcause: 'Accident With ECall Triggered Without Access To Cellular Network',
    icon: 'Post Crash_92.png',
  },
  { cause: 'Human Problem', subcause: 'Unknown', icon: 'Human Problem_93.png' },
  { cause: 'Human Problem', subcause: 'Glycemia Problem', icon: 'Human Problem_93.png' },
  { cause: 'Human Problem', subcause: 'Heart Problem', icon: 'Human Problem_93.png' },
  { cause: 'Stationary Vehicle', subcause: 'Unknown', icon: 'Stationary Vehicle_94.png' },
  { cause: 'Stationary Vehicle', subcause: 'Human Problem', icon: 'Stationary Vehicle_94.png' },
  { cause: 'Stationary Vehicle', subcause: 'Vehicle Breakdown', icon: 'Stationary Vehicle_94.png' },
  { cause: 'Stationary Vehicle', subcause: 'Post Crash', icon: 'Stationary Vehicle_94.png' },
  {
    cause: 'Stationary Vehicle',
    subcause: 'Public Transport Stop',
    icon: 'Stationary Vehicle_94.png',
  },
  {
    cause: 'Stationary Vehicle',
    subcause: 'Carrying Dangerous Goods',
    icon: 'Stationary Vehicle_94.png',
  },
  {
    cause: 'Emergency Vehicle Approaching',
    subcause: 'Unknown',
    icon: 'Emergency Vehicle Approaching_95.png',
  },
  {
    cause: 'Emergency Vehicle Approaching',
    subcause: 'Emergency Vehicle Approaching',
    icon: 'Emergency Vehicle Approaching_95.png',
  },
  {
    cause: 'Emergency Vehicle Approaching',
    subcause: 'Prioritized Vehicle Approaching',
    icon: 'Emergency Vehicle Approaching_95.png',
  },
  { cause: 'Dangerous Curve', subcause: 'Unknown', icon: 'Dangerous Curve_96.png' },
  {
    cause: 'Dangerous Curve',
    subcause: 'Dangerous Left Turn Curve',
    icon: 'Dangerous Curve_96.png',
  },
  {
    cause: 'Dangerous Curve',
    subcause: 'Dangerous Right Turn Curve',
    icon: 'Dangerous Curve_96.png',
  },
  {
    cause: 'Dangerous Curve',
    subcause: 'Multiple Curves Starting With Unknown Turning Direction',
    icon: 'Dangerous Curve_96.png',
  },
  {
    cause: 'Dangerous Curve',
    subcause: 'Multiple Curves Starting With Left Turn',
    icon: 'Dangerous Curve_96.png',
  },
  {
    cause: 'Dangerous Curve',
    subcause: 'Multiple Curves Starting With Right Turn',
    icon: 'Dangerous Curve_96.png',
  },
  { cause: 'Collision Risk', subcause: 'Unknown', icon: 'Collision Risk_97.png' },
  {
    cause: 'Collision Risk',
    subcause: 'Longitudinal Collision Risk',
    icon: 'Collision Risk_97.png',
  },
  { cause: 'Collision Risk', subcause: 'Crossing Collision Risk', icon: 'Collision Risk_97.png' },
  { cause: 'Collision Risk', subcause: 'Lateral Collision Risk', icon: 'Collision Risk_97.png' },
  { cause: 'Collision Risk', subcause: 'Vulnerable Road User', icon: 'Collision Risk_97.png' },
  { cause: 'Signal Violation', subcause: 'Unknown', icon: 'Signal Violation_98.png' },
  { cause: 'Signal Violation', subcause: 'Stop Sign Violation', icon: 'Signal Violation_98.png' },
  {
    cause: 'Signal Violation',
    subcause: 'Traffic Light Violation',
    icon: 'Signal Violation_98.png',
  },
  {
    cause: 'Signal Violation',
    subcause: 'Turning Regulation Violation',
    icon: 'Signal Violation_98.png',
  },
  { cause: 'Dangerous Situation', subcause: 'Unknown', icon: 'Dangerous Situation_99.png' },
  {
    cause: 'Dangerous Situation',
    subcause: 'Emergency Electronic Brake Engaged',
    icon: 'Dangerous Situation_99.png',
  },
  {
    cause: 'Dangerous Situation',
    subcause: 'Pre Crash System Engaged',
    icon: 'Dangerous Situation_99.png',
  },
  { cause: 'Dangerous Situation', subcause: 'Esp Engaged', icon: 'Dangerous Situation_99.png' },
  { cause: 'Dangerous Situation', subcause: 'Abs Engaged', icon: 'Dangerous Situation_99.png' },
  { cause: 'Dangerous Situation', subcause: 'Aeb Engaged', icon: 'Dangerous Situation_99.png' },
  {
    cause: 'Dangerous Situation',
    subcause: 'Brake Warning Engaged',
    icon: 'Dangerous Situation_99.png',
  },
  {
    cause: 'Dangerous Situation',
    subcause: 'Collision Risk Warning Engaged',
    icon: 'Dangerous Situation_99.png',
  },
  {
    cause: 'Impassability',
    subcause: 'Unknown',
    icon: 'Signal Violation_98.png',
  },
];

export function iconForCause(cause: Causes, subcause?: SubCauses): string | null {
  if (!cause || !subcause) {
    return null;
  }

  if (subcause) {
    const icon = iconMap.find(
      (c) => c.cause === cause.description && c.subcause === subcause.description,
    );
    if (icon) {
      return icon.icon;
    }
  }

  // Fallback, return first available icon even if not a full match
  return iconMap.find((c) => c.cause === cause.description)?.icon;
}

export default {
  iconForCause,
};
