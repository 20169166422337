import { useLocalization } from '@fluent/react';
import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';

interface TraceProps {
  isActiveSelection: boolean;
  toggleSelectionTraces: () => void;
  isTracesZoneDisabled: boolean;
}

export const Trace = ({
  isActiveSelection,
  toggleSelectionTraces,
  isTracesZoneDisabled,
}: TraceProps) => {
  const { l10n } = useLocalization();

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      marginTop={'20px'}
      paddingBottom={'20px'}
    >
      <Typography variant="caption">{l10n.getString('forms-denm-trace-zone')}</Typography>
      <Button
        onClick={() => {
          toggleSelectionTraces();
        }}
        color="primary"
        style={{
          borderRadius: 20,
          textTransform: 'none',
          backgroundColor: isTracesZoneDisabled ? 'rgb(241, 241, 241)' : '#005ABB',
          color: isTracesZoneDisabled ? 'inherit' : '#FFFFFF',
          fontSize: '11px',
          paddingLeft: '10px',
          paddingRight: '10px',
          marginRight: '2.5px',
          border: isTracesZoneDisabled ? 0 : '1px solid #005ABB',
        }}
        size="small"
        disabled={isTracesZoneDisabled}
      >
        {isActiveSelection
          ? l10n.getString('forms-points-end-selection')
          : l10n.getString('forms-points-add-trace')}
      </Button>
    </Box>
  );
};
