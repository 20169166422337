import React, { FC } from 'react';
import logoEmeras from '../../../assets/images/logo-emeras.png';

import { Client, mapClient } from '../../../../utils';
import styles from './styles';

const TopMenu: FC = () => {
  const classes = styles();
  const clientName = process.env.CLIENT_NAME;
  const client = mapClient(clientName);

  const renderImage = (client: Client) => {
    return <img src={client.logoPath} alt={client.name} className={classes.logoImage} />;
  };

  return (
    <div className={classes.drawerPaper}>
      <img src={logoEmeras} alt="logo-emeras" className={classes.logoImage} />
      <div className={classes.spacer}></div>
      {client.url ? (
        <a href={client.url} target="_blank" rel="noreferrer">
          {renderImage(client)}
        </a>
      ) : (
        renderImage(client)
      )}
    </div>
  );
};

export default React.memo(TopMenu);
