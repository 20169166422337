import { PointTuple } from 'leaflet';
import React, { FC } from 'react';

import CamInfoTooltip from './CamInfoTooltip';
import DenmInfoTooltip from './DenmInfoTooltip';
import DotAddInfoTooltip from './DotAddInfoTooltip';
import IvimInfoTooltip from './IvimInfoTooltip';
import RsuInfoTooltip from './RsuInfoTooltip';
import { HoveringElementType,HoverTooltipProps } from './types';

export const defaultOffset: PointTuple = [0, 0];

const elementMap: (hoveringElementType: HoveringElementType) => FC<HoverTooltipProps> = (
  hoveringElementType,
) => {
  switch (hoveringElementType) {
    case HoveringElementType.RSU:
      return ({ hoveringElement }) => <RsuInfoTooltip hoveringElement={hoveringElement} />;
    case HoveringElementType.DENM:
      return ({ hoveringElement }) => <DenmInfoTooltip hoveringElement={hoveringElement} />;
    case HoveringElementType.IVIM:
      return ({ hoveringElement }) => <IvimInfoTooltip hoveringElement={hoveringElement} />;
    case HoveringElementType.CAM:
      return ({ hoveringElement }) => <CamInfoTooltip hoveringElement={hoveringElement} />;
    case HoveringElementType.DOTT_ADD:
      return ({ hoveringElement }) => <DotAddInfoTooltip hoveringElement={hoveringElement} />;
    default:
      return undefined;
  }
};

const HoverTooltip: FC<HoverTooltipProps> = ({ hoveringElement, onClose }) => {
  if (!hoveringElement.type) {
    return null;
  }

  const Component = elementMap(hoveringElement.type);

  return <Component hoveringElement={hoveringElement} onClose={onClose} />;
};

export default HoverTooltip;
