import { useLocalization } from '@fluent/react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextField,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import {
  AltitudeConfidence,
  StationType,
  TrafficDirection,
  informationQualityOptions,
} from '_lib/api';
import { RootState, useAppDispatch } from '_store';
import denmsReducer from '_store/denm/denmsReducers';
import { Causes } from '_store/denm/denmsTypes';
import { subCausesByCauseOptionalSelector } from '_store/denm/selectors';
import denmFormReducer from '_store/forms/manage-denm';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Accordion } from '../../../../../components/items/Accordion';
import SelectItem from '../../../../../components/items/SelectItem';
import styles from './styles';
import useLaneStates from './useLaneStates';

interface DenmOptionalsProps {
  denmCauses: Causes[];
  isUpdate?: boolean;
  showAlacarte?: boolean;
}

export const DenmOptionals = ({
  denmCauses,
  isUpdate = false,
  showAlacarte = true,
}: DenmOptionalsProps) => {
  const { l10n } = useLocalization();
  const dispatch = useAppDispatch();
  const classes = styles();
  const { busy } = useSelector((state: RootState) => state.application.activeDialog);
  const formState = useSelector((state: RootState) => state.forms['manage-denm']);
  const denmSubCauses = useSelector(subCausesByCauseOptionalSelector);
  const { laneStates, updateLaneStates } = useLaneStates(formState, isUpdate);

  const causes = denmCauses.map(({ id, description }) => ({
    value: id,
    label: description,
  }));

  const subcauses = useMemo(
    () =>
      formState.optionalCause === null
        ? []
        : denmSubCauses.map(({ id, description }) => ({
            value: id,
            label: description,
          })),
    [formState.optionalCause, denmSubCauses],
  );

  const updateOptionalProperty = useCallback(
    (propertyValue) => {
      dispatch(
        denmFormReducer.actions.optionalChanged({
          management: {
            ...formState.optional?.management,
            altitude: {
              altitudeValue: propertyValue.altitudeValue
                ? parseInt(propertyValue.altitudeValue)
                : formState.optional?.management?.altitude.altitudeValue,
              altitudeConfidence:
                propertyValue.altitudeConfidence ||
                formState.optional?.management?.altitude.altitudeConfidence,
            },
            relevanceTrafficDirection:
              propertyValue.relevanceTrafficDirection ||
              formState.optional?.management?.relevanceTrafficDirection,
            stationType: propertyValue.stationType
              ? parseInt(propertyValue.stationType)
              : formState.optional?.management?.stationType,
          },
          situation: {
            ...formState.optional?.situation,
            informationQuality: propertyValue.informationQuality
              ? parseInt(propertyValue.informationQuality)
              : formState.optional?.situation?.informationQuality,
            linkedCause: {
              causeCode:
                propertyValue.causeCode || formState.optional?.situation?.linkedCause?.causeCode,
              subCauseCode:
                propertyValue.subCauseCode ||
                formState.optional?.situation?.linkedCause?.subCauseCode,
            },
          },
          location: {
            ...formState.optional?.location,
            speedValue: propertyValue.speedValue
              ? parseInt(propertyValue.speedValue)
              : formState.optional?.location?.speedValue,
            headingValue: propertyValue.headingValue
              ? parseInt(propertyValue.headingValue)
              : formState.optional?.location?.headingValue,
          },
          alacarte: {
            ...formState.optional?.alacarte,
            roadWorks: {
              closedLanes:
                propertyValue.closedLanes || formState.optional?.alacarte?.roadWorks?.closedLanes,
              referenceDenms:
                propertyValue.referenceDenms ||
                formState.optional?.alacarte?.roadWorks?.referenceDenms,
            },
          },
        }),
      );
    },
    [
      dispatch,
      formState.optional?.management,
      formState.optional?.situation,
      formState.optional?.location,
      formState.optional?.alacarte,
    ],
  );

  const updateProperty = (property: string) => (e: any) => {
    updateOptionalProperty({ [property]: e.target.value });
  };

  const updateCause = (e) => {
    const id = e.target.value;
    dispatch(denmFormReducer.actions.optionalSubCauseChanged());
    dispatch(denmsReducer.actions.optionalSubCausesByCauseRequest(id));
    dispatch(denmFormReducer.actions.optionalCauseChanged(id));
    const cause = denmCauses.find((cause) => cause.id === id);
    const causeCode = cause?.code;
    updateOptionalProperty({
      causeCode: causeCode,
      subCauseCode: undefined,
    });
  };

  const updateSubCause = (e) => {
    const id = e.target.value;
    dispatch(denmFormReducer.actions.optionalSubCauseChanged(id));
    const subcause = denmSubCauses.find((subcause) => subcause.id === id);
    const subcauseCode = subcause?.code;
    updateOptionalProperty({ subCauseCode: subcauseCode });
  };

  const handleClosedLanes = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    const updatedStates = { ...laneStates, [name]: !laneStates[name] };
    updateLaneStates(event);

    let newClosedLanes = 0;
    newClosedLanes |= updatedStates.overtaking ? 0b1000 : 0;
    newClosedLanes |= updatedStates.fast ? 0b0100 : 0;
    newClosedLanes |= updatedStates.slow ? 0b0010 : 0;
    newClosedLanes |= updatedStates.emergency ? 0b0001 : 0;

    const bitNumberToSend = newClosedLanes.toString(2).padStart(4, '0');

    updateOptionalProperty({ closedLanes: bitNumberToSend });
  };

  const addReferenceDenm = () => {
    const newReferenceDenm = { originatingStationID: '', sequenceNumber: '' };
    const updatedReferenceDenms = [
      ...(formState.optional?.alacarte?.roadWorks?.referenceDenms || []),
      newReferenceDenm,
    ];
    updateOptionalProperty({ referenceDenms: updatedReferenceDenms });
  };

  const removeReferenceDenm = (index: number) => {
    const updatedReferenceDenms = formState.optional.alacarte.roadWorks.referenceDenms.filter(
      (_, i) => i !== index,
    );
    updateOptionalProperty({ referenceDenms: updatedReferenceDenms });
  };

  const updateReferenceDenm = (index: number, field: string, value: string) => {
    const numericValue = parseInt(value);
    const updatedReferenceDenms = formState.optional.alacarte.roadWorks.referenceDenms.map(
      (denm, i) => (i === index ? { ...denm, [field]: numericValue } : denm),
    );
    updateOptionalProperty({ referenceDenms: updatedReferenceDenms });
  };

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      <Accordion title={l10n.getString('forms-denm-optional-management-container')}>
        <Box style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <TextField
            id="altitudeValue"
            label={l10n.getString('forms-denm-altitude-value')}
            InputLabelProps={{ style: { fontSize: 12 } }}
            type="number"
            value={formState.optional?.management?.altitude.altitudeValue.toString() ?? ''}
            onChange={updateProperty('altitudeValue')}
          />
          <SelectItem
            text={l10n.getString('forms-denm-altitude-confidence')}
            value={formState.optional?.management?.altitude.altitudeConfidence ?? ''}
            options={Object.values(AltitudeConfidence).map((ac) => ({ label: ac, value: ac }))}
            disabled={busy}
            onChange={updateProperty('altitudeConfidence')}
            tooltip=""
          />
          <SelectItem
            text={l10n.getString('forms-denm-traffic-direction')}
            value={formState.optional?.management?.relevanceTrafficDirection ?? ''}
            options={Object.values(TrafficDirection).map((td) => ({ label: td, value: td }))}
            disabled={busy}
            onChange={updateProperty('relevanceTrafficDirection')}
            tooltip=""
          />
          <SelectItem
            text={l10n.getString('forms-denm-station-type')}
            value={formState.optional?.management?.stationType ?? ''}
            options={Object.entries(StationType)
              .filter(([key, value]) => typeof value === 'number')
              .map(([key, value]) => ({ label: key, value: value }))}
            disabled={busy}
            onChange={updateProperty('stationType')}
            tooltip=""
          />
        </Box>
      </Accordion>
      <Accordion title={l10n.getString('forms-denm-optional-situation-container')}>
        <Box style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <SelectItem
            text={l10n.getString('forms-denm-information-quality')}
            value={formState.optional?.situation?.informationQuality ?? ''}
            options={informationQualityOptions.map((ac) => ({ label: ac.toString(), value: ac }))}
            disabled={busy}
            onChange={updateProperty('informationQuality')}
            tooltip=""
          />
          <SelectItem
            text={l10n.getString('forms-denm-linked-cause')}
            value={formState.optionalCause ?? ''}
            options={causes}
            disabled={busy}
            onChange={updateCause}
            tooltip=""
          />
          <SelectItem
            text={l10n.getString('forms-denm-linked-subcause')}
            value={formState.optionalSubCause ?? ''}
            options={subcauses}
            disabled={formState.optionalSubCause === null || busy}
            onChange={updateSubCause}
            tooltip=""
          />
        </Box>
      </Accordion>
      <Accordion title={l10n.getString('forms-denm-optional-location-container')}>
        <Box style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <TextField
            id="speedValue"
            label={l10n.getString('forms-denm-speed-value')}
            InputLabelProps={{ style: { fontSize: 12 } }}
            type="number"
            value={formState.optional?.location?.speedValue.toString() ?? ''}
            onChange={updateProperty('speedValue')}
          />
          <TextField
            id="headingValue"
            label={l10n.getString('forms-denm-heading-value')}
            InputLabelProps={{ style: { fontSize: 12 } }}
            type="number"
            value={formState.optional?.location?.headingValue.toString() ?? ''}
            onChange={updateProperty('headingValue')}
          />
        </Box>
      </Accordion>
      {showAlacarte && (
        <Accordion title={l10n.getString('forms-denm-optional-alacarte-container')}>
          <Box style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                style={{ fontSize: 13, color: 'rgba(0, 0, 0, 0.54)', marginBottom: 20 }}
              >
                {l10n.getString('forms-denm-closed-lanes')}
              </FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={laneStates.overtaking}
                      onChange={handleClosedLanes}
                      name="overtaking"
                      disableRipple={true}
                      size="small"
                      color="default"
                      classes={{ root: classes.checkbox, checked: classes.checkboxChecked }}
                    />
                  }
                  label={l10n.getString('forms-denm-closed-lane-overtaking')}
                  classes={{ root: classes.formControl, label: classes.formControlLabel }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={laneStates.fast}
                      onChange={handleClosedLanes}
                      name="fast"
                      disableRipple={true}
                      size="small"
                      color="default"
                      classes={{ root: classes.checkbox, checked: classes.checkboxChecked }}
                    />
                  }
                  label={l10n.getString('forms-denm-closed-lane-fast')}
                  classes={{ root: classes.formControl, label: classes.formControlLabel }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={laneStates.slow}
                      onChange={handleClosedLanes}
                      name="slow"
                      disableRipple={true}
                      size="small"
                      color="default"
                      classes={{ root: classes.checkbox, checked: classes.checkboxChecked }}
                    />
                  }
                  label={l10n.getString('forms-denm-closed-lane-slow')}
                  classes={{ root: classes.formControl, label: classes.formControlLabel }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={laneStates.emergency}
                      onChange={handleClosedLanes}
                      name="emergency"
                      disableRipple={true}
                      size="small"
                      color="default"
                      classes={{ root: classes.checkbox, checked: classes.checkboxChecked }}
                    />
                  }
                  label={l10n.getString('forms-denm-closed-lane-emergency')}
                  classes={{ root: classes.formControl, label: classes.formControlLabel }}
                />
              </FormGroup>
            </FormControl>
            <Divider style={{ margin: '32px 0' }} />
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                style={{ fontSize: 13, color: 'rgba(0, 0, 0, 0.54)', marginBottom: 20 }}
              >
                {l10n.getString('forms-denm-reference-denms')}
              </FormLabel>
              <FormGroup>
                {formState.optional?.alacarte?.roadWorks?.referenceDenms.map((denm, index) => (
                  <Box key={index} className={classes.referenceDenmBox}>
                    <div>
                      <TextField
                        id={`originatingStationID-${index}`}
                        label={l10n.getString('forms-denm-originating-station-id')}
                        InputLabelProps={{ style: { fontSize: 12 } }}
                        type="number"
                        value={denm.originatingStationID}
                        onChange={(e) =>
                          updateReferenceDenm(index, 'originatingStationID', e.target.value)
                        }
                        style={{ marginBottom: 8 }}
                      />
                      <TextField
                        id={`sequenceNumber-${index}`}
                        label={l10n.getString('forms-denm-sequence-number')}
                        InputLabelProps={{ style: { fontSize: 12 } }}
                        type="number"
                        value={denm.sequenceNumber}
                        onChange={(e) =>
                          updateReferenceDenm(index, 'sequenceNumber', e.target.value)
                        }
                      />
                    </div>
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{
                          backgroundColor: red[400],
                        }}
                        className={classes.iconButton}
                        size="sm"
                        onClick={() => removeReferenceDenm(index)}
                      />
                    </Box>
                  </Box>
                ))}
                <Button size="small" onClick={addReferenceDenm}>
                  {l10n.getString('forms-denm-add-reference-denm')}
                </Button>
              </FormGroup>
            </FormControl>
          </Box>
        </Accordion>
      )}
    </Box>
  );
};
