import { makeStyles } from '@material-ui/core';

const styles = makeStyles((_theme) => ({
  cardContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    position: 'relative',
  },
  input: {
    backgroundColor: '#fff',
    marginBottom: 20,
    marginTop: 0,
  },
  addNewStyle: {
    textTransform: 'none',
    marginTop: 10,
    color: '#005abb',
    borderRadius: 20,
    width: 150,
    alignSelf: 'flex-end',
  },
}));

export default styles;
