// import withQueryUtils from 'with-query';
import get from 'lodash/get';
import { call } from 'redux-saga/effects';

import { Method } from '.';
import { getFetchHeaders } from './utils';

const hostname = process.env.API_ENDPOINT;

const validateResponse = (response) => {
  if (!response.ok) {
    return Promise.reject(response);
  }
  return response;
};

const readResponseAsJSON = (response) => {
  const json = response.json();
  if (response.status >= 200 && response.status < 400) {
    return Promise.resolve(json);
  }
  return Promise.reject(response);
};

const readResponseAsIMAGE = (response) => {
  const blob = response.blob();

  if (response.status >= 200 && response.status < 400) {
    return Promise.resolve(blob);
  }
  return Promise.reject(response);
};

const handleResponseError = (response) =>
  new Promise((_resolve, reject) => {
    if (response.text) {
      return response
        .text() // response.json() doesn't appear to support sensible error handling of non-JSON
        .then((text) => {
          let jsonData = {};
          try {
            jsonData = JSON.parse(text); // try to do our own parsing of the json here
          } catch (err) {
            // no-op
          }
          return jsonData;
        })
        .then((packet) => {
          const error = { packet, response };
          return reject(error);
        });
    }
    return response;
  });

const fetchWrapper = ({
  url,
  options = { method: Method.GET },
  formData,
  // withQuery,
  authenticated,
  image,
}: {
  url: string;
  options?: any;
  formData?: any;
  withQuery?: any;
  authenticated?: boolean;
  image?: any;
}) => {
  const { method, body } = options;

  if (image) {
    return fetch(url.includes('http:') ? url : `${hostname}/${url}`, {
      method,
      body: body ? JSON.stringify({ ...body }) : formData || undefined,
      headers: getFetchHeaders({ /* headers, */ authenticated, method }),
    })
      .then(validateResponse)
      .then(readResponseAsIMAGE)
      .then((images) => URL.createObjectURL(images))
      .catch(handleResponseError);
  }
  // if (withQuery)
  //   return fetch(withQueryUtils(url.includes('http:') ? url : `${hostname}/${url}`, body), {
  //     method,
  //     headers: getFetchHeaders({ url, headers, authenticated, method }),
  //   })
  //     .then(validateResponse)
  //     .then(readResponseAsJSON)
  //     .catch(handleResponseError);

  return fetch(url.includes('http:') ? url : `${hostname}/${url}`, {
    method,
    body: body ? JSON.stringify({ ...body }) : formData || undefined,
    headers: getFetchHeaders({ /* headers, */ authenticated, method }),
  })
    .then(validateResponse)
    .then((response) => {
      if (method !== Method.DELETE) {
        return readResponseAsJSON(response);
      }
      return Promise.resolve(response);
    })
    .catch(handleResponseError);
};

interface FetchAPIOptions {
  method?: Method;
  params?: any;
  authenticated?: boolean;
  formData?: any;
  withQuery?: any;
  image?: any;
}

function* fetchAPI(
  url: string,
  options: FetchAPIOptions = {
    method: Method.GET,
    authenticated: true,
  },
) {
  const headers = {};

  const { method, params, authenticated, formData, withQuery, image } = options;

  try {
    const response = yield call(fetchWrapper, {
      url,
      options: {
        method,
        headers,
        body: params || undefined,
      },
      formData,
      withQuery,
      authenticated,
      image,
    });

    return response;
  } catch (res) {
    if (res === '500' || get(res, 'response.status') === 500) {
      // yield put(push('/generic-error'));
      console.log('ERROR 500 >>>>>>>>>>');
    }

    if ([403].includes(get(res, 'response.status'))) {
      // yield put(setRedirectUrl(history.location.pathname));
      // yield put(push(`/auth/sign-up`));
    }

    throw res;
  }
}

export default fetchAPI;
