import { Box, Button, ButtonGroup, PropTypes, Typography } from '@material-ui/core';
import React, { FC } from 'react';

import styles from './styles';

type ButtonColor = PropTypes.Color | '';
type ButtonVariant = 'text' | 'contained' | 'outlined';

interface ButtonSingle {
  label: string;
  color?: ButtonColor;
  disabled?: boolean;
  onClick?: (index: number, ev: React.MouseEvent) => void;
  variant?: ButtonVariant;
}

interface ButtonPairProps {
  id?: string;
  text: string;
  buttons: ButtonSingle[];
  onClick?: (index: number, ev: React.MouseEvent) => void;
  variant?: ButtonVariant;
  mandatory?: boolean;
}

function buttonColor(color: ButtonColor, disabled: boolean) {
  switch (color) {
    case 'primary':
      return {
        backgroundColor: disabled ? '#7fb1e6' : '#005ABB',
        color: disabled ? '#f5f5f5' : '#fff',
      };
    default:
      return {
        backgroundColor: '#f1f1f1',
        color: 'inherit',
      };
  }
}

const ButtonGroupItem: FC<ButtonPairProps> = (item) => {
  const classes = styles();
  const buttonGroupVariant = item.variant || 'contained';

  return (
    <Box key={item.id} color="text.primary" className={classes.main}>
      <Typography align={'center'} variant="caption" className={classes.title}>
        {item.text}
        {item.mandatory && <span className={classes.mandatory}>*</span>}
      </Typography>

      <ButtonGroup fullWidth size={'small'} disableElevation variant={buttonGroupVariant}>
        {item.buttons.map((btn, index) => {
          const variant = item.variant || 'contained';
          return (
            <Button
              key={index}
              style={{
                padding: 10,
                marginRight: 2.5,
                border: 0,
                borderRadius: 20,
                textTransform: 'none',
                fontSize: 11,
                ...buttonColor(btn.color, btn.disabled),
              }}
              disabled={btn.disabled}
              onClick={(ev) =>
                btn.onClick ? btn.onClick(index, ev) : item.onClick ? item.onClick(index, ev) : null
              }
              variant={variant}
            >
              {btn.label}
            </Button>
          );
        })}
      </ButtonGroup>
    </Box>
  );
};

export default React.memo(ButtonGroupItem);
