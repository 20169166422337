import { Denm } from '_store/denm/denmsTypes';
import L, { Map } from 'leaflet';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import MarkerClusterGroup from 'react-leaflet-markercluster';

import DenmMarker from './markers/DenmMarker';
import { iconSizePx } from '../utils';
import EmptyTriangle from '../../../icons/denm/RedTriangle.png';

const Denms: FC<{ denms: Denm[]; mapRef: Map }> = ({ denms, mapRef }): ReactElement => {
  // workaround to force re-render of markers https://github.com/yuzhva/react-leaflet-markercluster/issues/149
  const [renderKey, setRenderKey] = useState(0);
  useEffect(() => {
    setRenderKey((k) => k + 1);
  }, [denms]);

  const iconSize = new L.Point(iconSizePx, iconSizePx);
  const iconAnchor = new L.Point(iconSize.x / 2, iconSize.y);

  return (
    <MarkerClusterGroup
      maxClusterRadius={160}
      disableClusteringAtZoom={13}
      iconCreateFunction={(cluster) => {
        const markers = cluster.getAllChildMarkers();
        const count = markers.length;
        const digits = count.toString().length;

        const html = `
        <div class="cluster-icon-container-full">
          <img src="${EmptyTriangle}" class="cluster-icon" />
          <div class="cluster-count" data-digits="${digits}">${count}</div>
        </div>
      `;

        return L.divIcon({
          html,
          className: 'marker-event-unselected',
          iconSize: iconSize,
          iconAnchor: iconAnchor,
        });
      }}
      key={renderKey}
    >
      {denms.map((denm, key) => (
        <DenmMarker key={key} denm={denm} mapRef={mapRef} />
      ))}
    </MarkerClusterGroup>
  );
};

export default React.memo(Denms);
