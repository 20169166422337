import { Road } from '_store/roads/roadsTypes';
import React, { FC, ReactElement } from 'react';
import { Polyline } from 'react-leaflet';

const roadStyles = {
  color: '#FF0000',
  opacity: 0.8,
  weight: 3,
};

export interface RoadsProps {
  roads: Road[];
}

const Roads: FC<RoadsProps> = ({ roads }): ReactElement => (
    <>
      {roads.map((road) =>
        road.waypoints.coordinates.map((coordinate, i) => (
          <Polyline
            key={`${road.id}-${i}`}
            positions={coordinate.map(([lng, lat]) => ({ lat, lng }))}
            pathOptions={roadStyles}
          />
        )),
      )}
    </>
  );

export default React.memo(Roads);
