import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@material-ui/core';

import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { green } from '@material-ui/core/colors';
import { IVIMPictrogram } from '_store/api/types';
import React, { useState } from 'react';
import styles from '../styles';
import { TablePaginationActions } from './TablePaginationActions';
import { useLocalization } from '@fluent/react';
import { images } from '../../../icons/ivim/pictograms/index';
interface PictogramsTableProps {
  rows: IVIMPictrogram[];
  rowSelected?: string;
  selectPictogram: (row: IVIMPictrogram) => void;
}

export const PictogramsTable = ({ rows, rowSelected, selectPictogram }: PictogramsTableProps) => {
  const classes = styles();
  const { l10n } = useLocalization();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>{l10n.getString('forms-ivim-pictogram-name')}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row) => {
            const borderStyle = row.description === rowSelected ? `3px solid ${green[500]}` : '';
            const imageKey =
              `${row.serviceSubCategoryCode}-${row.pictogramCategoryCode}-${row.pictogramSerialNumber}`.replace(
                /-/g,
                '_',
              );
            const imageUrl = images[imageKey];

            return (
              <TableRow
                key={row.description}
                style={{
                  borderLeft: borderStyle,
                }}
              >
                <TableCell component="th" scope="row">
                  {imageUrl ? <img src={imageUrl} width={25} /> : null}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.description}
                </TableCell>
                <TableCell className={classes.tableCellRight}>
                  {row.description === rowSelected ? (
                    <Chip
                      variant="outlined"
                      icon={<FontAwesomeIcon icon={faCheck} style={{ color: green[500] }} />}
                      label={l10n.getString('forms-ivim-pictogram-selected')}
                      className={classes.chip}
                      size="small"
                    />
                  ) : (
                    <Tooltip
                      title={l10n.getString('forms-ivim-pictogram-select')}
                      placement={'left'}
                    >
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        onClick={() => selectPictogram(row)}
                        style={{ cursor: 'pointer' }}
                        size="lg"
                      />
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={2} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: l10n.getString('table-all'), value: -1 }]}
              colSpan={3}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': l10n.getString('table-rows-per-page') },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelRowsPerPage={l10n.getString('table-rows-per-page')}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} ${l10n.getString('table-of')} ${count}`
              }
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
